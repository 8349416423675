import React, { Component, FC } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { IPartnerPromotionProps } from '../../types/propTypes';
import { IPartnerPromotionState } from '../../types/stateTypes';
import UserService from '../../services/user/user-service';
import StandardResponse from '../../models/standard-response';
import { IPartnerPromotion } from '../../models/profile';
import { PreviewPromotion } from '../../components/common/PreviewPromotion';
import { useParams } from 'react-router-dom';

const PartnerPromotionPageHOC: FC<any> = (props) => {
    const params = useParams();

    return <PartnerPromotionPage {...props} params={params} />;
};

class PartnerPromotionPage extends Component<IPartnerPromotionProps & WithTranslation, IPartnerPromotionState> {
    userService: UserService;
    constructor(props: IPartnerPromotionProps & WithTranslation) {
        super(props);
        this.userService = new UserService();
        this.state = {
            partnerPromotion: null,
            viewPromotionKey: 0,
        };
    }
    componentDidMount() {
        this.getPartnerPromotionById();
    }

    getPartnerPromotionById = () => {
        const params = this.props.params;
        this.userService
            .getParentPromotionById(params.id, params.driver_id, params.session_id)
            .then((res: StandardResponse<IPartnerPromotion>) => {
                this.setState({ partnerPromotion: res.Data, viewPromotionKey: Math.random() * 10 });
            })
            .catch(() => {});
    };

    render() {
        const { partnerPromotion, viewPromotionKey } = this.state;
        return (
            <>
                <div id="faq-wrapper">
                    {this.state.partnerPromotion && (
                        <PreviewPromotion
                            layout={partnerPromotion.layout}
                            image={partnerPromotion.image}
                            qrCode={partnerPromotion.qr_code_details}
                            init={viewPromotionKey}
                            additionalImages={partnerPromotion.additional_images}
                        />
                    )}
                </div>
            </>
        );
    }
}

export default withTranslation()(PartnerPromotionPageHOC);
