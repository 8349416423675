import { useState } from 'react';
import { Form } from 'react-bootstrap';

const ReactstrapFormikInput = ({ field: { ...fields }, form: { touched, errors, setFieldTouched }, ...props }) => {
    const [isFocused, setFocus] = useState(false);
    const { id, className, accountNameNote, ...rest } = props;
    const inputClassName = props.inputClassName ? props.inputClassName : '';
    const handleFocus = () => {
        setFocus(true);
    };

    const handleBlur = () => {
        setFocus(false);
        setFieldTouched(fields.name, true);
    };

    let groupClassName = '';
    if (isFocused || fields.value) {
        groupClassName = 'value-exists';
    }
    if (className) {
        groupClassName = groupClassName + ' ' + className;
    }

    return (
        <Form.Group
            controlId={id}
            className={
                Boolean(touched[fields.name] && errors[fields.name])
                    ? `${groupClassName} error form-group`
                    : `${groupClassName} form-group`
            }
        >
            {props.label && (
                <Form.Label className={'float-label'} htmlFor={fields.id}>
                    {props.label}
                </Form.Label>
            )}
            <Form.Control
                {...rest}
                {...fields}
                isInvalid={Boolean(touched[fields.name] && errors[fields.name])}
                onFocus={handleFocus}
                onBlur={handleBlur}
                className={`${inputClassName} ${Boolean(touched[fields.name] && errors[fields.name]) && 'error'}`}
            />
            {touched[fields.name] && errors[fields.name] && errors[fields.name] !== 'required' && (
                <Form.Control.Feedback className="pb-0 error invalid-feedback d-block padding-0" type="invalid">
                    {errors[fields.name]}
                </Form.Control.Feedback>
            )}
            {accountNameNote && (
                <span className="pb-0 error invalid-feedback d-block padding-0">{accountNameNote}</span>
            )}
        </Form.Group>
    );
};
export default ReactstrapFormikInput;
