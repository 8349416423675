export const NOTIFICATION_SYSTEM_STYLE = {
    NotificationItem: {
        DefaultStyle: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',

            borderRadius: '4px',
            fontSize: '14px',
        },

        success: {
            borderTop: 0,
            backgroundColor: '#45b649',
            WebkitBoxShadow: 0,
            MozBoxShadow: 0,
            boxShadow: 0,
        },

        error: {
            borderTop: 0,
            backgroundColor: '#f85032',
            WebkitBoxShadow: 0,
            MozBoxShadow: 0,
            boxShadow: 0,
        },

        warning: {
            borderTop: 0,
            backgroundColor: '#ffd700',
            WebkitBoxShadow: 0,
            MozBoxShadow: 0,
            boxShadow: 0,
        },

        info: {
            borderTop: 0,
            background: 'linear-gradient(to right, #6a82fb, #fc5c7d)',
            WebkitBoxShadow: 0,
            MozBoxShadow: 0,
            boxShadow: 0,
        },
    },

    Title: {
        DefaultStyle: {
            margin: 0,
            padding: 0,
            paddingRight: 5,
            color: '#fff',
            display: 'inline-flex',
            fontSize: 20,
            fontWeight: 'bold',
        },
    },

    MessageWrapper: {
        DefaultStyle: {
            display: 'block',
            color: '#fff',
            width: '100%',
        },
    },

    Dismiss: {
        DefaultStyle: {
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontFamily: 'inherit',
            fontSize: 20,
            color: '#f2f2f2',
            position: 'relative',
            margin: 0,
            padding: 0,
            background: 'none',
            borderRadius: 0,
            opacity: 1,
            width: 20,
            height: 20,
            textAlign: 'initial',
            float: 'none',
            top: 'unset',
            right: 'unset',
            lineHeight: 'inherit',
        },
    },

    Action: {
        DefaultStyle: {
            background: '#fff',
            borderRadius: '2px',
            padding: '6px 20px',
            fontWeight: 'bold',
            margin: '10px 0 0 0',
            border: 0,
        },

        success: {
            backgroundColor: '#45b649',
            color: '#fff',
        },

        error: {
            backgroundColor: '#f85032',
            color: '#fff',
        },

        warning: {
            backgroundColor: '#ffd700',
            color: '#fff',
        },

        info: {
            backgroundColor: '#00c9ff',
            color: '#fff',
        },
    },

    ActionWrapper: {
        DefaultStyle: {
            margin: 0,
            padding: 0,
        },
    },
};

export class Common {
    public static readonly Module = 'Module';
    public static readonly BILLING_ADDRESS = 'BillingAddress';
    public static readonly PERSONAL_ADDRESS = 'PersonalAddress';
    public static readonly DATEFORMAT = 'DD/MM/YYYY HH:mm';
    public static readonly REGULAR_DECIMAL_SEPERATOR = '.';
    static Modules = class Modules {
        public static readonly DASHBOARD = 'DashBoard';
        public static readonly ARTICLE = 'Article';
    };
    public static readonly ByPassAuthURLs = ['signup', 'notify-password-change', 'check-referral-code'];

    static Actions = class Actions {
        public static readonly CAN_READ = 'CanRead';
        public static readonly CAN_DELETE = 'CanDelete';
        public static readonly CAN_UPDATE = 'CanUpdate';
        public static readonly CAN_EXPORT = 'CanExport';
        public static readonly CAN_CREATE = 'CanCreate';
    };

    static Colors = class Colors {
        public static readonly PRIMARY = 'primary';
        public static readonly DANGER = 'danger';
        public static readonly SECONDARY = 'secondary';
    };

    static Modes = class Modes {
        public static readonly ADD = 'Add';
        public static readonly Edit = 'Edit';
    };

    static AuthStage = class AuthStage {
        public static readonly SIGNIN = 0;
        public static readonly FORGOT_PASSWORD = 1;
        public static readonly CHANGE_PASSWORD_WITH_VERIFICATION_CODE = 2;
        public static readonly SIGNUP = 3;
        public static readonly SIGNUP_OTP = 4;
    };

    static Authentication = class Authentication {
        public static readonly SUCCESS = 'SUCCESS';
        public static readonly NotAuthorizedException = 'NotAuthorizedException';
        public static readonly UserNotConfirmedException = 'UserNotConfirmedException';
        public static readonly UsernameExistsException = 'UsernameExistsException';
        public static readonly CodeMismatchException = 'CodeMismatchException';
        public static readonly InvalidParameterException = 'InvalidParameterException';
        public static readonly referaalCodeMisMatch = 'referaalCodeMisMatch';
        public static readonly LimitExceededException = 'LimitExceededException';
    };

    static UserStatus = class UserStatus {
        public static readonly ENABLE = 'ENABLE';
        public static readonly CANCELLED = 'CANCELLED';
        public static readonly DISABLE = 'DISABLE';
        public static readonly BAD_DEBTOR = 'BAD_DEBTOR';
    };
    static PaymentStage = Object.freeze({
        PAYMENT_TYPE: 0,
        CREDIT_PAYMENT: 1,
        PRE_PAYMENT: 2,
        CREDIT_PAYMENT_CARD: 3,
        PRE_PAYMENT_DETAIL: 4,
        PAYMENT_PROCESS: 5,
        PAYMENT_SUCCESS: 6,
        PAYMENT_FAIL: 7,
        PRE_PAYMENT_WITHDRAW: 8,
        PRE_PAYMENT_DETAILS_DNA: 9,
        CREDIT_PAYMENT_DNA: 10,
        EDIT_CREDIT_CARD: 11,
        CREDIT_PAYMENT_DATATRANS: 12,
        PRE_PAYMENT_DATATRANS: 13,
    });

    static PaymentProcess = Object.freeze({
        PAYMENT_TYPE: 'PAYMENT_TYPE',
        CREDIT_PAYMENT: 'CREDIT_PAYMENT',
        CREDIT_PAYMENT_CARD: 'CREDIT_PAYMENT_CARD',
        PRE_PAYMENT: 'PRE_PAYMENT',
        PRE_PAYMENT_DETAIL: 'PRE_PAYMENT_DETAIL',
        PAYMENT_PROCESS: 'PAYMENT_PROCESS',
        PAYMENT_SUCCESS: 'PAYMENT_SUCCESS',
        PAYMENT_FAIL: 'PAYMENT_FAIL',
        PRE_PAYMENT_WITHDRAW: 'PRE_PAYMENT_WITHDRAW',
        PRE_PAYMENT_DETAILS_DNA: 'PRE_PAYMENT_DETAILS_DNA',
        CREDIT_PAYMENT_DNA: 'CREDIT_PAYMENT_DNA',
        EDIT_CREDIT_CARD: 'EDIT_CREDIT_CARD',
        CREDIT_PAYMENT_DATATRANS: 'CREDIT_PAYMENT_DATATRANS',
        PRE_PAYMENT_DATATRANS: 'PRE_PAYMENT_DATATRANS',
    });

    static RegularExpression = class RegularExpression {
        //eslint-disable-next-line
        public static readonly PasswordRegularExpression = new RegExp(
            /^(?=.*\p{Ll})(?=.*\p{Lu})(?=.*\d)(?=.*[!@#$%^&*()\-_=+{};:,<.>])[\p{L}\d!@#$%^&*()\-_=+{};:,<.>]{10,99}$/u,
        );
        public static CodeRegularExpression = new RegExp(/^[0-9]{6}$/);
        public static NameRegularExpression = new RegExp(/[\p{L}]*^[^*\^@&%#~_+!$(){}<>?\/\\,.:;`"|0-9]*$/);
        public static readonly EmailRegularExpression = new RegExp(
            /(?=^.{1,64}@.{1,255}$)^([a-zA-Z0-9%_.+-]+)@((([a-zA-Z0-9-]+\.)+([a-zA-Z]{2,})+)|[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})$/,
        );
        public static readonly CardNumberRegularExpression = new RegExp(/^[0-9]*$/);
        public static readonly AmountRegularExpression = new RegExp(/^[0-9]*\.?\,?[0-9]*$/);
        public static readonly CVVRegularExpression = new RegExp(/^[0-9]*$/);
        public static readonly CharityAmountRegularExpression = new RegExp(/^[0-9]*\.?[0-9]*$/);
        public static readonly CardTitleRegularExpression = new RegExp(/[\p{L}]*^[^*\^@&%#~+!$(){}<>?\/\\,.:;`'"|]*$/);
        public static readonly DigitsRegularExpression = new RegExp(/^[0-9]*$/);
        public static readonly DigitsExcludingOnlyZeroRegularExpression = new RegExp(/^(?!0+$)[0-9]{0,2}$/);
        public static readonly AlphaNumericRegex = new RegExp(/[\p{L}]*^[^*\^@&%#~_\-+!$(){}<>?\/\\,.:;`'"|\s]*$/);
        public static readonly DigitsWithSpacesRegularExpression = new RegExp(/^[0-9\s]*$/);
        public static readonly AddressRegularExpression = new RegExp(/^[\p{L}\p{N}\s.,&-/()#'"!$%^+=<>?@_{}:;~|]+$/u);
        public static readonly GeneralAddressRegularExpression = new RegExp(
            /^[A-Za-zÀ-ſĀ-ſα-ωΑ-Ω\r\n\d.,?!":;€£$%^&*()_+=\[\]#@<>`\\ \-'\/]+$/u,
        );
        public static readonly GeneralNameRegularExpression = new RegExp(/^[\p{L}\p{N}'\s_-]+$/u);
    };

    static ChargingType = class ChargingType {
        public static readonly CHARGE = 'CHARGE';
        public static readonly PAYMENT = 'PAYMENT';
    };
    public static readonly OauthFedratedSignIn = 'oauth-federatedSignin';

    static DonationType = Object.freeze({
        ROUNDUP: 'ROUNDUP',
        TOPUP: 'TOPUP',
    });

    static PaymentType = Object.freeze({
        PREPAYMENT_ACCOUNT: 'PREPAYMENT_ACCOUNT',
        CREDIT_ACCOUNT: 'CREDIT_ACCOUNT',
    });

    static PayemntCode = 'Optomany';
    static PaymentActionCode = Object.freeze({
        THREEDS_ADD_MONEY_TO_WALLET: '3DS_ADD_MONEY_TO_WALLET',
        ADD_MONEY_TO_WALLET: 'ADD_MONEY_TO_WALLET',
        THREEDS_REPLACE_CARD: '3DS_REPLACE_CARD',
        THREEDS_SAVE_CARD: '3DS_SAVE_CARD',
        REPLACE_CARD: 'REPLACE_CARD',
        SAVE_CARD: 'SAVE_CARD',
    });
    static PaymentAction = Object.freeze({
        EDITCARD: 'editcard',
        REPLACECARD: 'replacecard',
        ADDCARD: 'addcard',
        SHOWBAL: 'showbal',
        CREDITWITHDROW: 'creditwithdraw',
        PREPAYWITHDROW: 'prepaymentwithdraw',
    });

    static PaymentCardType = Object.freeze({
        VISA: 'VISA',
        AMEX: 'AMEX',
        DINERS_CLUB_CARTE_BLANCHE: 'DINERS_CLUB_CARTE_BLANCHE',
        DINERS_CLUB_INTERNATIONAL: 'DINERS_CLUB_INTERNATIONAL',
        JCB: 'JCB',
        LASER: 'LASER',
        VISA_ELECTRON: 'VISA_ELECTRON',
        MASTERCARD: 'MASTERCARD',
        DISCOVER: 'DISCOVER',
        DANKORT: 'DANKORT',
        MAESTRO: 'MAESTRO',
        UATP: 'UATP',
    });

    static PaymentCardTypeImage = Object.freeze({
        VISA: 'visa.svg',
        AMEX: 'amex.svg',
        DINERS_CLUB_CARTE_BLANCHE: 'diners_club_carte_blanche.svg',
        DINERS_CLUB_INTERNATIONAL: 'diners_club_carte_blanche.svg',
        JCB: 'jcb.svg',
        LASER: 'laser.svg',
        VISA_ELECTRON: 'visa_electron.svg',
        MASTERCARD: 'mastercard.svg',
        DISCOVER: 'discover.svg',
        DANKORT: 'dankort.svg',
        MAESTRO: 'maestro.svg',
        UATP: 'uatp.svg',
    });

    static PaymentLocalStorages = Object.freeze({
        REFERENCE: 'Reference',
        PREPAYMENT: 'PrePayment',
        CREDITPAYMENT: 'CreditPayment',
        SAVEDATA: 'saveData',
        WITHDRAW: 'withdraw',
        SWITCH: 'switch',
    });

    static PayamentPage = '/payment';
    static PayamentSecure = '/paymentsecure';

    static ConfigurationKey = Object.freeze({
        MINIMUM_TOPUP_AMOUNT: 'MINIMUM_TOPUP_AMOUNT',
        MAX_PREPAYMENT_BALANCE: 'MAX_PREPAYMENT_BALANCE',
        DEFAULT_LOCATION_IMAGE: 'DEFAULT_LOCATION_IMAGE',
        CHARITY_DONATION_LINK: 'CHARITY_DONATION_LINK',
        CHARITY_DONATION_IMAGE: 'CHARITY_DONATION_IMAGE',
        CHARITY_DONATION_TEXT: 'CHARITY_DONATION_TEXT',
        CHARITY_DONATION: 'CHARITY_DONATION',
        COOKIE_POLICY_URL: 'COOKIE_POLICY_URL',
        SAVE_CARD_CHARGE: 'SAVE_CARD_CHARGE',
        WITHDRAW_ALLOWED: 'WITHDRAW_ALLOWED',
        MIN_TRANSACTION_AMOUNT: 'MIN_TRANSACTION_AMOUNT',
        MAX_TOPUP_AMOUNT: 'MAX_TOPUP_AMOUNT',
        CONTACT_US_PHONE: 'CONTACT_US_PHONE',
        DISABLE_REFERRAL: 'DISABLE_REFERRAL',
        PAY_NOW_DURATION: 'PAY_NOW_DURATION',
        GIFT_AID_ENABLE: 'GIFT_AID_ENABLE',
        GIFT_AID_MESSAGE: 'GIFT_AID_MESSAGE',
        REFERRAL_BONUS: 'REFERRAL_BONUS',
        RFID_CARD_PROCESSING_DAYS: 'RFID_CARD_PROCESSING_DAYS',
        DONATION_TOPUP_OR_ROUNDUP: 'DONATION_TOPUP_OR_ROUNDUP',
        DONATION_VALUE: 'DONATION_VALUE',
        CREDIT_ACCOUNT_DONATION_NOTE: 'CREDIT_ACCOUNT_DONATION_NOTE',
        PREPAYMENT_ACCOUNT_DONATION_NOTE: 'PREPAYMENT_ACCOUNT_DONATION_NOTE',
        FAQ_PRICE_PER_KWH: 'FAQ_PRICE_PER_KWH',
        DONATION: 'DONATION',
        MINIMUM_TRANSACTION_AMOUNT_FOR_CHARITY: 'MINIMUM_TRANSACTION_AMOUNT_FOR_CHARITY',
        PRIVACY_POLICY: 'PRIVACY_POLICY',
    });
    static RewardRedeemedType = Object.freeze({
        CREDIT: 'CREDIT',
        DEBIT: 'DEBIT',
    });
    static PaymentTransactionType = Object.freeze({
        CREDIT: 'CREDIT',
        DEBIT: 'DEBIT',
    });
    static RefundRequestStatus = Object.freeze({
        PROCESSING: 'PROCESSING',
        COMPLETE: 'COMPLETE',
        CANCELED: 'CANCELED',
        DECLINED: 'DECLINED',
    });
    public static readonly ivEncryptionAlgorithm = 'aes-256-cbc';

    static PaymentServiceProvider = Object.freeze({
        DNA: 'DNA',
        OPTOMANY: 'OPTOMANY',
        DATATRANS: 'DATATRANS',
    });

    static ChargingSessionStatus = Object.freeze({
        CANCELLED: 'CANCELLED',
        ACTIVE: 'ACTIVE',
        PENDING: 'PENDING',
        INVALID: 'INVALID',
        COMPLETED: 'COMPLETED',
    });

    public static readonly MARKETING_CONSENT = 'MARKETING_CONSENT';
}
