import React, { Component, FC } from 'react';
import { IReferralProps } from '../../types/PropTypes';
import { useParams } from 'react-router-dom';
import images from '../../assets/images';

const ReferralHOC: FC<any> = (props) => {
    const params = useParams();

    return <Referral {...props} params={params} />;
};

class Referral extends Component<IReferralProps> {
    async componentDidMount() {
        let myParam = this.props.params?.id;

        if (myParam == null) {
            myParam = '';
        }
        let baseURL = `${window.location.origin}/sign-up?code=${myParam}`;
        let url = baseURL;

        const userAgent = navigator.userAgent.toLowerCase();
        if (userAgent.includes('iphone') || userAgent.includes('ipad') || userAgent.includes('ipod')) {
            url = 'instavolt-dev://referral?code=' + myParam;
            baseURL = 'https://apps.apple.com/gb/app/instavolt/id1516767939';
        } else if (userAgent.includes('android')) {
            if (myParam !== '') {
                url = 'instavolt-dev://referral?code=' + myParam;
                baseURL =
                    'https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id%3dcom.app.instavolt%26referrer%3d' +
                    myParam;
            } else {
                url = 'instavolt-dev://referral?code=' + myParam;
                baseURL =
                    'https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id%3dcom.app.instavolt';
            }
        }

        this.share(url, baseURL);
    }

    share = (url, baseURL) => {
        window.location = url;
        setTimeout(function () {
            if (!document.webkitHidden) {
                window.location = baseURL;
            }
        }, 10000);
    };

    render() {
        return (
            <>
                <div className="card-body text-center loaderBody">
                    <div className="row min-vh-50 align-items-center">
                        <div className="col">
                            <div className="mesg-box process-block text-center">
                                <img src={images.processGIF} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default ReferralHOC;
