import { IAppAction, ActionType } from '../actions/Helpers';
import { LanguageInfo } from 'store/state/language.state';

export const LanguageReducer = (state: LanguageInfo = null, action: IAppAction): LanguageInfo => {
    switch (action.type) {
        case ActionType.SUPPORTED_LANGUAGES:
            return {
                ...state,
                supportedLanguages: action.payload,
            };
        case ActionType.DEFAULT_LANGUAGE:
            return {
                ...state,
                defaultLanguage: action.payload,
            };
        default:
            return state;
    }
};
