import React, { Component } from 'react';
import { IOpenSourceDetails } from '../../types/stateTypes';
import { openSourceList } from './OpenSourceList';
import { IOpenSourceProps } from '../../types/propTypes';
import { WithTranslation, withTranslation } from 'react-i18next';

export class OpenSource extends Component<IOpenSourceProps & WithTranslation> {
    render() {
        const { t } = this.props;
        return (
            <>
                <div className="open-source">
                    <div className="wrapper">
                        <main>
                            <h1>{t('openSource.title')}</h1>
                            <div className="detail-wrapper">
                                {openSourceList &&
                                    openSourceList.length > 0 &&
                                    openSourceList.map((data: IOpenSourceDetails) => {
                                        return (
                                            <div className="detail-block">
                                                <p>
                                                    {data.name} <br /> <br />
                                                    {data.description}
                                                </p>
                                                <div className="links-wrapper">
                                                    <a
                                                        href={data.url}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        className="link"
                                                    >
                                                        {data.license}
                                                    </a>
                                                </div>
                                            </div>
                                        );
                                    })}
                            </div>
                        </main>
                    </div>
                </div>
            </>
        );
    }
}

export default withTranslation()(OpenSource);
