import React, { FC } from 'react';
import { useEffect } from 'react';
import { getDNAPaymentScript } from '../../utils/Payment';
import Spinner from '../../layout/spinner';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import { companyNameReplace } from '../../utils';
declare global {
    interface Window {
        DNAPayments: any;
    }
}

function getPaymentData(
    access_token: string,
    accountId: string,
    invoiceId: string,
    terminal: string,
    amount: number,
    currency: string,
    language: string,
    transactionType: string,
    donationAmount: number,
) {
    return {
        paymentSettings: {
            terminalId: terminal,
            returnUrl: `${window.location.origin}${window.location.pathname}?success=true&iid=${invoiceId}`,
            failureReturnUrl: `${window.location.origin}${window.location.pathname}?success=false&iid=${invoiceId}`,
            callbackUrl: `${process.env.REACT_APP_API_SERVER}/${process.env.REACT_APP_API_VERSION}/driver/payment/dna-webhook/${invoiceId}`,
            failureCallbackUrl: `${process.env.REACT_APP_API_SERVER}/${process.env.REACT_APP_API_VERSION}/driver/payment/dna-webhook/${invoiceId}`,
            cscMatrix: 22,
            paMatrix: 3,
        },
        customerDetails: {
            accountDetails: {
                accountId: accountId,
            },
        },
        periodic: {
            periodicType: 'ucof',
        },
        invoiceId,
        amount,
        auth: { access_token: access_token },
        description: companyNameReplace(t('Payment.description')),
        language: language || 'en',
        currency,
        transactionType,
        donationAmount,
    };
}

const PaymentPage: FC = (props: any) => {
    useEffect(() => {
        if (props && props.location && props.location.search.includes('success=false&redirect=true')) {
            props.navigate(`/payment${props.location.search}`);
        } else if (props && props.location && props.location.search.includes('success=false')) {
            props.navigate(`/payment${props.location.search}`);
        }

        const params: any = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop: any) => searchParams.get(prop),
        });
        const isTestMode =
            params.isTestMode === undefined ||
            params.isTestMode === null ||
            params.isTestMode === 'true' ||
            params.isTestMode === true
                ? true
                : false;

        (document as any).getElementById('loading').classList.remove('spinner-disable');
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = getDNAPaymentScript(isTestMode);
        script.nonce = 'ils+vsV+OAN1z2QUGBzoyQ==';
        script.async = true;
        script.onload = () => openPaymentPage(params, isTestMode);
        script.onerror = () => {
            window.location.href = `${window.location.origin}${window.location.pathname}?success=false&errorcode=payment_script_load_fail&message=unable%20to%20load%20payment%20script`;
        };
        if ((params.success || '').length === 0) {
            document.body.appendChild(script);
        }
    }, [props]);

    return (
        <>
            <div>
                <Spinner />
            </div>
        </>
    );
};

const openPaymentPage = (params, isTestMode: boolean) => {
    const isEnableDonation = params.isEnableDonation === 'true' ? true : false;
    const donationAmount = params.donationAmount ? Number(params.donationAmount) : 0;
    const config = {
        autoRedirectDelayInMs: 10000,
        scopes: {
            allowHosted: true,
        },
        paymentMethods: [
            {
                name: window.DNAPayments.paymentMethods.BankCard,
            },
        ],
        isTestMode: isTestMode,
        paymentTimeoutInSeconds: 900,
        isEnableDonation,
        locale: {
            targetLocale: params.targetLocale || 'en_GB',
        },
    };

    window.DNAPayments.configure(config);

    window.DNAPayments.openPaymentPage(
        getPaymentData(
            params.accessToken,
            params.accountId,
            params.invoiceId,
            params.terminal,
            +params.amount,
            params.currency,
            params.language,
            params.transactionType,
            donationAmount,
        ),
    );
};

export default withTranslation()(PaymentPage);
