import React from 'react';
import { Helmet } from 'react-helmet';
import { ISignUpOTPProps } from '../../types/propTypes';
import { ISignUpOTPState } from '../../types/stateTypes';
import { Formik, Field } from 'formik';
import { Form, Button } from 'react-bootstrap';
import { ReactstrapInput } from '../../components/reactstrap-formik';
import { SignUpOTPSchema } from '../../validation/login/login-validation';
import { ISignUpOTPFormField } from '../../models/login';
import { Common } from '../../utils/constants';
import LoginService from '../../services/login/login-service';
import { toast } from 'react-toastify';
import images from '../../assets/images';
import { companyNameReplace } from '../../utils';

class SignUpOTPForm extends React.Component<ISignUpOTPProps, ISignUpOTPState> {
    private initialState: ISignUpOTPFormField = {
        code: '',
    };
    loginService: LoginService;
    constructor(props: ISignUpOTPProps) {
        super(props);
        this.loginService = new LoginService();
    }
    async componentDidMount() {
        if (
            this.props.isDriverVerified !== undefined &&
            this.props.isDriverVerified !== null &&
            !this.props.isDriverVerified
        ) {
            this.resendOtpSubmit();
        }
    }
    handleSubmit = (value: ISignUpOTPFormField) => {
        value.username = this.props.email.toLowerCase();
        return this.loginService
            .signupConfirm(value)
            .then(async (res: any) => {
                if (
                    res === Common.Authentication.SUCCESS &&
                    this.props.isDriverVerified !== undefined &&
                    this.props.isDriverVerified !== null &&
                    !this.props.isDriverVerified
                ) {
                    return this.props.onChangeStage(Common.AuthStage.FORGOT_PASSWORD);
                }
                if (res === Common.Authentication.SUCCESS && !this.props.isLoginVerified) {
                    this.props.onSignupLogin();
                }
                if (res === Common.Authentication.SUCCESS && this.props.isLoginVerified) {
                    this.props.onDriverReLogin();
                }
            })
            .catch((err) => {
                if (err && err.code === Common.Authentication.CodeMismatchException) {
                    toast.error(this.props.t('signup.toast.invalidCode'));
                } else {
                    toast.error(this.props.t('signup.toast.error'));
                }
            })
            .finally();
    };

    resendOtpSubmit = async () => {
        await this.loginService
            .resendOtp(this.props.email)
            .then(() => {
                toast.success(this.props.t('signup.toast.resendSignupCode'));
            })
            .catch((error) => {
                if (error && error.code === 'LimitExceededException') {
                    toast.error(this.props.t('signup.toast.resendLimitExceeded'));
                } else {
                    toast.error(this.props.t('signup.toast.error'));
                }
            });
    };

    render() {
        const { t, companyName } = this.props;
        return (
            <React.Fragment>
                <Helmet>
                    <title>{companyNameReplace(t('signup.title'))} </title>
                </Helmet>
                <button
                    type="button"
                    className="btn btn-icon btn-link pr-0 btn-back"
                    onClick={() => this.props.onChangeStage(Common.AuthStage.SIGNIN)}
                    title={t('signup.button.back')}
                >
                    <img className="icon" src={images.backArrowRSVG} alt={t('signup.button.back')} />
                </button>
                <div className="title-outer">
                    <h1 className="title">
                        <span className="icon">
                            <img src={images.emailSVG} alt={t('navbar.title')} />
                        </span>
                        {t('signup.label.otpConfirmationTitle1')}
                    </h1>
                    {this.props.isDriverVerified !== undefined &&
                    this.props.isDriverVerified !== null &&
                    !this.props.isDriverVerified ? (
                        <p>
                            {t('signup.label.otpNonVerifiedEmailNote1')}
                            <span className="text-primary"> {this.props.email}</span>{' '}
                            {t('signup.label.otpNonVerifiedEmailNote2')}
                        </p>
                    ) : (
                        <p>
                            {t('signup.label.otpConfirmationTitle2')}
                            <span className="text-primary d-block pt-2">{this.props.email}</span>
                        </p>
                    )}
                </div>
                <Formik
                    initialValues={this.initialState}
                    onSubmit={this.handleSubmit}
                    validationSchema={SignUpOTPSchema(t)}
                >
                    {(props) => {
                        const { handleSubmit } = props;
                        return (
                            <form className="form-secondary" onSubmit={handleSubmit} autoComplete="off" noValidate>
                                <Field
                                    type="text"
                                    name="code"
                                    id="code"
                                    label={t('signup.form.code')}
                                    component={ReactstrapInput}
                                />
                                <Form.Group className="form-group">
                                    <Button type="submit" className="btn btn-primary w-100">
                                        {t('signup.button.submitOtp')}
                                    </Button>
                                </Form.Group>
                                <div className="clearfix pt-4">
                                    <p className="sign-up">
                                        {t('signup.label.resendTitle')}{' '}
                                        <a href="#" title={t('signup.label.resend')} onClick={this.resendOtpSubmit}>
                                            {t('signup.button.resend')}
                                        </a>
                                    </p>
                                </div>
                            </form>
                        );
                    }}
                </Formik>
            </React.Fragment>
        );
    }
}

export default SignUpOTPForm;
