import Axios from 'axios';
import StandardResponse from '../../models/standard-response';

export default class StationDetailsService {
    getStationDetail = async (id: number) => {
        try {
            const res = await Axios.post(
                `${process.env.REACT_APP_API_SERVER}/${process.env.REACT_APP_API_VERSION}/location/charge-station/${id}`,
            );
            return new StandardResponse<number>(res.status, res.data.data, res.data.message, null);
        } catch (e) {
            return Promise.reject(e);
        }
    };

    getConfigurationDetail = async (data: any) => {
        try {
            const res = await Axios.post(
                `${process.env.REACT_APP_API_SERVER}/${process.env.REACT_APP_API_VERSION}/driver/public/configuration/search`,
                data,
            );
            return Promise.resolve(res.data);
        } catch (e) {
            return Promise.reject(e);
        }
    };
}
