import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { IAboutUsProps } from '../../types/propTypes';
import UserService from '../../services/user/user-service';
import { IAboutUsState } from '../../types/stateTypes';
import images from '../../assets/images';
import { store } from '../../store';
import _ from 'lodash';

export class AboutUs extends Component<IAboutUsProps & WithTranslation> {
    state: IAboutUsState;
    userService: UserService;
    constructor(props) {
        super(props);
        this.state = {
            aboutUsArray: [],
            optomanyUrl: '',
            CPOSoftwareServiceUrl: '',
        };
        this.userService = new UserService();
    }
    async componentDidMount() {
        await this.getImageUrl();

        const queryStringLang = new URLSearchParams(window.location.search);
        const languageInfo = store?.getState()?.LanguageInfo?.supportedLanguages;
        const changelanguage = languageInfo
            ? languageInfo?.filter(
                  (item) =>
                      item?.code === queryStringLang.get('langCode') || item?.code === queryStringLang.get('language'),
              )[0]
            : { code: queryStringLang.get('langCode') || queryStringLang.get('language') };
        let regionCode = process.env.REACT_APP_PORTAL_REGION?.toLocaleLowerCase();
        let languageCode;
        if (queryStringLang.get('langCode') || queryStringLang.get('language')) {
            languageCode = changelanguage?.code;
        } else {
            if (store.getState()?.UserData) {
                languageCode = store.getState()?.UserData?.language?.code;
            } else if (store.getState()?.LanguageInfo) {
                languageCode = store.getState()?.LanguageInfo?.defaultLanguage?.code;
            }
        }
        let jsonFile = require(`../../assets/aboutUs/uk/en.json`);
        if (regionCode && languageCode) {
            jsonFile = require(`../../assets/aboutUs/${regionCode}/${languageCode}.json`);
        }
        let aboutUs = JSON.stringify(jsonFile?.aboutus);
        aboutUs = _.replace(aboutUs, '##IMAGE_URL##', this.state.CPOSoftwareServiceUrl);
        //it only replace once to second is added due to second value replace
        aboutUs = _.replace(aboutUs, '##IMAGE_URL##', this.state.optomanyUrl);
        aboutUs = _.replace(aboutUs, '##IMAGE_URL##', images.rightArrowDarkSVG);
        const aboutUsList = JSON.parse(aboutUs);
        this.setState({ aboutUsArray: aboutUsList });
    }
    getImageUrl = async () => {
        const optomany = this.userService.getConfigurationDetail({ code: 'PAYMENT_PROVIDER_LOGO' });
        const CPOSoftwareService = this.userService.getConfigurationDetail({ code: 'CHARGING_PROVIDER_LOGO' });
        const res = [optomany, CPOSoftwareService];
        await Promise.all(res)
            .then(([optomanyData, CPOSoftwareServiceData]) => {
                this.setState({
                    optomanyUrl: optomanyData?.data[0]?.value,
                    CPOSoftwareServiceUrl: CPOSoftwareServiceData?.data[0]?.value,
                });
            })
            .catch((error) => console.log(error));
    };
    BackButton = () => {
        window.location.href = `${process.env.REACT_APP_REDIRECT_URL}/open-source`;
    };
    render() {
        return (
            <>
                <div className="open-source">
                    <div className="wrapper">
                        <main>
                            <div className="detail-wrapper">
                                {this.state.aboutUsArray.length > 0 &&
                                    this.state.aboutUsArray.map((data) => {
                                        return (
                                            <>
                                                {data.name === this.props.t('aboutUs.listTitle.openSourcelicenses') ? (
                                                    <a style={{ width: '5%' }} onClick={() => this.BackButton()}>
                                                        <div className="detail-block-open-source">
                                                            <p style={{ paddingBottom: '0px', fontWeight: '900' }}>
                                                                {data.name}
                                                            </p>
                                                            <img
                                                                style={{ paddingLeft: '30px' }}
                                                                className="fit-img"
                                                                src={data.url}
                                                                alt={data.name}
                                                            />{' '}
                                                        </div>{' '}
                                                    </a>
                                                ) : (
                                                    <div className="detail-block-about-us">
                                                        <p>{data.name}</p>
                                                        <img className="fit-img" src={data.url} alt={data.name} />
                                                    </div>
                                                )}
                                            </>
                                        );
                                    })}
                            </div>
                        </main>
                    </div>
                </div>
            </>
        );
    }
}

export default withTranslation()(AboutUs);
