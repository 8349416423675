import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import images from '../../assets/images';

const styles = StyleSheet.create({
    page: { backgroundColor: 'white', flex: 1, padding: 0 },
    logo: { alignSelf: 'center', marginTop: 20, marginBottom: 20 },
    logoImg: { height: 30 },
    Header: { flexDirection: 'row', paddingTop: 20, paddingBottom: 20 },
    Hr: { borderTopWidth: 1, borderColor: 'black', marginLeft: 30, marginRight: 30 },
    Message: { fontSize: 16, textAlign: 'center', marginLeft: 30, marginRight: 30 },
});

const NoDataReceipt = (props) => {
    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.logo}>
                    <Image source={images.logoInstavoltPNG} style={styles.logoImg} />
                </View>
                <View style={styles.Hr}></View>
                <View style={styles.Header}>
                    <Text style={styles.Message}>
                        {props.isUnauthorisedUser
                            ? props.t('history.receipt.content.vatReceiptNotAssociatedWithAcc')
                            : props.t('history.receipt.content.vatReceiptNotGenerated')}
                    </Text>
                </View>
            </Page>
        </Document>
    );
};

export default NoDataReceipt;
