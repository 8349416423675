import _ from 'lodash';
import React from 'react';
import NumberFormat from 'react-number-format';
import { ICurrencyFormat, INumberFormat } from '../../models/login';
import { store } from '../../store';
import { Common } from '../../utils/constants';

const getIndicesOf = (searchStr, str) => {
    var searchStrLen = searchStr?.length;
    if (searchStrLen == 0) {
        return [];
    }
    var startIndex = 0,
        index,
        indices = [];
    while ((index = str?.indexOf(searchStr, startIndex)) > -1) {
        indices.push(index);
        startIndex = index + searchStrLen;
    }
    return indices;
};

export const defaultNumberFormat: INumberFormat = {
    GENERAL_NUMBER_FORMAT: '###,###,###.##',
    FINANCIAL_NUMBER_FORMAT: '###,###,###.####',
    NUMBER_DECIMAL_SEPARATOR: '.',
    GENERAL_NUMBER_FORMAT_WITHOUT_DECIMAL_PLACES: '###.###.###.###',
};

export const defaultCurrencyFormat: ICurrencyFormat = {
    ENABLE_CURRENCY_ROUNDING: 'off',
    CURRENCY_SYMBOL_UI_PLACEMENT: 'left',
    GENERAL_CURRENCY_FORMAT: '###,###,###.##',
    FINANCIAL_CURRENCY_FORMAT: '###,###,###.####',
    DEFAULT_CURRENCY: '£',
};

export const getNumberFormat = () => {
    if (!_.isEmpty(store?.getState()?.configuration?.number_format)) {
        return store.getState()?.configuration.number_format;
    } else {
        return defaultNumberFormat;
    }
};

export const getCurrencyFormat = () => {
    if (!_.isEmpty(store?.getState()?.configuration?.currency_format)) {
        return store.getState()?.configuration.currency_format;
    } else {
        return defaultCurrencyFormat;
    }
};

export const localFormat = (number: string, displayType: 'inputString' | 'text' | 'string', pattern?: string) => {
    let firstChar = number?.toString().indexOf('-');
    if (firstChar !== -1) {
        number = number?.replace('-', '');
    }
    if (!pattern) {
        pattern = getNumberFormat()?.GENERAL_NUMBER_FORMAT;
    }
    let formatedNumber = '';
    let regularDecimal = Common.REGULAR_DECIMAL_SEPERATOR;
    number = Number(number) || Number(number) === 0 ? Number(number).toString() : number;
    const decimalSeperator = getNumberFormat()?.NUMBER_DECIMAL_SEPARATOR;
    let arr = getIndicesOf('#', pattern?.split(decimalSeperator)[0]);
    if (arr.length < number?.split(regularDecimal)[0]?.length) {
        for (let i = 0; i < number?.split(regularDecimal)[0]?.length - arr?.length; i++) {
            pattern = '#' + pattern;
        }
    }

    arr = getIndicesOf('#', pattern?.split(decimalSeperator)[0]);
    pattern = pattern.slice(arr[arr.length - number?.split(regularDecimal)[0]?.length], pattern.length);

    //for input text box or string value
    let formatFirstPart;
    if (
        displayType === 'string' ||
        displayType === 'inputString' ||
        pattern === getNumberFormat()?.GENERAL_NUMBER_FORMAT_WITHOUT_DECIMAL_PLACES
    ) {
        formatedNumber = pattern;
        let decimalSeperatorValue = displayType === 'inputString' ? decimalSeperator : regularDecimal;
        if (decimalSeperator !== regularDecimal && number?.includes(regularDecimal)) {
            decimalSeperatorValue = regularDecimal;
        } else if (decimalSeperator === regularDecimal && !number?.includes(regularDecimal)) {
            //to extract unknown decimal seperator
            let seperator = number?.replace(/[0-9]/g, '');
            if (seperator !== '' && seperator?.length === 1) {
                decimalSeperatorValue = seperator;
            }
        }
        const firstPart = number?.split(decimalSeperatorValue)[0]?.replace(/\D/g, '');
        if (displayType === 'inputString') {
            formatFirstPart = formatedNumber?.split(decimalSeperator)[0]?.replace(/\,*\.*/g, '');
            formatFirstPart = formatFirstPart?.substring(0, firstPart?.length);
        } else {
            // @ts-ignore - to ignore typescript error because separatorToReplace variable is used in replace function
            const separatorToReplace = decimalSeperator === regularDecimal ? regularDecimal : decimalSeperator;
            formatFirstPart = formatedNumber?.split(decimalSeperator)[0]?.replace(/\`${separatorToReplace}`*/g, '');

            //to make num of digits and pattern same length
            const separator = decimalSeperator === regularDecimal ? decimalSeperator : regularDecimal;
            const numberOfOccurencesOfSeparator = formatFirstPart.split(separator).length - 1;
            let numOfAllSeperators = (formatFirstPart.match(/[^#]/g) || []).length;
            formatFirstPart = formatFirstPart.substring(
                0,
                formatFirstPart.includes(decimalSeperator) || formatFirstPart.includes(regularDecimal)
                    ? firstPart.length + numberOfOccurencesOfSeparator
                    : firstPart.length + numOfAllSeperators,
            );
        }

        let formatSecondPart = formatedNumber?.split(decimalSeperator)[1];
        for (let j = 0; j < firstPart?.length; j++) {
            formatFirstPart = formatFirstPart?.replace('#', `${('' + number)[j]}`);
        }
        for (let j = 0; j < pattern?.split(decimalSeperator)[1]?.length; j++) {
            if (
                number?.split(decimalSeperatorValue)[1] !== undefined &&
                (number?.split(decimalSeperatorValue)[1])[j] !== undefined
            ) {
                formatSecondPart = formatSecondPart?.replace(
                    '#',
                    `${('' + number?.split(decimalSeperatorValue)[1])[j]}`,
                );
            } else {
                formatSecondPart = formatSecondPart?.replace('#', `${'' + 0}`);
            }
        }
        formatedNumber =
            (firstChar !== -1 ? '-' : '') +
            formatFirstPart +
            (decimalSeperator === null ? '' : decimalSeperator) +
            (formatSecondPart ? formatSecondPart : '');
    }
    const calculatedValue = Number(number).toFixed(pattern.split(decimalSeperator)[1]?.length);
    if (
        displayType === 'string' ||
        displayType === 'inputString' ||
        pattern === getNumberFormat()?.GENERAL_NUMBER_FORMAT_WITHOUT_DECIMAL_PLACES
    ) {
        return pattern === getNumberFormat()?.GENERAL_NUMBER_FORMAT_WITHOUT_DECIMAL_PLACES
            ? (firstChar !== -1 ? '-' : '') + formatFirstPart
            : formatedNumber;
    } else {
        if (firstChar !== -1) {
            //for negative values
            return (
                <>
                    {'-'}
                    {
                        <NumberFormat
                            className="patternField"
                            value={calculatedValue}
                            format={pattern}
                            displayType={displayType}
                        />
                    }
                </>
            );
        } else {
            return (
                <NumberFormat
                    className="patternField"
                    value={calculatedValue}
                    format={pattern}
                    displayType={displayType}
                />
            );
        }
    }
};

export const localCurrencyFormat = (
    number: string,
    displayType: 'inputString' | 'text' | 'string',
    pattern?: string,
    disableRounding: boolean = false,
) => {
    let firstChar = number?.toString().indexOf('-');
    if (firstChar !== -1) {
        number = number?.replace('-', '');
    }
    const regularDecimal = Common.REGULAR_DECIMAL_SEPERATOR;
    let decimalSeperator = getNumberFormat()?.NUMBER_DECIMAL_SEPARATOR;

    if (getCurrencyFormat()?.ENABLE_CURRENCY_ROUNDING?.toLocaleLowerCase() === 'on' && !disableRounding) {
        let decimal_point;
        if (number?.includes(decimalSeperator)) {
            number = number?.toString()?.replace(decimalSeperator, regularDecimal);
        }
        decimal_point = (number as string)?.split(regularDecimal)[0]?.length;
        number = `${
            +((Number(number) * Math.pow(10, 2)) % 1).toFixed(1) === 0.5
                ? +(Math.ceil(Number(number) * Math.pow(10, decimal_point)) / Math.pow(10, decimal_point))
                : Math.round(Number(number))?.toString()
        }`;
        let patternArray = pattern
            ? pattern?.split(getNumberFormat()?.NUMBER_DECIMAL_SEPARATOR)
            : getCurrencyFormat()?.GENERAL_CURRENCY_FORMAT?.split(getNumberFormat()?.NUMBER_DECIMAL_SEPARATOR);
        pattern =
            patternArray && patternArray.length > 0 ? patternArray[0] : getCurrencyFormat()?.GENERAL_CURRENCY_FORMAT;
    } else {
        if (!pattern) {
            pattern = getCurrencyFormat()?.GENERAL_CURRENCY_FORMAT;
        }
    }
    let formatedNumber: any = '';
    number = Number(number) || Number(number) === 0 ? Number(number).toString() : number;
    let arr = getIndicesOf('#', pattern?.split(decimalSeperator)[0]);
    if (arr.length < number?.split(regularDecimal)[0]?.length) {
        for (let i = 0; i < number?.split(regularDecimal)[0]?.length - arr?.length; i++) {
            pattern = '#' + pattern;
        }
    }
    if (parseInt(number) && pattern!.indexOf(decimalSeperator) > -1 && pattern!.split(decimalSeperator).length > 1) {
        number = parseFloat(number.replace(decimalSeperator, '.')).toFixed(pattern!.split(decimalSeperator)[1].length);
    }
    arr = getIndicesOf('#', pattern?.split(decimalSeperator)[0]);
    pattern = pattern.slice(arr[arr.length - number?.split(regularDecimal)[0]?.length], pattern.length);
    let formatFirstPart;
    //for input text box or string value
    // if(displayType === "string" || displayType === "inputString"){
    formatedNumber = pattern;
    let decimalSeperatorValue = displayType === 'inputString' ? decimalSeperator : regularDecimal;
    if (decimalSeperator !== regularDecimal && number?.includes(regularDecimal)) {
        decimalSeperatorValue = regularDecimal;
    } else if (decimalSeperator === regularDecimal && !number?.includes(regularDecimal)) {
        //to extract unknown decimal seperator
        let seperator = number?.replace(/[0-9]/g, '');
        if (seperator !== '' && seperator?.length === 1) {
            decimalSeperatorValue = seperator;
        }
    }
    const firstPart = number?.split(decimalSeperatorValue)[0]?.replace(/\D/g, '');
    if (displayType === 'inputString') {
        formatFirstPart = formatedNumber?.split(decimalSeperator)[0]?.replace(/\,*\.*/g, '');
        formatFirstPart = formatFirstPart?.substring(0, firstPart?.length);
    } else {
        // @ts-ignore - to ignore typescript error because separatorToReplace variable is used in replace function
        const separatorToReplace = decimalSeperator === regularDecimal ? regularDecimal : decimalSeperator;
        formatFirstPart = formatedNumber?.split(decimalSeperator)[0]?.replace(/\`${separatorToReplace}`*/g, '');
        //to make num of digits and pattern same length
        const separator = decimalSeperator === regularDecimal ? decimalSeperator : regularDecimal;
        const numberOfOccurencesOfSeparator = formatFirstPart.split(separator).length - 1;
        let numOfAllSeperators = (formatFirstPart.match(/[^#]/g) || []).length;
        formatFirstPart = formatFirstPart.substring(
            0,
            formatFirstPart.includes(decimalSeperator) || formatFirstPart.includes(regularDecimal)
                ? firstPart?.length + numberOfOccurencesOfSeparator
                : firstPart?.length + numOfAllSeperators,
        );
    }

    let formatSecondPart = formatedNumber?.split(decimalSeperator)[1];
    for (let j = 0; j < firstPart?.length; j++) {
        formatFirstPart = formatFirstPart?.replace('#', `${('' + number)[j]}`);
    }
    for (let j = 0; j < pattern?.split(decimalSeperator)[1]?.length; j++) {
        if (
            number?.split(decimalSeperatorValue)[1] !== undefined &&
            (number?.split(decimalSeperatorValue)[1])[j] !== undefined
        ) {
            formatSecondPart = formatSecondPart?.replace('#', `${('' + number?.split(decimalSeperatorValue)[1])[j]}`);
        } else {
            formatSecondPart = formatSecondPart?.replace('#', `${'' + 0}`);
        }
    }
    formatedNumber =
        (firstChar !== -1 ? '-' : '') +
        formatFirstPart +
        (decimalSeperator === null ? '' : decimalSeperator) +
        (formatSecondPart ? formatSecondPart : '');
    //   }
    // const calculatedValue = !number?.toString()?.includes(decimalSeperator)
    //     ? Number(number).toFixed(pattern.split(decimalSeperator)[1]?.length)
    //     : number;
    const calculatedValue = formatedNumber;

    if (displayType === 'string' || displayType === 'inputString') {
        return getCurrencyFormat()?.ENABLE_CURRENCY_ROUNDING?.toLocaleLowerCase() === 'on' && !disableRounding
            ? (firstChar !== -1 ? '-' : '') + formatFirstPart
            : formatedNumber;
    } else {
        if (firstChar !== -1) {
            //for negative values
            return (
                <>
                    {getCurrencyFormat()?.ENABLE_CURRENCY_ROUNDING?.toLocaleLowerCase() === 'on' &&
                    !disableRounding &&
                    Math.round(Number(calculatedValue)) === 0
                        ? ''
                        : '-'}
                    {
                        <NumberFormat
                            className="patternField"
                            value={calculatedValue}
                            format={pattern}
                            displayType={displayType}
                        />
                    }
                </>
            );
        } else {
            return (
                <NumberFormat
                    className="patternField"
                    value={calculatedValue}
                    format={pattern}
                    displayType={displayType}
                />
            );
        }
    }
};

export const changeDecimalSeperator = (number: string) => {
    //this func is for latitude and longitude values
    let firstChar = number?.toString().indexOf('-');
    if (firstChar !== -1) {
        number = number?.replace('-', '');
    }
    const decimalSeperator = store.getState()?.configuration?.number_format?.NUMBER_DECIMAL_SEPARATOR;
    let value = number?.replace(Common.REGULAR_DECIMAL_SEPERATOR, decimalSeperator);
    if (firstChar !== -1) {
        return value ? `-${value}` : '';
    } else {
        return value ? value : '';
    }
};

export function getFormattedCurrencyWithSymbol(
    value: string,
    displayType: 'inputString' | 'text' | 'string',
    pattern?: string,
    currency_symbol?: string,
    hideCurrency?: boolean,
    disableRounding?: boolean,
) {
    let number;
    if (!hideCurrency) {
        hideCurrency = false;
    }
    const decimalSeperator = getNumberFormat()?.NUMBER_DECIMAL_SEPARATOR;
    if (
        decimalSeperator !== Common.REGULAR_DECIMAL_SEPERATOR &&
        value?.includes(decimalSeperator) &&
        displayType !== 'inputString'
    ) {
        number = value;
    } else {
        let roundingOff =
            (currency_symbol && currency_symbol !== getCurrencyFormat()?.DEFAULT_CURRENCY) || disableRounding;
        number = localCurrencyFormat(value, displayType, pattern, roundingOff);
    }
    if (getCurrencyFormat()?.CURRENCY_SYMBOL_UI_PLACEMENT && currency_symbol && !hideCurrency && number) {
        if (displayType === 'text') {
            return (
                <>
                    {getCurrencyFormat()?.CURRENCY_SYMBOL_UI_PLACEMENT?.toLowerCase() === 'left'
                        ? currency_symbol + ' '
                        : ''}
                    {number}
                    {getCurrencyFormat()?.CURRENCY_SYMBOL_UI_PLACEMENT?.toLowerCase() === 'right'
                        ? ' ' + currency_symbol
                        : ''}
                </>
            );
        } else {
            return `${
                getCurrencyFormat()?.CURRENCY_SYMBOL_UI_PLACEMENT?.toLowerCase() === 'left' ? currency_symbol + ' ' : ''
            }${number}${
                getCurrencyFormat()?.CURRENCY_SYMBOL_UI_PLACEMENT?.toLowerCase() === 'right'
                    ? ' ' + currency_symbol
                    : ''
            }`;
        }
    }
    return number ? number : '';
}

export const getAmountRegex = () => {
    if (getCurrencyFormat()?.ENABLE_CURRENCY_ROUNDING?.toLocaleLowerCase() === 'on') {
        return Common.RegularExpression.DigitsRegularExpression;
    } else {
        return Common.RegularExpression.AmountRegularExpression;
    }
};
