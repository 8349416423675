import React from 'react';
import { ISignUpProps } from '../../types/propTypes';
import { Helmet } from 'react-helmet';
import { ISignUpState } from '../../types/stateTypes';
import LoginAPIService from './../../services/login/loginApi-service';
import { ISignUpFormField } from '../../models/login';
import { Formik, Field } from 'formik';
import { Form, Button } from 'react-bootstrap';
import { ReactstrapInput, ReactstrapFormikPasswordInput } from '../../components/reactstrap-formik';
import { SignUpValidationSchema } from '../../validation/login/login-validation';
import { Common } from '../../utils/constants';
import { toast } from 'react-toastify';
import SocialMediaLoginForm from './SocialMediaLoginForm';
import ReCAPTCHA from 'react-google-recaptcha';
import { FormFeedback } from 'reactstrap';
import _ from 'lodash';
import { ConfirmationBox } from '../../components/common/ConfirmationBox';
import SelectBox from '../../components/reactstrap-formik/SelectBox';
import { store } from '../../store';
import images from '../../assets/images';
import { companyNameReplace } from '../../utils';

export class SignUpForm extends React.Component<ISignUpProps, ISignUpState> {
    parameters = new URLSearchParams(window.location.search);
    private initialState: ISignUpFormField = {
        email:
            this?.parameters?.get('email') && this?.parameters?.get('email') !== 'undefined'
                ? `${this?.parameters?.get('email')}`
                : '',
        password: '',
        firstName:
            this?.parameters?.get('firstName') && this?.parameters?.get('firstName') !== 'undefined'
                ? `${this?.parameters?.get('firstName')}`
                : '',
        lastName:
            this?.parameters?.get('lastName') && this?.parameters?.get('lastName') !== 'undefined'
                ? `${this?.parameters?.get('lastName')}`
                : '',
        recaptchaCode: '',
    };
    state: ISignUpState;
    loginApiService: LoginAPIService;
    constructor(props: ISignUpProps) {
        super(props);
        this.state = {
            showModel: false,
            modal: {
                showModal: false,
                header: '',
                body: '',
                callBack: null,
            },
        };
        this.loginApiService = new LoginAPIService();
    }

    handleModal = (body: string, header: string, callBack: Function) => {
        this.setState({
            ...this.state,
            modal: {
                body: body,
                header: header,
                callBack: callBack,
                showModal: !this.state.modal.showModal,
            },
        });
    };

    closeModal = () => {
        this.setState({
            ...this.state,
            modal: {
                body: '',
                header: '',
                callBack: null,
                showModal: false,
            },
        });
    };

    handleSubmitAfterEmailCheck = (value: ISignUpFormField) => {
        const userLang = store.getState()?.UserData?.language
            ? store.getState()?.UserData?.language
            : store.getState()?.LanguageInfo?.defaultLanguage;
        return this.loginApiService
            .cognitoSignup({ ...value, language_preference_id: userLang?.id, language_preference_name: userLang?.code })
            .then(async (res: any) => {
                this.props.setEmail(value.email);
                this.props.setPassword(value.password);
                this.props.setEmailPattern(res && res.codeDeliveryDetails && res.codeDeliveryDetails.Destination);
                if (!res.userConfirmed) {
                    this.props.onChangeStage(Common.AuthStage.SIGNUP_OTP);
                }
            })
            .catch((err) => {
                if (err && err.code === Common.Authentication.UsernameExistsException) {
                    toast.error(this.props.t('signup.toast.alreadyExist'));
                    this.closeModal();
                } else if (err && err.code === Common.Authentication.referaalCodeMisMatch) {
                    toast.error(this.props.t('signup.toast.referralCodeInvalid'));
                    this.closeModal();
                } else {
                    toast.error(this.props.t('signup.toast.error'));
                    this.closeModal();
                }
            })
            .finally();
    };

    handleSubmit = async (value) => {
        const input = {
            email: value.email,
            federated_account_type: null,
        };
        await this.loginApiService
            .checkEmailAlreadyExists(input)
            .then(async (res: any) => {
                const existingEmails = res.Data;
                if (!_.isEmpty(existingEmails)) {
                    let modalMessage = '';
                    modalMessage = companyNameReplace(this.props.t('signup.label.modalMessage'));
                    let emailString = "<span class='themeTextColor'>" + value.email.toString() + '</span>';
                    modalMessage = _.replace(modalMessage, '##EMAIL##', emailString);
                    let accounts = '';
                    const existingEmailsLength = existingEmails.length;
                    existingEmails.forEach((account, index) => {
                        if (account.federated_account_type !== null) {
                            if (existingEmailsLength === 1 || existingEmailsLength === index + 1) {
                                accounts =
                                    accounts +
                                    "<span class='themeTextColor'>" +
                                    account.federated_account_type.toString() +
                                    '</span>';
                            } else {
                                accounts =
                                    accounts +
                                    "<span class='themeTextColor'>" +
                                    account.federated_account_type.toString() +
                                    '</span>' +
                                    ', ';
                            }
                        } else {
                            if (existingEmailsLength === 1 || existingEmailsLength === index + 1) {
                                accounts =
                                    accounts +
                                    "<span class='themeTextColor'>" +
                                    companyNameReplace(this.props.t('signup.label.instavoltUserAccount')) +
                                    '</span>';
                            } else {
                                accounts =
                                    accounts +
                                    "<span class='themeTextColor'>" +
                                    companyNameReplace(this.props.t('signup.label.instavoltUserAccount')) +
                                    '</span>' +
                                    ', ';
                            }
                        }
                    });
                    modalMessage = _.replace(modalMessage, '##FEDERATEDACCOUNT##', accounts);
                    this.handleModal(modalMessage, companyNameReplace(this.props.t('signup.label.instavolt')), () =>
                        this.handleSubmitAfterEmailCheck(value),
                    );
                } else {
                    this.handleSubmitAfterEmailCheck(value);
                }
            })
            .catch((m) => {
                if (
                    m.response &&
                    m.response.status &&
                    m.response.status === 409 &&
                    m.response.data &&
                    typeof m.response.data === 'object'
                ) {
                    if (Object.keys(m.response.data).length === 0) {
                        let modalMessage = companyNameReplace(
                            this.props.t('signup.label.alreadyRegisteredWithNormalAcc'),
                        );
                        let emailString = "<span class='themeTextColor'>" + value.email.toString() + '</span>';
                        modalMessage = _.replace(modalMessage, '##EMAIL##', emailString);
                        this.handleModal(modalMessage, companyNameReplace(this.props.t('signup.label.instavolt')), () =>
                            this.handleSubmitAfterEmailCheck(value),
                        );
                    } else {
                        let existingEmails = m.response.data.data ? m.response.data.data : null;
                        if (!_.isEmpty(existingEmails)) {
                            let modalMessage = '';
                            modalMessage = companyNameReplace(this.props.t('signup.label.modalMessage'));
                            let emailString = "<span class='themeTextColor'>" + value.email.toString() + '</span>';
                            modalMessage = _.replace(modalMessage, '##EMAIL##', emailString);
                            let accounts = '';
                            const existingEmailsLength = existingEmails.length;
                            existingEmails.forEach((account, index) => {
                                if (account.federated_account_type !== null) {
                                    if (existingEmailsLength === 1 || existingEmailsLength === index + 1) {
                                        accounts =
                                            accounts +
                                            "<span class='themeTextColor'>" +
                                            account.federated_account_type.toString() +
                                            '</span>';
                                    } else {
                                        accounts =
                                            accounts +
                                            "<span class='themeTextColor'>" +
                                            account.federated_account_type.toString() +
                                            '</span>' +
                                            ', ';
                                    }
                                } else {
                                    if (existingEmailsLength === 1 || existingEmailsLength === index + 1) {
                                        accounts =
                                            accounts +
                                            "<span class='themeTextColor'>" +
                                            companyNameReplace(this.props.t('signup.label.instavoltUserAccount')) +
                                            '</span>';
                                    } else {
                                        accounts =
                                            accounts +
                                            "<span class='themeTextColor'>" +
                                            companyNameReplace(this.props.t('signup.label.instavoltUserAccount')) +
                                            '</span>' +
                                            ', ';
                                    }
                                }
                            });
                            modalMessage = _.replace(modalMessage, '##FEDERATEDACCOUNT##', accounts);
                            this.handleModal(
                                modalMessage,
                                companyNameReplace(this.props.t('signup.label.instavolt')),
                                () => this.handleSubmitAfterEmailCheck(value),
                            );
                        } else {
                            toast.error(this.props.t('login.toast.error'));
                        }
                    }
                } else if (m.response && m.response.data && m.response.data.message) {
                    toast.error(m.response.data.message);
                } else {
                    toast.error(m.toString());
                }
            })
            .finally();
    };

    render() {
        const { t, companyName } = this.props;
        return (
            <React.Fragment>
                <Helmet>
                    <title>{companyNameReplace(t('signup.title'))} </title>
                </Helmet>
                <button
                    type="button"
                    className="btn btn-icon btn-link pr-0 btn-back"
                    onClick={() => this.props.onChangeStage(Common.AuthStage.SIGNIN)}
                    title={t('signup.button.back')}
                >
                    <img className="icon" src={images.backArrowRSVG} alt={t('forgot.button.back')} />
                </button>
                <h1 className="title">
                    <img src={images.logoIconPNG} alt={t('navbar.title')} />
                    {t('signup.header')}
                </h1>
                <Formik
                    initialValues={this.initialState}
                    onSubmit={this.handleSubmit}
                    validationSchema={SignUpValidationSchema(t)}
                >
                    {(props) => {
                        const { handleSubmit, values } = props;
                        return (
                            <form className="form-secondary" onSubmit={handleSubmit} autoComplete="off" noValidate>
                                <div className="form-row">
                                    <Field
                                        name="firstName"
                                        id="FirstName"
                                        label={t('signup.form.firstName')}
                                        component={ReactstrapInput}
                                        className="col-sm"
                                        maxLength={20}
                                        inputclassname="capitalize"
                                        onKeyUp={(e) => {
                                            this.props.handleChangeFormValues(e);
                                        }}
                                    />
                                    <Field
                                        name="lastName"
                                        id="LastName"
                                        label={t('signup.form.lastName')}
                                        component={ReactstrapInput}
                                        className="col-sm"
                                        maxLength={20}
                                        inputclassname="capitalize"
                                        onKeyUp={(e) => {
                                            this.props.handleChangeFormValues(e);
                                        }}
                                    />
                                </div>

                                <Field
                                    type="text"
                                    name="email"
                                    id="Email"
                                    label={t('signup.form.email')}
                                    component={ReactstrapInput}
                                    maxLength={50}
                                    onKeyUp={(e) => {
                                        this.props.handleChangeFormValues(e);
                                    }}
                                />
                                <Field
                                    type="password"
                                    name="password"
                                    id="Password"
                                    label={t('signup.form.password')}
                                    autoComplete="new-password"
                                    component={ReactstrapFormikPasswordInput}
                                />
                                <input type="hidden" value={values.recaptchaCode} name="recaptchaCode" />
                                <div align="center">
                                    <ReCAPTCHA
                                        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                                        onChange={(value) => {
                                            props.setFieldValue('recaptchaCode', value);
                                            props.setFieldTouched('recaptchaCode', true);
                                        }}
                                        hl={
                                            store?.getState()?.UserData?.language?.code
                                                ? store?.getState()?.UserData?.language?.code
                                                : store?.getState()?.LanguageInfo?.defaultLanguage?.code
                                        }
                                        key={
                                            store?.getState()?.UserData?.language?.code
                                                ? store?.getState()?.UserData?.language?.code
                                                : store?.getState()?.LanguageInfo?.defaultLanguage?.code
                                        }
                                    />
                                    <div className="text-left codeFixWidth">
                                        {props.touched['recaptchaCode'] &&
                                            props.errors['recaptchaCode'] !== '' &&
                                            props.values.recaptchaCode !== null && (
                                                <FormFeedback
                                                    className="pb-0 error invalid-feedback d-block"
                                                    type="invalid"
                                                >
                                                    {props.errors['recaptchaCode']}
                                                </FormFeedback>
                                            )}
                                    </div>
                                </div>

                                <Form.Group className="pt-3 form-group">
                                    <Button type="submit" className="btn btn-primary w-100">
                                        {t('signup.button.submit')}
                                    </Button>
                                </Form.Group>
                                <SocialMediaLoginForm t={t} title={t('signup.label.continueWith')} />
                                <div className="clearfix border-top-1 pt-4">
                                    <p className="sign-up">
                                        {t('signup.label.accountNew')}{' '}
                                        <a
                                            href="#"
                                            title={t('signup.button.signin')}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                this.props.onChangeStage(Common.AuthStage.SIGNIN);
                                            }}
                                            className="login-btn"
                                        >
                                            {t('signup.button.signin')}
                                        </a>
                                    </p>
                                </div>
                            </form>
                        );
                    }}
                </Formik>
                <ConfirmationBox
                    t={this.props.t}
                    onCancel={this.closeModal}
                    onConfirm={this.state.modal.callBack}
                    header={this.state.modal.header}
                    bodyMessage={<div dangerouslySetInnerHTML={{ __html: this.state.modal.body }}></div>}
                    Message={this.state.modal.body}
                    body={<div dangerouslySetInnerHTML={{ __html: this.state.modal.body }}></div>}
                    showModel={this.state.modal.showModal}
                    closeModel={this.closeModal}
                />
            </React.Fragment>
        );
    }
}
export default SignUpForm;
