import React, { Component, SyntheticEvent } from 'react';
import _ from 'lodash';
import QRCode from 'qrcode.react';
import ReactDOM from 'react-dom';

const IMAGE_TAG = '##image##';
const QRCODE_TAG = '##qrcode##';
const ADDITIONAL_IMAGES = {
    image01: '##image01##',
    image02: '##image02##',
    image03: '##image03##',
    image04: '##image04##',
    image05: '##image05##',
};
interface IPreviewPromotionProps {
    layout: string;
    image: string | Object;
    qrCode: string;
    init: number;
    additionalImages: Array<any>;
}
export class PreviewPromotion extends Component<IPreviewPromotionProps, any> {
    constructor(props: IPreviewPromotionProps) {
        super(props);
        this.state = {
            html: '',
        };
    }

    componentDidMount = () => {
        this.generateHTML();
    };
    errorImage = (e: SyntheticEvent) => {
        e.target.onerror = null;
        e.target.src = '/images/no-image-found.jpg';
    };

    UNSAFE_componentWillReceiveProps = (props: IPreviewPromotionProps) => {
        if (props.init && props.init !== this.props.init) {
            this.generateHTML();
        }
    };

    getImage = () => {
        if (_.isObject(this.props.image)) {
            return URL.createObjectURL(this.props.image);
        }
        return `${process.env.REACT_APP_DRIVER_PORTAL_URL}/${this.props.image}`;
    };

    getAdditonalImage = (imgNumber) => {
        let additionalImage = this.props.additionalImages.find((image) => image.number === imgNumber);
        let image = additionalImage ? additionalImage.image_url : '';
        if (_.isObject(image)) {
            return URL.createObjectURL(image);
        }
        return `${process.env.REACT_APP_DRIVER_PORTAL_URL}/${image}`;
    };
    generateHTML = () => {
        if (this.props.layout) {
            let layout = this.props.layout;
            layout = layout.replace(IMAGE_TAG, `<div id="imageElement"></div>`);
            for (let i = 1; i <= 5; i++) {
                let key = 'image0' + i;
                if (layout.includes(ADDITIONAL_IMAGES[key])) {
                    let imgID = 'imageElement' + i;
                    layout = layout.replace(ADDITIONAL_IMAGES[key], `<div id="${imgID}"></div>`);
                }
            }
            if (this.props.qrCode) {
                layout = layout.replace(QRCODE_TAG, `<div id="qrcodeElement"></div>`);
            }
            this.setState({ html: layout }, () => {
                setTimeout(() => {
                    const element = document.getElementById('qrcodeElement');
                    const imageElement = document.getElementById('imageElement');
                    if (imageElement) {
                        ReactDOM.render(
                            <img className="h100 w100" src={this.getImage()} onError={this.errorImage} alt="" />,
                            imageElement,
                        );
                    }
                    if (element && this.props.qrCode) {
                        ReactDOM.render(<QRCode value={this.props.qrCode} />, element);
                    }

                    for (let i = 1; i <= 5; i++) {
                        let elementID = 'imageElement' + i;
                        const element = document.getElementById(elementID);
                        if (element) {
                            ReactDOM.render(
                                <img
                                    className="h100 w100"
                                    src={this.getAdditonalImage(i)}
                                    onError={this.errorImage}
                                    alt=""
                                />,
                                element,
                            );
                        }
                    }
                }, 500);
            });
        }
    };

    render() {
        const { html } = this.state;
        return (
            <>
                <div dangerouslySetInnerHTML={{ __html: html }}></div>
            </>
        );
    }
}

export default PreviewPromotion;
