import React, { Component, FC } from 'react';
import { Helmet } from 'react-helmet';
import StationDetailsService from '../../services/station-details/station-details-service';
import { toast } from 'react-toastify';
import { IStationDetailState } from '../../types/stateTypes/stateTypes';
import StandardResponse from '../../models/standard-response';
import { Common } from '../../utils/constants';
import { IGetConfigurationData } from '../../models/profile/index';
import images from '../../assets/images';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import { companyNameReplace } from '../../utils';

const StationDetailsHOC: FC<any> = (props) => {
    const params = useParams();

    return <StationDetails {...props} params={params} />;
};
class StationDetails extends Component<any, IStationDetailState> {
    stationDetailsService: StationDetailsService;
    constructor(props) {
        super(props);

        this.state = {
            title: '',
            description: '',
            image: '',
        };

        this.stationDetailsService = new StationDetailsService();
    }

    async UNSAFE_componentWillMount() {
        let myParam = this.props.params?.id;

        if (myParam == null) {
            myParam = '';
        }
        let baseURL = 'https://instavolt.co.uk/locations/';
        let url = baseURL;

        await this.stationDetailsService
            .getStationDetail(myParam)
            .then(async (res: StandardResponse<any>) => {
                const elem = document.querySelectorAll('meta[name=description]');
                if (elem && elem[0]) {
                    elem[0].remove();
                }
                baseURL = `${baseURL}${res.Data.cp_id}`;
                url = baseURL;
                this.setState({
                    ...this.state,
                    title: `${res.Data.name} Station : ${this.props.companyName} - Rapid EV Charging UK`,
                    description: res.Data.address,
                    image: res.Data.image,
                });

                if (res.Data && !res.Data.image) {
                    await this.getStationDefaultImage();
                }
            })
            .catch((e) => {
                if (e.response && e.response.data && e.response.data.message) {
                    toast.error(e.response.data.message);
                } else {
                    toast.error(e.toString());
                }
            });
        const userAgent = navigator.userAgent.toLowerCase();
        if (
            userAgent.includes('iphone') ||
            userAgent.includes('ipad') ||
            userAgent.includes('ipod') ||
            userAgent.includes('mac os')
        ) {
            url = 'instavolt-dev://station-detail?location=' + myParam;
            baseURL = 'https://apps.apple.com/gb/app/instavolt/id1516767939';
        } else if (userAgent.includes('android')) {
            url = 'instavolt-dev://station-detail?location=' + myParam;
            baseURL = 'https://play.google.com/store/apps/details?id=com.app.instavolt';
        }
        this.share(url, baseURL);
    }

    getStationDefaultImage = async () => {
        await this.stationDetailsService
            .getConfigurationDetail({
                code: Common.ConfigurationKey.DEFAULT_LOCATION_IMAGE,
            })
            .then((res: IGetConfigurationData) => {
                this.setState({ ...this.state, image: res.data[0]?.value });
            })
            .catch((e) => {
                if (e.response && e.response.data && e.response.data.message) {
                    toast.error(e.response.data.message);
                } else {
                    toast.error(e.toString());
                }
            });
    };

    share = (url, baseURL) => {
        window.location = url;
        setTimeout(function () {
            if (!document.webkitHidden) {
                window.location = baseURL;
            }
        }, 10000);
    };

    render() {
        const { description, image } = this.state;

        return (
            <>
                <Helmet>
                    <meta charset="utf-8" />
                    <title>{companyNameReplace(this.props.t('route.stationTitle'))}</title>
                    <meta name="description" content={description} />
                    <meta property="og:title" content={this.state.title} />
                    <meta property="og:url" content={window.location.origin} />
                    <meta property="og:description" content={description} />
                    <meta property="og:image" content={image} />
                    <meta property="og:type" content="website" />
                    <meta property="og:site_name" content="instavolt" />
                </Helmet>
                <div className="card-body text-center loaderBody">
                    <div className="row min-vh-50 align-items-center">
                        <div className="col">
                            <div className="mesg-box process-block text-center">
                                <img src={images.processGIF} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default StationDetailsHOC;
