export default {
    route: {
        dashboard: 'Painel de controlo',
        article: 'Artigos',
        stationTitle:
            'Dados da estação: ##COMPANY_BRAND_NAME## – Carregamento rápido de veículos elétricos no Reino Unido',
        or: 'OU',
    },
    month: {
        jan: 'Jan',
        feb: 'Fev',
        mar: 'Mar',
        apr: 'Abr',
        may: 'Mai',
        jun: 'Jun',
        jul: 'Jul',
        aug: 'Ago',
        sep: 'Set',
        oct: 'Out',
        nov: 'Nov',
        dec: 'Dez',
    },
    error: {
        accessDenied: 'Acesso negado',
        somethingWrong: 'Algo correu mal',
        notFound: 'Página não encontrada',
    },
    navbar: {
        title: '##COMPANY_BRAND_NAME##',
        logOut: 'Terminar sessão',
        myProfile: 'O meu perfil',
        termsConditions: 'Termos e Condições',
        menu: {
            dashboard: 'Painel de controlo',
            paymentConfiguration: 'Configuração do pagamento',
            chargingPaymentHistory: 'Histórico de carregamentos e pagamentos',
            rewards: 'Recompensas',
            rfidCards: 'Cartões RFID',
        },
    },
    login: {
        title: 'Iniciar sessão – ##COMPANY_BRAND_NAME##: Carregamento rápido de veículos elétricos no Reino Unido',
        header: 'Bem-vindo/a a',
        form: {
            email: 'End. de e-mail',
            password: 'Palavra-passe',
        },
        placeholder: {
            region: 'Selecionar região',
            language: 'Selecionar idioma',
        },
        button: {
            submit: 'Iniciar sessão',
            forgotPassword: 'Esqueceu-se da sua palavra-passe?',
            signup: 'Criar conta',
            signin: 'Iniciar sessão',
        },
        label: {
            logo: '##COMPANY_BRAND_NAME##',
            thirdPartyLogin: 'Ou iniciar sessão com',
            rememberMe: 'Guardar os meus dados',
            newAccount: 'Não tem uma conta?',
            facebook: 'Iniciar sessão com o Facebook',
            google: 'Iniciar sessão com a Google',
            apple: 'Iniciar sessão com a Apple',
            copyright: 'Copyright ##COMPANY_BRAND_NAME## Ltd',
        },
        toast: {
            success: 'Condutor iniciou sessão com sucesso.',
            inValid: 'Introduza o e-mail e a palavra-passe correta.',
            error: 'Ocorreu um erro ao processar o seu pedido. Tente novamente mais tarde.',
            cancelled: 'O cancelamento da sua conta está em curso, pelo que não poderá efetuar qualquer ação.',
            maximumAttempt: 'Atingiu o número máximo de tentativas. Volte a tentar mais tarde.',
            reminderMessage:
                'Adicione um cartão de pagamento à sua conta de crédito ou adicione dinheiro à sua conta pré-paga para ativar o pagamento associado a este código de promoção.',
        },
        errors: {
            inValidEmail: 'O endereço de e-mail é inválido.',
        },
    },
    forgot: {
        title: 'Esqueci-me da palavra-passe – ##COMPANY_BRAND_NAME##: Carregamento rápido de veículos elétricos no Reino Unido',
        resetTitle:
            'Repor a sua palavra-passe – ##COMPANY_BRAND_NAME###: Carregamento rápido de veículos elétricos no Reino Unido',
        header: 'Esqueceu-se da sua palavra-passe?',
        subHeader: 'Introduza o seu e-mail abaixo e enviar-lhe-emos uma mensagem para redefinir a sua palavra-passe',
        resetHeader: 'Redefina a sua palavra-passe.',
        resetSubHeader: 'Introduza o código que lhe enviámos por e-mail e redefina a sua palavra-passe.',
        supportNumber: 'O nosso número de apoio',
        button: {
            back: 'Voltar',
            submit: 'Redefinir a minha palavra-passe',
            save: 'Guardar nova palavra-passe',
        },
        form: {
            email: 'E-mail',
            code: 'Código',
            password: 'Palavra-passe',
            confirmPassword: 'Confirmar novamente a palavra-passe',
            message:
                'Se tiver uma conta válida, deverá receber um código por e-mail. Se tiver criado uma conta ##COMPANY_BRAND_NAME## com uma conta vinculada, por exemplo, na Apple, na Google ou no Facebook, não poderá redefinir a sua palavra-passe, uma vez que é gerida pelo serviço de terceiros.  Se precisar de mais assistência, contacte-nos através de',
        },
        toast: {
            success: 'A sua palavra-passe foi alterada com sucesso.',
            error: 'Ocorreu um erro ao processar o seu pedido. Tente novamente mais tarde.',
            inValidEmailAddress: 'O endereço de e-mail é inválido.',
            invalidCode: 'Ups! Tem um código inválido.',
        },
        errors: {
            passwordFormat:
                'A palavra-passe deve ter mais de 10 caracteres, com pelo menos uma letra maiúscula, uma letra minúscula, um carácter especial e um número.',
            confirmPasswordMatch:
                'a palavra-passe de confirmação tem de corresponder a uma nova palavra-passe que tenha introduzido.',
            verificationCodeFormat: 'Introduza o código de verificação de 6 dígitos.',
        },
    },
    dashboard: {
        title: 'Painel de controlo – ##COMPANY_BRAND_NAME###: Carregamento rápido de veículos elétricos no Reino Unido',
        header: 'Painel de controlo',
        premiumSubscription:
            'A ##COMPANY_BRAND_NAME## fornece um serviço premium. Selecione a subscrição para usufruir gratuitamente das funcionalidades do serviço premium durante 3 meses.',
    },
    permission: {
        createArticle: 'Adicionar artigo',
        editArticle: 'Editar artigo',
        save: 'Guardar',
        confirm: 'Tem a certeza?',
        cancel: 'Cancelar',
        update: 'Atualizar',
    },
    profile: {
        title: 'Perfil – ##COMPANY_BRAND_NAME##: Carregamento rápido de veículos elétricos no Reino Unido',
        header: 'Perfil',
        addresses: 'Endereços',
        personalDetails: {
            header: 'Dados pessoais',
            label: {
                firstName: 'Nome próprio',
                lastName: 'Apelido',
                email: 'End. de e-mail',
                address: 'Endereço',
                billingAddress: 'Endereço de faturação',
                postcode: 'Código postal',
                samePersonalAddress: 'Igual ao endereço pessoal',
            },
            placeholder: {
                firstName: 'Nome próprio',
                lastName: 'Apelido',
                email: 'End. de e-mail',
                address: 'Introduza o seu endereço',
                billingAddress: 'Endereço de faturação',
                postcode: 'Código postal',
                searchAddress: 'Comece a escrever o seu endereço para pesquisar',
            },
            button: {
                save: 'Guardar',
                cancel: 'Cancelar',
                yes: 'Sim',
                no: 'Não',
            },
            errors: {
                email: {
                    email: 'O endereço de e-mail é inválido.',
                },
                first_name: {
                    matches: 'Nome próprio inválido.',
                },
                last_name: {
                    matches: 'Apelido inválido.',
                },
                address: {
                    address: 'É necessário um mínimo de ##MIN_LENGTH## caracteres',
                    invalid: 'O endereço é inválido',
                    minLine: 'O endereço inserido não atende às linhas de endereço mínimas para ser válido.',
                },
                billing_Address: {
                    billing_Address: 'É necessário um mínimo de ##MIN_LENGTH## caracteres',
                    invalid: 'O endereço de faturação é inválido',
                    minLine: 'O endereço inserido não atende às linhas de endereço mínimas para ser válido.',
                },
            },
            model: {
                header: 'Encontrar endereço',
                address: 'Escrever endereço',
                suggested_address: 'Endereços sugeridos',
                no_data_found: 'Não foram encontrados endereços',
                no_address_found: 'Não foi encontrado qualquer endereço.',
                button: {
                    ok: 'OK',
                    cancel: 'Cancelar',
                    close: 'Fechar',
                },
                toast: {
                    invalidAddress: 'Foi introduzido um código postal ou endereço inválido.',
                },
            },
        },
        addressDetails: {
            header: 'Endereço',
            label: {
                address1: 'Endereço',
                address2: 'Linha de endereço 2',
                city: 'Cidade',
                county: 'Concelho',
                paymentPostcode: 'Código Postal',
            },
            placeholder: {
                address1: 'Endereço',
                address2: 'Linha de endereço 2',
                city: 'Cidade',
                county: 'Concelho',
            },
        },
        billingAddressDetails: {
            header: 'Endereço de faturação',
            label: {
                billingaddress1: 'Endereço',
                billingaddress2: 'Linha de endereço 2',
                billingcity: 'Cidade',
                billingcounty: 'Concelho',
                samePersonalAddress: 'Igual ao endereço pessoal',
            },
            placeholder: {
                billingaddress1: 'Endereço',
                billingaddress2: 'Linha de endereço 2',
                billingcity: 'Cidade',
                billingcounty: 'Concelho',
            },
        },
        marketingPreferenceDetails: {
            header: 'Preferências de marketing',
            label: {
                receivePreference: 'Receber comunicações de marketing da ##COMPANY_BRAND_NAME##',
                yes: 'Sim',
                no: 'Não',
            },
            message: 'Gostaria de receber comunicações de marketing da ##COMPANY_BRAND_NAME##?',
        },
        langPreference: 'Idioma de preferência',
        changePassword: {
            header: 'Palavra-passe',
            toast: {
                success: 'A sua palavra-passe foi alterada com sucesso.',
                error: 'Ups! A sua palavra-passe atual está incorreta.',
                commonError: 'Ocorreu um erro ao processar o seu pedido. Tente novamente mais tarde.',
            },
            button: {
                update: 'Atualizar',
            },
            label: {
                current_password: 'Palavra-passe atual',
                new_password: 'Nova palavra-passe',
                confirm_password: 'Confirmar novamente a palavra-passe',
            },
            placeholder: {
                current_password: 'Palavra-passe atual',
                new_password: 'Nova palavra-passe',
                confirm_password: 'Confirmar novamente a palavra-passe',
            },
            errors: {
                passwordFormat:
                    'A palavra-passe deve ter mais de 10 caracteres, com pelo menos uma letra maiúscula, uma letra minúscula, um carácter especial e um número.',
                confirmPasswordMatch:
                    'A palavra-passe de confirmação tem de corresponder a uma nova palavra-passe introduzida.',
            },
        },
        cancelAccount: {
            header: 'Eliminar conta',
            note1: 'Pode eliminar a sua conta em qualquer altura. A ##COMPANY_BRAND_NAME## removerá todos os seus dados do nosso sistema.',
            note2: 'Eliminar a sua conta.',
            clickHere: 'Clique aqui',
            toast: {
                success:
                    'Lamentamos a sua partida. Iniciámos o processo de eliminação da sua conta. Assim que terminarmos enviaremos uma mensagem para o seu e-mail.',
                failed: 'Ocorreu um erro ao processar o seu pedido. Tente novamente mais tarde.',
            },
            model: {
                title: 'Eliminar conta',
                body: 'Ao apagar a sua conta, todos os seus dados serão permanentemente removidos do nosso sistema. Ainda deseja continuar?',
                button: {
                    yes: 'Sim',
                    no: 'Não',
                },
            },
        },
        limitExceedAddressError: 'Não é possível encontrar o endereço neste momento',
    },
    signup: {
        header: 'Crie a sua conta com',
        title: 'Criação de conta – ##COMPANY_BRAND_NAME##: Carregamento rápido de veículos elétricos no Reino Unido',
        form: {
            firstName: 'Nome próprio',
            lastName: 'Apelido',
            email: 'E-mail',
            password: 'Palavra-passe',
            referralCode: 'Código de referência',
            code: 'Código',
            terms: 'Aceito os',
            condition: 'Termos e Condições.',
        },
        button: {
            submit: 'Criar conta',
            signin: 'Iniciar sessão',
            login: 'Iniciar sessão',
            back: 'Voltar',
            submitOtp: 'Enviar',
            resend: 'Reenviar código?',
        },
        label: {
            continueWith: 'Ou continuar com',
            accountNew: 'Já tem uma conta?',
            otpConfirmationTitle1: 'Acabámos de lhe enviar um e-mail',
            otpConfirmationTitle2: 'Para iniciar sessão, introduza o código enviado para',
            otpNonVerifiedEmailNote1: 'A sua conta ainda não foi verificada. Introduza o código que enviámos para',
            otpNonVerifiedEmailNote2: 'para continuar.',
            resendTitle: 'Não recebeu uma mensagem no e-mail?',
            resend: 'Reenviar',
            email: 'E-mail',
            instavolt: '##COMPANY_BRAND_NAME##',
            modalMessage:
                'O endereço ##EMAIL## já tem uma conta ##COMPANY_BRAND_NAME## registada através de ##FEDERATEDACCOUNT##. Deseja continuar?',
            instavoltUserAccount: 'Conta de utilizador da ##COMPANY_BRAND_NAME##',
            alreadyRegisteredWithNormalAcc:
                'O endereço ##EMAIL## já tem uma conta ##COMPANY_BRAND_NAME## registada através de uma conta de utilizador da ##COMPANY_BRAND_NAME##. Deseja continuar?',
        },
        toast: {
            success: 'Conta de condutor criada com sucesso.',
            alreadyExist: 'O endereço de e-mail que introduziu já existe.',
            error: 'Ocorreu um erro ao processar o seu pedido. Tente novamente mais tarde.',
            confirmationSuccess: 'Verificação da conta efetuada com sucesso.',
            invalidCode: 'Ups! Introduziu um código inválido.',
            resendSentOTP: 'Código de utilização única enviado com sucesso.',
            resendOTPErr: 'O código de utilização única não foi enviado com sucesso. Volte a tentar mais tarde.',
            referralCodeInvalid: 'O código de referência é inválido.',
            resendSignupCode: 'Olá, acabámos de lhe enviar outro código, verifique o seu e-mail.',
            resendLimitExceeded: 'Atingiu o máximo de tentativas. Tente novamente depois de algum tempo.',
        },
        errors: {
            recaptchaCode: 'Verificar o código recaptcha.',
            passwordFormat:
                'A palavra-passe deve ter mais de 10 caracteres, com pelo menos uma letra maiúscula, uma letra minúscula, um carácter especial e um número.',
            securityCode: 'O código de segurança deve ter pelo menos 6 dígitos',
            firstName: 'Nome próprio inválido.',
            lastName: 'Apelido inválido.',
            termsAndCondition: 'É necessário ler e concordar com os Termos e Condições para prosseguir',
        },
    },
    Payment: {
        Title: 'Configuração do pagamento – ##COMPANY_BRAND_NAME##: Carregamento rápido de veículos elétricos no Reino Unido',
        ChargeUnit: '(kWh)',
        description: 'Pagamento a ##COMPANY_BRAND_NAME##',
        PaymentType: {
            Title: 'Configuração do pagamento',
            SubTitle: 'Selecionar a opção de pagamento',
            Message: {
                selectPaymentOption: 'Selecionar a opção de pagamento.',
            },
            Inputs: {
                creditAccount: {
                    label: 'Conta de crédito',
                    label1: 'Pague enquanto carrega',
                },
                paymentAccount: {
                    label: 'Conta pré-paga',
                    label1: 'Pagar adiantado e utilizar o crédito da conta para efetuar o débito',
                },
            },
            Buttons: {
                Next: {
                    text: 'Seguinte',
                },
            },
        },
        CreditPayment: {
            Title: 'Configuração do pagamento',
            SubTitle: 'Conta de crédito',
            note: 'Tenha em atenção que, ao adicionar um cartão de pagamento, a sua conta será debitada em ##CURRENCY## que será creditado/a no saldo da sua carteira ##COMPANY_BRAND_NAME##.',
            donateMessage: {
                first: 'Sim, enviar donativo',
                second: 'Arredondar o pagamento para',
            },
            donationNote:
                'Doação "Arredondar" para os 50 cêntimos mais próximos ou "Recarregar" com um montante fixo para cada transação de pagamento.',
            Inputs: {
                CardTitle: {
                    label: 'Nome no cartão',
                    placeHolder: 'Nome no cartão',
                    required: 'O título do cartão é obrigatório',
                },
                CardNumber: {
                    label: 'Número do cartão',
                    placeHolder: '1234  1234  1234  1234',
                    invalid: 'Número de cartão inválido',
                    required: 'É necessário o número do cartão',
                    maxlimit: 'O número do cartão deve ter, no máximo, 20 dígitos',
                },
                Expiry: {
                    label: 'Prazo de validade',
                    required: 'É necessário o mês de fim da validade',
                },
                Year: {
                    label: 'Ano',
                    required: 'É necessário o ano de fim da validade',
                },
                CSC: {
                    label: 'CSC',
                    label1: '(Código de segurança do cartão)',
                    required: 'É necessário o código de segurança do cartão',
                    placeHolder: 'CSC',
                    maxlimit: 'Introduza um código com, pelo menos, 3 dígitos.',
                },
                Donation: {
                    label: 'Sim, quero fazer um donativo!',
                },
                giftaid: {
                    label: 'Ativar o donativo Gift Aid?',
                },
                RoundUp: {
                    label: 'Arredondar',
                },
                TopUp: {
                    label: 'Recarregar',
                },
            },
            Buttons: {
                Save: {
                    text: 'Guardar',
                },
                Back: {
                    text: 'Mudar para conta pré-paga',
                },
                editBack: {
                    text: 'Voltar',
                },
            },
            Card: {
                expire: 'Expira a',
                add: 'Adicionar cartão',
                charity: 'Donativo à beneficência',
                edit: 'Editar definições de donativos',
                editCard: 'Editar cartão',
                delete: 'Eliminar',
                replace: 'Substituir',
                balance: 'Saldo atual:',
                Outbalance: 'Saldo em dívida:',
                mmYY: 'MM/AA',
                mm: 'MM',
                yyyy: 'AAAA',
                Pay: {
                    text: 'Pagar agora',
                },
                Withdraw: {
                    text: 'Levantar fundos',
                },
                toast: {
                    delete: {
                        success: 'Cartão eliminado com sucesso.',
                        error: 'Ocorreu um erro no processamento do seu pedido. Volte a tentar mais tarde.',
                    },
                    save: {
                        success: 'Os dados do cartão foram guardados com sucesso.',
                        error: 'Ocorreu um erro ao processar o seu pedido. Volte a tentar mais tarde.',
                    },
                    paynow: {
                        success: 'Pagamento efetuado com sucesso.',
                        error: 'Ocorreu um erro ao processar o seu pedido. Volte a tentar mais tarde.',
                    },
                    edit: {
                        success: 'Os dados do cartão foram atualizados com sucesso.',
                        error: 'Ocorreu um erro ao processar o seu pedido. Volte a tentar mais tarde.',
                    },
                    replace: {
                        success: 'Os dados do cartão foram substituídos com sucesso.',
                        error: 'Ocorreu um erro ao processar o seu pedido. Volte a tentar mais tarde.',
                    },
                    common: {
                        error: 'Ocorreu um erro ao processar o seu pedido. Volte a tentar mais tarde.',
                    },
                    amount: 'Não há saldo em dívida a cobrar.',
                    noSwitch: 'Liquide o seu saldo em dívida antes de mudar para uma conta pré-paga',
                },
            },
            label: {
                accountFunds: 'Fundos da conta',
                rewards: 'Recompensas',
                referralBonus: 'Bónus de referência',
                currentBalance: 'Saldo atual',
                seeMore: 'Ver mais',
                message: 'Mensagem',
                charityDonation: 'Donativo à beneficência',
                topupAmount: 'Sim, enviar donativo',
                note: 'Nota:',
                charity: 'donativo',
                card: 'cartão',
                donation: 'donativo',
                giftaid: 'giftaid',
                noThanks: 'Não, obrigado',
                cancel: 'Cancelar',
            },
            error: 'A sua transação de pagamento é inferior ao valor mínimo ##AMOUNT da transação',
        },
        PrePaymentDetail: {
            Title: 'Configuração do pagamento',
            SubTitle: 'Conta pré-paga',
            Inputs: {
                Amount: {
                    label: 'Montante',
                    placeHolder: 'Montante',
                    required: 'O montante é necessário',
                    invalid: 'O montante deve ser igual ou superior a',
                    maxLimit: '',
                    number: 'O montante deve ser um número',
                },
                CardNumber: {
                    label: 'Número do cartão',
                    placeHolder: '1234  1234  1234  1234',
                    required: 'É necessário o número do cartão',
                    invalid: 'Número de cartão inválido',
                },
                Expiry: {
                    label: 'Prazo de validade',
                    required: 'É necessário o mês de fim da validade',
                },
                Year: {
                    label: 'Ano',
                    required: 'É necessário o ano de fim da validade',
                },
                CSC: {
                    label: 'CSC',
                    label1: '(Código de segurança do cartão)',
                    required: 'É necessário o código de segurança do cartão',
                    placeHolder: 'CSC',
                    maxlimit: 'Introduza um código com, pelo menos, 3 dígitos.',
                },
                Donation: {
                    label: 'Sim, quero fazer um donativo!',
                },
                RoundUp: {
                    label: 'Arredondar',
                },
                TopUp: {
                    label: 'Recarregar',
                },
            },
            Buttons: {
                Save: {
                    text: 'Adicionar dinheiro',
                },
                Back: {
                    text: 'Mudar para conta de crédito',
                    text1: 'Voltar',
                },
                FailBack: {
                    text: 'Voltar à conta',
                },
            },
            PrePayemnt: {
                text: {
                    currentBalance: 'Saldo atual',
                    OutBalance: 'Saldo em dívida',
                },
                button: {
                    add: 'Adicionar dinheiro',
                    Withdraw: 'Levantar fundos',
                    withdrawAccountFunds: 'Levantar fundos da conta',
                    viewYourRefundRequests: 'Ver pedidos de levantamento',
                },
            },
            toast: {
                canNotAddMoney:
                    'Não pode adicionar mais fundos porque atingiu o limite máximo de fundos que pode manter na sua carteira ##COMPANY_BRAND_NAME##.',
                common: {
                    error: 'Ocorreu um erro no processamento do seu pedido. Volte a tentar mais tarde.',
                },
                maxLimitForPrePayment: 'Só pode carregar o saldo da sua carteira até ##MAXAMOUNT.',
                maxLimitReached:
                    'Só pode carregar o saldo da sua carteira até ##MAXAMOUNT. Pode adicionar o máximo de ##ALLOWEDAMOUNT agora.',
            },
            label: {
                accountFunds: 'Fundos da conta',
                rewards: 'Recompensas',
                referralBonus: 'Bónus de referência',
                currentBalance: 'Saldo atual',
                seeMore: 'Ver mais',
                billingAddress: 'Endereço de faturação',
                charityDonation: 'Donativo à beneficência',
                note: 'Nota:',
            },
            donationNote: 'Doação "Recarga" com um montante fixo para cada transação de pagamento.',
        },
        PrePaymentWithdraw: {
            Title: 'Configuração do pagamento',
            SubTitle: 'Conta pré-paga',
            Inputs: {
                Amount: {
                    label: 'Montante',
                    placeHolder: 'Montante',
                    required: 'O montante é necessário',
                    invalid: 'O montante é superior ao saldo atual',
                },
                CardNumber: {
                    label: 'Número do cartão',
                    placeHolder: '1234  1234  1234  1234',
                    required: 'É necessário o número do cartão',
                    invalid: 'Número de cartão inválido',
                    maxlimit: 'O número do cartão deve ter, no máximo, 20 dígitos',
                },
                Expiry: {
                    label: 'Prazo de validade',
                    required: 'É necessário o mês de fim da validade',
                },
                Year: {
                    label: 'Ano',
                    required: 'É necessário o ano de fim da validade',
                },
                CSC: {
                    label: 'CSC',
                    label1: '(Código de segurança do cartão)',
                    required: 'É necessário o código de segurança do cartão',
                    placeHolder: 'CSC',
                    maxlimit: 'Introduza um código com um mínimo de 3 dígitos.',
                },
            },
            Buttons: {
                Withdraw: {
                    text: 'Levantar fundos',
                },
                Back: {
                    text: 'Mudar para conta de crédito',
                    text1: 'Voltar',
                },
                FailBack: {
                    text: 'Voltar à conta',
                },
            },
            PrePayemnt: {
                text: {
                    currentBalance: 'Saldo atual',
                    OutBalance: 'Saldo em dívida',
                },
                button: {
                    add: 'Adicionar dinheiro',
                    Withdraw: 'Levantar fundos',
                },
            },
        },
        PaymentSuccess: {
            label: 'Sucesso!',
            Successlabel: 'Transação bem sucedida',
            transactionId: 'Id da transação:',
            amount: 'Montante:',
            warningLabel: 'Aviso',
            common: {
                error: 'Ocorreu um erro no processamento do seu pedido. Volte a tentar mais tarde.',
            },
        },
        PaymentFail: {
            label: 'Falha na transação',
            failureLabel: 'A sua transação falhou. Tente novamente.',
        },
        PaymentProcess: {
            label: 'Aguarde enquanto a sua transação está a ser processada',
        },
        Model: {
            header: 'Mudar de conta',
            DeleteBoxHeader: 'Eliminar cartão',
            WithdrawBoxHeader: 'Levantar fundos',
            button: {
                yes: 'Sim',
                no: 'Não',
                existingCard: 'Cartão existente',
                newCard: 'Novo cartão',
            },
            messagePrePayment: 'Tem a certeza de que pretende mudar para uma conta pré-paga?',
            messageCreditPayment: 'Tem a certeza de que pretende mudar para uma conta de crédito?',
            msgPrePaymentSaveCard:
                'Ao mudar para a conta pré-paga, os dados do seu cartão serão eliminados. Tem a certeza de que pretende mudar para uma conta pré-paga?',
            DeleteCardMsg: 'Tem a certeza de que pretende eliminar os dados do cartão?',
            WithdrawMoneyMsg: 'Selecione a opção de cartão para levantar dinheiro.',
        },
        charityModal: {
            yesDonate: 'Sim, enviar donativo',
        },
        Withdraw: {
            CanNotWithdrawMoneyWhileSessionIsActive:
                'Você não pode sacar dinheiro neste momento. Aguarde até que sua sessão de carregamento em execução seja concluída',
        },
    },
    termsAndCondition: {
        title: 'Termos e Condições',
        note: 'Página em construção.',
    },
    table: {
        data: {
            noData: 'Não foram encontrados dados.',
        },
        pagination: {
            prev: 'Anterior',
            nrev: 'Seguinte',
            showing: 'Em exibição',
            pageSize: {
                ten: '10',
                fifty: '50',
                hundred: '100',
            },
        },
    },
    history: {
        headerTitle:
            'Histórico de carregamentos e pagamentos – ##COMPANY_BRAND_NAME##: Carregamento rápido de veículos elétricos no Reino Unido',
        title: 'Histórico de carregamentos e pagamentos',
        columns: {
            chargingStation: 'Estação de carregamento',
            date: 'Data',
            address: 'Endereço',
            milesAdded: 'Milhas adicionadas',
            time: 'Hora',
            cost: 'Custo',
            energy: 'Energia',
            action: 'Ação',
            remark: 'Observações',
            credit: 'Crédito',
            debit: 'Débito',
            closing: 'Fecho',
            transaction: 'ID da transação',
            credit_debit: 'Crédito/Débito',
        },
        form: {
            charge: 'Carregamento',
            payment: 'Pagamento',
            month: 'Mês',
        },
        label: {
            exportAll: 'Gerar recibo',
        },
        button: {
            search: 'Pesquisar',
            reset: 'Redefinir',
        },
        message: {
            selectRecordToDownload: 'Selecione qualquer registo para gerar um recibo.',
            noChargingHistoryMessage:
                'Quando estiver a carregar o seu VE, o seu histórico de carregamento aparecerá aqui.',
            noPaymentHistoryMessage: 'Quando tiver concluído uma transação de pagamento, esta será apresentada aqui.',
        },
        receipt: {
            title: 'RECIBO',
            header: {
                date: 'Data',
                billing_addresss: 'Endereço de faturação',
                note: 'O seu recibo de carregamento de VE da ##COMPANY_BRAND_NAME##',
                vat_number: 'Número de identificação fiscal',
            },
            content: {
                summary: 'Segue-se um resumo das suas transações de carregamento',
                table: {
                    header: {
                        station_name: 'Nome da estação',
                        start: 'Início',
                        end: 'Fim',
                        usage: 'Utilização',
                        unit: 'Unidade',
                        value: 'Valor',
                        transaction_id: 'ID da transação',
                        vatRate: 'Percentagem de IVA',
                        vat: 'IVA',
                    },
                },
                total_before_vat: 'Total antes do IVA',
                total_vat: 'Total IVA',
                vat: 'IVA a',
                total_fee: 'Taxa total',
                thanks_message: 'Obrigado por carregar na rede de carregamento de VE da ##COMPANY_BRAND_NAME##',
                vatReceiptNotGenerated:
                    'O recibo com NIF ainda não foi gerado. Tente novamente 24 horas após o fim da sessão de carregamento. Se continuar a não conseguir, contacte o nosso serviço de apoio ao cliente.',
                vatReceiptNotAssociatedWithAcc:
                    'O recibo com NIF não foi associado à sua conta. Certifique-se de que está a utilizar as credenciais corretas para visualizar o recibo com NIF.',
            },
            footer: {
                title: 'A ##COMPANY_BRAND_NAME## Limited é uma empresa registada em Inglaterra e no País de Gales, com o número de identificação de pessoa coletiva 10484882',
                address: '6 Cedarwood, Crockford Lane, Chineham Park, Basingstoke, Hampshire, RG24 8WD',
                telephone: 'T',
                email: 'E',
                note: 'Indique a data da transação e o ID da transação em toda a correspondência.',
            },
        },
    },
    loyaltyPoint: {
        header: 'Recompensas – ##COMPANY_BRAND_NAME###: Carregamento rápido de veículos elétricos no Reino Unido',
        title: 'Recompensas',
        note: '*Só é possível resgatar volts através da nossa aplicação.',
        availableVolts: 'Volts disponíveis:',
        noDataMessage:
            'Quando tiver concluído uma sessão de carregamento, as suas transações de volts serão apresentadas aqui.',
        label: {
            chargingStation: 'Estação de carregamento',
            date: 'Data',
            address: 'Endereço',
            credit: 'Crédito',
            debit: 'Débito',
            closing: 'Fecho',
            redeemed: 'Resgatado',
            charged: 'Carregado',
            remark: 'Observações',
            credit_debit: 'Crédito/Débito',
            voltsRedeemed: 'Volts resgatados',
        },
    },
    withdraw: {
        subTitle: 'Conta pré-paga',
        label: {
            maxWithdrawalBalance: 'Saldo máximo de levantamento',
            note: 'Nota:',
            enterAmount: 'Introduzir montante',
            withdrawAmount: 'Montante de levantamento',
            expires: 'Expira a',
            rewardHeading: 'Introduza o valor que pretende levantar da sua conta',
            selectCardToWithdrawAmount: 'Selecionar o cartão para levantar o montante',
            remainingAmount: 'Montante restante',
            accountName: 'Nome da conta',
            sortCode: 'Código do banco',
            accountNumber: 'Número de conta',
            bankDetail: 'Dados bancários',
            withdrawAmountNote:
                'Só poderá transferir os fundos da sua conta para o cartão de pagamento que foi utilizado para financiar a conta.',
            withdrawAmountNote1: 'O levantamento será processado dentro de 14 dias.',
            withdrawAmountNote2: 'O levantamento será processado dentro de 3 a 5 dias úteis.',
            accountNameNote:
                ' Certifique-se de que o nome da conta bancária corresponde ao nome da sua conta ##COMPANY_BRAND_NAME##. Caso contrário, o levantamento será rejeitado.',
        },
        placeholder: {
            amount: 'Montante',
            accountName: 'Nome da conta',
            sortCode: 'Código do banco',
            accountNumber: 'Número de conta',
        },
        button: {
            back: 'Voltar',
            next: 'Seguinte',
            raisedRequest: 'Realizar pedido de levantamento',
            withdrawMoney: 'Levantar fundos',
            proceed: 'Prosseguir',
        },
        errors: {
            amount: {
                typeError: 'Introduzir um montante válido',
                moreThan: 'O montante deve ser superior a 0',
            },
            AmountIsGreaterThanwithdrawableAmount: 'O montante introduzido é superior ao montante que pode levantar',
            selectCardToWithdrawMoney: 'Selecione qualquer cartão para levantar dinheiro.',
            canNotSelectCard:
                'O valor do levantamento excede o fundo máximo de levantamento disponível para o cartão de pagamento selecionado. Escolha outro cartão.',
        },
    },
    termsAndConditionListing: {
        title: 'Termos e Condições – ##COMPANY_BRAND_NAME##: Carregamento rápido de veículos elétricos no Reino Unido',
        noteDetails: '*Deve aceitar o obrigatório para poder utilizar/criar uma conta.',
        label: {
            referralCode: 'Código de referência (se tiver)',
            promotioncode: 'Código promocional (se tiver)',
            termsAndConditions: 'Termos e Condições.',
            welcome: 'Bem-vindo/a',
            apply: 'Aplicar',
            note: 'Nota:',
            invalidCode: 'Ups! Código inválido!',
            usedCode: 'Código já utilizado',
            codeApplied: 'Código aplicado',
            iAggreeToTermsCondition: 'Aceito os',
            reviewUpdatedCondition: 'Reveja e aceite os termos e condições atualizados apresentados abaixo.',
            name: 'Nome',
            accepted: 'Aceite',
        },
        button: {
            next: 'Seguinte',
            cancel: 'Cancelar',
            accept: 'Aceitar',
        },
        errors: {
            acceptTermCondition: 'Aceite os Termos e Condições *obrigatórios para prosseguir.',
            invalidReferralCode:
                'O código de referência ##REFERRAL_CODE## introduzido não é válido. Corrija (o texto é sensível a maiúsculas e minúsculas) ou remova o código de referência para continuar.',
            invalidPromotionCode:
                'O código promocional ##PROMO_CODE## introduzido não é válido. Corrija-o (o valor do texto é sensível a maiúsculas e minúsculas) ou remova o código promocional para continuar.',
            referralNotAllowed: 'Os códigos de referência não podem ser utilizados para esta promoção',
        },
        message: {
            refferalCode: {
                first: 'O código de referência',
                second: 'introduzido não é válido. Corrija (o texto é sensível a maiúsculas e minúsculas) ou remova o código de referência para continuar.',
            },
            promotionCode: {
                first: 'O código de promoção',
                second: 'introduzido não é válido. Corrija-o (o valor do texto é sensível a maiúsculas e minúsculas) ou remova o código promocional para continuar.',
            },
        },
    },
    cookiePolicy: {
        text: 'Este website utiliza cookies para melhorar a experiência do utilizador. Ao utilizar o nosso website, o utilizador reconhece que leu e compreendeu a nossa',
        action: 'Política de cookies',
        iAccept: 'Aceito',
    },
    openSource: {
        title: 'Créditos',
    },
    refundRequest: {
        title: 'Pedidos de levantamento – ##COMPANY_BRAND_NAME###: Carregamento rápido de veículos elétricos no Reino Unido',
        header: 'Pedidos de levantamento',
        label: {
            name: 'Nome',
            requestedDate: 'Data pedida',
            refundRequested: 'Levantamento pedido',
            account: 'Conta (para levantamento de créditos)',
            remarks: 'Observações',
            transactionID: 'ID do pedido',
            processing: 'A processar',
            cancelled: 'Cancelado',
            declined: 'Recusado',
            complete: 'Concluído',
            date: 'Data',
            status: 'Estado',
            cancelRequest: 'Cancelar pedido',
            action: 'Ação',
        },
        button: {
            search: 'Pesquisar',
            reset: 'Redefinir',
            yes: 'Sim',
            no: 'Não',
        },
        placeholder: {
            selectStatus: 'Selecionar estado',
        },
        message: {
            confirmCancelRequest: 'Tem a certeza de que pretende cancelar o seu pedido de levantamento?',
        },
        errors: {
            sortCode: {
                min: 'Introduza um código do banco com pelo menos 6 dígitos.',
                valid: 'Introduza um código do banco válido.',
            },
            accountNumber: {
                min: 'Introduza um número de conta com pelo menos 8 dígitos.',
            },
        },
    },
    footer: {
        version: 'Versão',
        copyRightText: 'Todos os direitos reservados',
    },
    rfidCards: {
        headerTitle: 'Cartões RFID – ##COMPANY_BRAND_NAME##: Carregamento rápido de veículos elétricos no Reino Unido',
        title: 'Cartões RFID',
        label: {
            public_card_id: 'Número(s) do cartão',
            quantity: 'Introduzir uma quantidade',
            action: 'Ação',
            delete: 'Eliminar',
            cardNumber: 'Introduzir o número do cartão',
            rfid_card_unit_price: 'O preço unitário de um cartão RFID é',
            rfid_card_unit_price_label: 'Por cartão RFID',
        },
        placeholder: {
            quantity: 'Introduzir uma quantidade',
            public_card_id: 'Introduzir o número do cartão',
        },
        noDataMessage: 'Nenhum cartão RFID ativo neste momento',
        requestProcessDaysMessage: 'O seu pedido de cartão RFID será processado no prazo de ##DAYS## dias úteis.',
        modal: {
            header: 'Pedido de cartão RFID',
            body: 'Introduza o número de cartões RFID necessários. Os cartões serão enviados para o endereço que definiu na sua conta. Após a receção dos cartões, terá de os ativar na sua conta.',
        },
        addCardModal: {
            header: 'Adicionar cartão RFID',
        },
        button: {
            proceed: 'Prosseguir',
            cancel: 'Cancelar',
            addNewCard: 'Adicionar cartão RFID',
            requestCard: 'Pedir novo cartão RFID',
            delete: 'Eliminar',
            action: 'Ação',
        },
        errors: {
            quantity: {
                min: 'A quantidade deve ser superior a zero',
            },
            public_card_id: {
                valid: 'Não foi possível reconhecer o ID do cartão introduzido. Certifique-se de que não foram incluídos espaços/caracteres especiais no ID.',
            },
        },
        message: {
            here: 'aqui',
            deleteConfirmMessage:
                'Ao eliminar este cartão, será permanentemente desativado e não poderá ser novamente registado. Tem a certeza de que pretende continuar?',
        },
    },
    giftAidmessage: 'mensagem do gift aid',
    creditAccountDonationNote:
        'Ao selecionar Sim, será aplicado um donativo de ##AMOUNT## a todos os pagamentos futuros. Pode alterar a sua preferência de donativo em qualquer altura, clicando no botão "Editar definições de donativo".',
    PrepaymentAccountDonationNote:
        'Ao selecionar Sim, será aplicado um donativo de ##AMOUNT## a esta transação de pagamento. Pode alterar a sua preferência de donativo em qualquer altura, clicando no botão "Editar definições de donativo".',
    aboutUs: {
        listTitle: {
            openSourcelicenses: 'Licenças de código aberto',
        },
    },
    price_breakdown_details: {
        detailed_breakup: 'Descrição pormenorizada',
        total_cost: 'Custo total',
        conversion_rate: 'Taxa de conversão',
    },
};
