import React from 'react';
import { Modal } from 'react-bootstrap';
import images from '../../assets/images';

export const ConfirmationBox = ({ ...props }) => {
    const { showModel } = props;
    return (
        <Modal show={showModel}>
            <div className="modal-body form-primary pb-2">
                <button
                    type="button"
                    className="close"
                    onClick={props.closeModel}
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">
                        <img src={images.closeSVG} alt="" />
                    </span>
                </button>
                <h2 className="title pb-3" id="exampleModalLabel">
                    {props.header ? props.header : props.t('Payment.Model.header')}
                </h2>
                {props.bodyMessage ? props.bodyMessage : <p>{props.Message}</p>}

                <div className="btn-block clearfix ModelBtn">
                    <button
                        type="button"
                        className="btn btn-primary mr-2 mb-2"
                        title={props.confirmText ? props.confirmText : props.t('Payment.Model.button.yes')}
                        onClick={props.onConfirm}
                    >
                        <span>{props.confirmText ? props.confirmText : props.t('Payment.Model.button.yes')}</span>
                    </button>
                    <button
                        type="button"
                        className="btn btn-secondary mb-2"
                        title={props.cancelText ? props.cancelText : props.t('Payment.Model.button.no')}
                        onClick={props.onCancel}
                    >
                        <span>{props.cancelText ? props.cancelText : props.t('Payment.Model.button.no')}</span>
                    </button>
                </div>
            </div>
        </Modal>
    );
};
export default ConfirmationBox;
