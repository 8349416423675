import { Auth } from 'aws-amplify';
import {
    ISignUpOTPFormField,
    ILoginForm,
    IForgotPasswordFormField,
    IAuthResponse,
    IForgotPasswordChangeField,
} from '../../models/login';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { Common } from 'utils/constants';
import { trackPromise } from 'react-promise-tracker';
import { IGetConfigurationData } from 'models/profile';
import BaseService from 'services/base-service';

export default class LoginService {
    getCognitoUser = async (configs: any) => {
        return await trackPromise(Auth.currentAuthenticatedUser(configs));
    };

    login = async (data: ILoginForm): Promise<CognitoUser> => {
        try {
            const res = await trackPromise(Auth.signIn(data.email.toLowerCase(), data.password));
            return Promise.resolve(res);
        } catch (error) {
            return Promise.reject(error);
        }
    };

    logout = async () => {
        try {
            const data = await trackPromise(Auth.signOut());
            return Promise.resolve(data);
        } catch (e) {
            return Promise.reject(e);
        }
    };

    signupConfirm = async (data: ISignUpOTPFormField): Promise<string> => {
        try {
            const res = await trackPromise(Auth.confirmSignUp(data.username.toLowerCase(), data.code.toString()));
            return Promise.resolve(res);
        } catch (error) {
            return Promise.reject(error);
        }
    };

    resendOtp = async (username: string): Promise<string> => {
        try {
            const res = await trackPromise(Auth.resendSignUp(username.toLowerCase()));
            return Promise.resolve(res);
        } catch (error) {
            return Promise.reject(error);
        }
    };

    forgotPassword = async (data: IForgotPasswordFormField): Promise<IAuthResponse> => {
        try {
            await trackPromise(Auth.forgotPassword(data.email.toLowerCase()));
            return Promise.resolve({
                status: Common.Authentication.SUCCESS,
            });
        } catch (error) {
            return Promise.reject(error);
        }
    };

    forgotPasswordChange = async (data: IForgotPasswordChangeField): Promise<IAuthResponse> => {
        try {
            await trackPromise(Auth.forgotPasswordSubmit(data.email.toLowerCase(), data.code, data.password));
            return Promise.resolve({ status: Common.Authentication.SUCCESS });
        } catch (error) {
            return Promise.reject(error);
        }
    };

    changePassword = async (oldPassword: string, newPassword: string): Promise<IAuthResponse> => {
        try {
            const user = await this.getCognitoUser({});
            await trackPromise(Auth.changePassword(user, oldPassword, newPassword));
            return Promise.resolve({ status: Common.Authentication.SUCCESS });
        } catch (error) {
            return Promise.reject(error);
        }
    };

    getAPIKey = async (data: any): Promise<IGetConfigurationData> => {
        try {
            const res = await BaseService({
                url: '/driver/public/configuration/search',
                method: 'POST',
                data: { ...data, byPassAuth: true },
            });
            return Promise.resolve(res.data);
        } catch (e) {
            return Promise.reject(e);
        }
    };
}
