import _ from 'lodash';
import { store } from 'store';
// Parse the time to string
export const parseTime = (time?: object | string | number, cFormat?: string): string | null => {
    if (time === undefined || time === '') {
        return null;
    }
    const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}';
    let date: Date;
    if (typeof time === 'object') {
        date = time as Date;
    } else {
        if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
            time = parseInt(time);
        }
        if (typeof time === 'number' && time.toString().length === 10) {
            time = time * 1000;
        }
        date = new Date(time);
    }
    const formatObj: { [key: string]: number } = {
        y: date.getFullYear(),
        m: date.getMonth() + 1,
        d: date.getDate(),
        h: date.getHours(),
        i: date.getMinutes(),
        s: date.getSeconds(),
        a: date.getDay(),
    };
    const timeStr = format.replace(/{([ymdhisa])+}/g, (_result, key) => {
        const value = formatObj[key];
        // Note: getDay() returns 0 on Sunday
        if (key === 'a') {
            return ['日', '一', '二', '三', '四', '五', '六'][value];
        }
        return value.toString().padStart(2, '0');
    });
    return timeStr;
};

// Format and filter json data using filterKeys array
export const formatJson = (filterKeys: any, jsonData: any) =>
    jsonData.map((data: any) =>
        filterKeys.map((key: string) => {
            if (key === 'timestamp') {
                return parseTime(data[key]);
            } else {
                return data[key];
            }
        }),
    );

// Check if an element has a class
export const hasClass = (ele: HTMLElement, className: string) => {
    return !!ele.className.match(new RegExp('(\\s|^)' + className + '(\\s|$)'));
};

// Add class to element
export const addClass = (ele: HTMLElement, className: string) => {
    if (!hasClass(ele, className)) ele.className += ' ' + className;
};

// Remove class from element
export const removeClass = (ele: HTMLElement, className: string) => {
    if (hasClass(ele, className)) {
        const reg = new RegExp('(\\s|^)' + className + '(\\s|$)');
        ele.className = ele.className.replace(reg, ' ');
    }
};

// Toggle class for the selected element
export const toggleClass = (ele: HTMLElement, className: string) => {
    if (!ele || !className) {
        return;
    }
    let classString = ele.className;
    const nameIndex = classString.indexOf(className);
    if (nameIndex === -1) {
        classString += '' + className;
    } else {
        classString = classString.substr(0, nameIndex) + classString.substr(nameIndex + className.length);
    }
    ele.className = classString;
};

export const ConvertToTableFilter = (sortName: string | number | symbol, sortOrder) => {
    return `${sortName.toString()}${sortOrder === 'asc' ? '+' : '-'}`;
};

export const regionDateAndTime = () => {
    if (!_.isEmpty(store?.getState()?.configuration?.date_time_format)) {
        return store.getState().configuration.date_time_format;
    } else {
        return {
            GENERAL_DATE_FORMAT: 'DD/MM/YYYY',
            GENERAL_DATE_TIME_FORMAT: 'DD/MM/YYYY HH:mm',
            DATE_TIME_FORMAT_WITH_SECONDS: 'DD/MM/YYYY HH:mm:ss',
            GENERAL_EXPORT_DATE_FORMAT: 'DD/MM/YYYY',
            GENERAL_EXPORT_DATE_TIME_FORMAT: 'DD/MM/YYYY HH:mm:ss',
        };
    }
};

export const companyNameReplace = (fullString: string) => {
    const changeValue = store?.getState()?.configuration?.company_name || 'Instavolt';
    const originalString = fullString;
    const replacedString = originalString.replace(/##COMPANY_BRAND_NAME##/g, changeValue);
    return replacedString;
};
