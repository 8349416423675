import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enLocale from './en';
import esLocale from './es';
import isLocale from './is';
import caLocale from './ca';
import euLocale from './eu';
import ca_ES_VAL_Locale from './ca_ES_VAL';
import glLocale from './gl';
import gaLocale from './ga';
import ptLocale from './pt';
import { store } from '../store';

const state = store.getState();

const resources = {
    en: { translation: { ...enLocale } },
    es: { translation: { ...esLocale } },
    is: { translation: { ...isLocale } },
    ca: { translation: { ...caLocale } },
    ca_ES_VAL: { translation: { ...ca_ES_VAL_Locale } },
    eu: { translation: { ...euLocale } },
    gl: { translation: { ...glLocale } },
    ga: { translation: { ...gaLocale } },
    pt: { translation: { ...ptLocale } },
};

i18n.use(initReactI18next).init({
    fallbackLng: state.UserData?.language ? state.UserData?.language?.code : 'en',
    debug: false,
    resources,
    interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
        formatSeparator: '.',
    },
});

export default i18n;
