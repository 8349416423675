import React, { Component, FC } from 'react';
import { IDownloadVATReceiptProps, IApplicationProps } from '../../types/propTypes';
import { IDownloadVATReceiptState } from '../../types/stateTypes';
import HistoryAPIService from '../../services/history/history-service';
import { IVatReceipt } from '../../models/history';
import StandardResponse from '../../models/standard-response';
import moment from 'moment';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import VATReceipt from '../history/VatReceipt';
import NoDataReceipt from './NoDataReceipt';
import { WithTranslation, withTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { regionDateAndTime } from '../../utils/index';
import { getFormattedCurrencyWithSymbol, localFormat } from '../../components/common/LocalNumberFormat';
import _ from 'lodash';
import { store } from '../../store';
import LoginService from '../../services/login/login-service';

const DownloadVATReceiptHOC: FC<any> = (props) => {
    const params = useParams();

    return <DownloadVATReceipt {...props} params={params} />;
};

class DownloadVATReceipt extends Component<
    IDownloadVATReceiptProps & IApplicationProps & WithTranslation,
    IDownloadVATReceiptState
> {
    historyService: HistoryAPIService;
    loginService: LoginService;
    constructor(props) {
        super(props);
        this.state = {
            receipt: {},
            isAPICalled: false,
            isUnauthorisedUser: false,
            address: '',
            email: '',
            telephone: 0,
            companyStatement: '',
        };
        this.historyService = new HistoryAPIService();
        this.loginService = new LoginService();
    }

    async componentDidMount() {
        let encryptedSessionId = this.props.params?.session_id;
        let encryptedSessionPrimaryId = this.props.params?.session_primary_id;

        if (encryptedSessionId !== null && encryptedSessionId !== undefined) {
            await this.historyService
                .getDownloadReceiptData({
                    sessionId: encryptedSessionId,
                    sessionPrimaryId: encryptedSessionPrimaryId ? encryptedSessionPrimaryId : null,
                })
                .then(async (res: StandardResponse<IVatReceipt>) => {
                    const vatReceiptData: any = res.Data;
                    _.isArray(vatReceiptData?.charge_details) &&
                        vatReceiptData?.charge_details?.length > 0 &&
                        vatReceiptData?.charge_details.map((item) => {
                            item.usage = localFormat(item?.usage?.toString(), 'string');
                            item.value = getFormattedCurrencyWithSymbol(
                                item?.value?.toString(),
                                'string',
                                undefined,
                                item?.currency_details?.symbol,
                            );
                            item.vat_amount = getFormattedCurrencyWithSymbol(
                                item?.vat_amount?.toString(),
                                'string',
                                undefined,
                                item?.currency_details?.symbol,
                            );
                            item.vat_rate = localFormat(item?.vat_rate?.toString(), 'string');
                            item.start = moment(item.start)?.format(regionDateAndTime().GENERAL_DATE_TIME_FORMAT);
                            item.end = moment(item.end)?.format(regionDateAndTime().GENERAL_DATE_TIME_FORMAT);
                        });
                    _.isArray(vatReceiptData?.vat_details) &&
                        vatReceiptData?.vat_details?.length > 0 &&
                        vatReceiptData?.vat_details.map((item) => {
                            item.total_amount = getFormattedCurrencyWithSymbol(
                                item?.total_amount?.toString(),
                                'string',
                                undefined,
                                store.getState()?.configuration?.default_currency?.symbol,
                            );
                            item.total_vat_amount = getFormattedCurrencyWithSymbol(
                                item?.total_vat_amount?.toString(),
                                'string',
                                undefined,
                                store.getState()?.configuration?.default_currency?.symbol,
                            );
                        });
                    vatReceiptData.date = moment(new Date()).format(regionDateAndTime().GENERAL_DATE_FORMAT);
                    vatReceiptData.net_amount = getFormattedCurrencyWithSymbol(
                        vatReceiptData?.net_amount?.toString(),
                        'string',
                        undefined,
                        store.getState()?.configuration?.default_currency?.symbol,
                    );
                    vatReceiptData.total_fee = getFormattedCurrencyWithSymbol(
                        vatReceiptData?.total_fee?.toString(),
                        'string',
                        undefined,
                        store.getState()?.configuration?.default_currency?.symbol,
                    );
                    vatReceiptData.total_vat = getFormattedCurrencyWithSymbol(
                        vatReceiptData?.total_vat?.toString(),
                        'string',
                        undefined,
                        store.getState()?.configuration?.default_currency?.symbol,
                    );
                    this.setState(
                        {
                            ...this.state,
                            receipt: vatReceiptData,
                            isAPICalled: true,
                        },
                        () => {
                            setTimeout(() => {
                                document.getElementById('instaVoltReceiptDownload').click();
                            }, 2000);
                        },
                    );
                })
                .catch((m) => {
                    if (m && m.response && m.response.status && m.response.status === 401) {
                        this.setState({ isUnauthorisedUser: true });
                    }
                    this.setState({ isAPICalled: true });
                });
        } else {
            this.setState({ isAPICalled: true });
        }

        const config = await this.loginService.getAPIKey({});

        this.setState({
            address: config.data.filter((res: any) => res.reference_code === 'CONTACT_US_ADDRESS')[0].value,
            email: config.data.filter((res: any) => res.reference_code === 'CONTACT_US_EMAIL')[0].value,
            telephone: config.data.filter((res: any) => res.reference_code === 'CONTACT_US_PHONE')[0].value,
            companyStatement: config.data.filter((res: any) => res.reference_code === 'COMPANY_STATEMENT')[0].value,
        });
    }

    render() {
        const { t } = this.props;
        return (
            <React.Fragment>
                {this.state.isAPICalled && Object.keys(this.state.receipt).length > 0 && (
                    <>
                        <div className="displayNone">
                            <PDFDownloadLink
                                document={
                                    <VATReceipt
                                        data={this.state.receipt}
                                        t={t}
                                        address={this.state.address}
                                        email={this.state.email}
                                        telephone={this.state.telephone}
                                        companyStatement={this.state.companyStatement}
                                    />
                                }
                                fileName={`${store.getState().configuration.company_name}_Rapid_EV_Charging_${moment(
                                    new Date(),
                                ).format('DD_MM_YY')}.pdf`}
                                className="btn icon-btn pr-0 btn-outline-primary"
                            >
                                {() => <div id="instaVoltReceiptDownload"></div>}
                            </PDFDownloadLink>
                        </div>
                        <PDFViewer className="receiptItem">
                            <VATReceipt
                                data={this.state.receipt}
                                t={t}
                                isDisplay={true}
                                address={this.state.address}
                                email={this.state.email}
                                telephone={this.state.telephone}
                                companyStatement={this.state.companyStatement}
                            />
                        </PDFViewer>
                    </>
                )}
                {this.state.isAPICalled && Object.keys(this.state.receipt).length <= 0 && (
                    <PDFViewer className="receiptItem">
                        <NoDataReceipt t={t} isUnauthorisedUser={this.state.isUnauthorisedUser} />
                    </PDFViewer>
                )}
            </React.Fragment>
        );
    }
}

export default withTranslation()(DownloadVATReceiptHOC);
