export default {
    route: {
        dashboard: 'Quadre de comandaments',
        article: 'Articles',
        stationTitle: "Detalls de l'estació: ##COMPANY_BRAND_NAME## -Recàrrega ràpida de VE al Regne Unit",
        or: 'O',
    },
    month: {
        jan: 'Gen',
        feb: 'Feb',
        mar: 'Mar',
        apr: 'Abr',
        may: 'Mai',
        jun: 'Jun',
        jul: 'Jul',
        aug: 'Ago',
        sep: 'Sep',
        oct: 'Oct',
        nov: 'Nov',
        dec: 'Des',
    },
    error: {
        accessDenied: 'Accés denegat',
        somethingWrong: 'Alguna cosa ha anat malament',
        notFound: 'Pagina no trobada',
    },
    navbar: {
        title: '##COMPANY_BRAND_NAME##',
        logOut: 'Tancar sessió',
        myProfile: 'El meu perfil',
        termsConditions: 'Condicions generals',
        menu: {
            dashboard: 'Quadre de comandaments',
            paymentConfiguration: 'Configuració de Pagament',
            chargingPaymentHistory: 'Historial de pagaments & recàrrega',
            rewards: 'Recompensa',
            rfidCards: 'Targetes RFID',
        },
    },
    login: {
        title: 'Login: ##COMPANY_BRAND_NAME## Recàrrega ràpida de vehicles elèctrics al Regne Unit',
        header: 'Benvingut a',
        form: {
            email: 'Identificació de correu electrònic',
            password: 'Contrasenya',
        },
        placeholder: {
            region: 'Seleccionar regió',
            language: 'Seleccionar idioma',
        },
        button: {
            submit: 'Inici de sessió',
            forgotPassword: 'Ha oblidat la seua contrasenya?',
            signup: 'Registrar-se',
            signin: 'Iniciar sessió',
        },
        label: {
            logo: '##COMPANY_BRAND_NAME##',
            thirdPartyLogin: 'O inicie de sessió amb',
            rememberMe: 'Recordar-la',
            newAccount: 'No tens un compte?',
            facebook: 'Iniciar sessió amb Facebook',
            google: 'Iniciar sessió amb Google',
            apple: 'Iniciar sessió amb Apple',
            copyright: 'Copyright ##COMPANY_BRAND_NAME##Ltd',
        },
        toast: {
            success: 'Perfil del conductor iniciat correctament',
            inValid: 'Per favor, introduïsca el correu electrònic o la contrasenya correctes.',
            error: "S'ha produït un error en processar la seua sol·licitud, per favor intente-ho de nou més tard",
            cancelled:
                'La cancel·lació del seu compte està en procés, per la qual cosa no podrà realitzar cap acció(ns)',
            maximumAttempt: "Ha aconseguit el nombre màxim d'intents. Per favor intente-ho de nou més tard",
            reminderMessage:
                'Per favor, afija una targeta de pagament en el seu compte de crèdit o afija diners al seu compte de prepagament per a activar el pagament associat a este codi promocional.',
        },
        errors: {
            inValidEmail: "L'adreça de correu electrònic no és vàlida.",
        },
    },
    forgot: {
        title: 'Contrasenya oblidada - ##COMPANY_BRAND_NAME##: Recàrrega ràpida de vehicles elèctrics al Regne Unit',
        resetTitle:
            'Restablisca la seua contrasenya - ##COMPANY_BRAND_NAME##: Recàrrega ràpida de vehicles elèctrics al Regne Unit',
        header: 'Ha oblidat la seua contrasenya?',
        subHeader:
            'Introduïsca la seua adreça de correu electrònic i li enviarem un missatge per a restablir la seua contrasenya',
        resetHeader: 'Restablisca la seua contrasenya.',
        resetSubHeader:
            'Introduïsca el codi que li hem enviat per correu electrònic i restablisca la seua contrasenya.',
        supportNumber: "El nostre número d'assistència",
        button: {
            back: 'Tornar',
            submit: 'Restablir la meua contrasenya',
            save: 'Guardar nova contrasenya',
        },
        form: {
            email: 'Correu electrònic',
            code: 'Codi',
            password: 'Contrasenya',
            confirmPassword: 'Torne a confirmar la seua contrasenya',
            message:
                "Si té un compte vàlid, rebrà un correu electrònic amb un codi. Tinga en compte que si es va enregistrar en un compte de ##COMPANY_BRAND_NAME## amb un compte federat, per exemple, Apple, Google o Facebook, no podrà restablir la seua contrasenya, ja que la gestiona el servici de tercers. Si necessita més ajuda, pose's en contacte amb nosaltres",
        },
        toast: {
            success: "La seua contrasenya s'ha modificat correctament.",
            error: "S'ha produït un error en processar la seua sol·licitud, per favor intente-ho de nou més tard",
            inValidEmailAddress: "L'adreça de correu electrònic no és vàlida.",
            invalidCode: 'Ui! Té un codi que no és vàlid.',
        },
        errors: {
            passwordFormat:
                'La contrasenya ha de tindre més de 10 caràcters, amb almenys una lletra majúscula, una lletra minúscula, un caràcter especial i un número.',
            confirmPasswordMatch:
                'la seua contrasenya de confirmació ha de coincidir amb la nova contrasenya que ha introduït.',
            verificationCodeFormat: 'Per favor, introduïsca el codi de verificació de 6 dígits.',
        },
    },
    dashboard: {
        title: 'Quadre de comandaments - ##COMPANY_BRAND_NAME## Recàrrega ràpida de vehicles elèctrics al Regne Unit',
        header: 'Quadre de comandaments',
        premiumSubscription:
            "##COMPANY_BRAND_NAME## oferix un servici premium. Seleccione subscriure's per a gaudir del servici Premium gratis durant 3 mesos.",
    },
    permission: {
        createArticle: 'Afegir articles',
        editArticle: 'Editar articles',
        save: 'Guardar',
        confirm: 'Està segur?',
        cancel: 'Cancel·lar',
        update: 'Actualització',
    },
    profile: {
        title: 'Perfil- ##COMPANY_BRAND_NAME## Recàrrega ràpida de vehicles elèctrics al Regne Unit',
        header: 'Perfil',
        addresses: 'Adreces',
        personalDetails: {
            header: 'Dades personals',
            label: {
                firstName: 'Nom',
                lastName: 'Cognom(s)',
                email: 'Identificació de correu electrònic',
                address: 'Adreça',
                billingAddress: 'Adreça de facturació',
                postcode: 'Codi postal',
                samePersonalAddress: 'La mateixa que la adreça personal',
            },
            placeholder: {
                firstName: 'Nom',
                lastName: 'Cognom',
                email: 'Identificació de correu electrònic',
                address: 'Introduïsca la seua adreça',
                billingAddress: 'Adreça de facturació',
                postcode: 'Codi postal',
                searchAddress: 'Comença a escriure la seua adreça per a buscar',
            },
            button: {
                save: 'Guardar',
                cancel: 'Cancel·lar',
                yes: 'Sí',
                no: 'No',
            },
            errors: {
                email: {
                    email: "L'adreça de correu electrònic no és vàlida.",
                },
                first_name: {
                    matches: 'El nom no és vàlid.',
                },
                last_name: {
                    matches: 'El cognom no és vàlid.',
                },
                address: {
                    address: 'Mínim ##MIN_LENGTH## caràcter requerit',
                    invalid: "L'adreça no és vàlida",
                    minLine: "L'adreça que heu introduït no compleix les línies d'adreces mínimes per ser vàlida.",
                },
                billing_Address: {
                    billing_Address: 'Mínim ##MIN_LENGTH## caràcter requerit',
                    invalid: "L'adreça de facturació no és vàlida",
                    minLine: "L'adreça que heu introduït no compleix les línies d'adreces mínimes per ser vàlida.",
                },
            },
            model: {
                header: 'Buscar adreça',
                address: 'Tipus adreça',
                suggested_address: 'Adreces suggerides',
                no_data_found: "No s'han trobat adreces",
                no_address_found: "No s'han trobat adreces",
                button: {
                    ok: 'OK',
                    cancel: 'Cancel·lar',
                    close: 'Tancar',
                },
                toast: {
                    invalidAddress: "S'ha introduït un codi postal o una adreça que no son vàlids.",
                },
            },
        },
        addressDetails: {
            header: 'Adreça',
            label: {
                address1: 'Adreça',
                address2: 'Adreça línia 2',
                city: 'Ciutat',
                county: 'Província',
                paymentPostcode: 'Codi postal',
            },
            placeholder: {
                address1: 'Adreça',
                address2: 'Adreça línia 2',
                city: 'Ciutat',
                county: 'Província',
            },
        },
        billingAddressDetails: {
            header: 'Adreça de facturació',
            label: {
                billingaddress1: 'Adreça',
                billingaddress2: 'Adreça línia 2',
                billingcity: 'Ciutat',
                billingcounty: 'Província',
                samePersonalAddress: 'La mateixa que la adreça personal',
            },
            placeholder: {
                billingaddress1: 'Adreça',
                billingaddress2: 'Adreça línia 2',
                billingcity: 'Ciutat',
                billingcounty: 'Província',
            },
        },
        marketingPreferenceDetails: {
            header: 'Preferències comercials',
            label: {
                receivePreference: 'Rebre comunicacions comercials de ##COMPANY_BRAND_NAME##',
                yes: 'Sí',
                no: 'No',
            },
            message: 'Desitja rebre comunicacions comercials de ##COMPANY_BRAND_NAME##',
        },
        langPreference: 'Preferència lingüística',
        changePassword: {
            header: 'Contrasenya',
            toast: {
                success: "La seua contrasenya s'ha modificat correctament.",
                error: 'Ui! La seua contrasenya actual és incorrecta',
                commonError:
                    "S'ha produït un error en processar la seua sol·licitud, per favor intente-ho de nou més tard",
            },
            button: {
                update: 'Actualització',
            },
            label: {
                current_password: 'Contrasenya actual',
                new_password: 'Nova contrasenya',
                confirm_password: 'Torne a confirmar la seua contrasenya',
            },
            placeholder: {
                current_password: 'Contrasenya actual',
                new_password: 'Nova contrasenya',
                confirm_password: 'Torne a confirmar la seua contrasenya',
            },
            errors: {
                passwordFormat:
                    'La contrasenya ha de tindre més de 10 caràcters, amb almenys una lletra majúscula, una lletra minúscula, un caràcter especial i un número.',
                confirmPasswordMatch:
                    'La seua contrasenya de confirmació ha de coincidir amb la nova contrasenya que ha introduït.',
            },
        },
        cancelAccount: {
            header: 'Eliminar compte',
            note1: 'Pot eliminar el seu compte en qualsevol moment. ##COMPANY_BRAND_NAME## eliminarà totes les seues dades del nostre sistema.',
            note2: 'Per a eliminar el seu compte.',
            clickHere: 'Polse ací',
            toast: {
                success:
                    "Lamentem la seua marxa! Hem iniciat el procés d'eliminació del seu compte i li enviarem una notificació per correu electrònic una vegada haja finalitzat el procés.",
                failed: "S'ha produït un error en processar la seua sol·licitud, per favor intente-ho de nou més tard",
            },
            model: {
                title: 'Eliminar compte',
                body: "L'eliminació del seu compte eliminarà permanentment totes les seues dades del nostre sistema. Desitja continuar de totes formes?",
                button: {
                    yes: 'Sí',
                    no: 'No',
                },
            },
        },
        limitExceedAddressError: 'No es pot trobar la direcció en este moment',
    },
    signup: {
        header: 'Cree el seu compte en',
        title: 'Inscripció - ##COMPANY_BRAND_NAME##: Recàrrega ràpida de vehicles elèctrics al Regne Unit',
        form: {
            firstName: 'Nom',
            lastName: 'Cognom',
            email: 'Correu electrònic',
            password: 'Contrasenya',
            referralCode: 'Codi de referència',
            code: 'Codi',
            terms: 'Accepte',
            condition: 'Condicions generals',
        },
        button: {
            submit: 'Registrar-se',
            signin: 'Iniciar sessió',
            login: 'Inici de sessió',
            back: 'Tornar',
            submitOtp: 'Enviar',
            resend: 'Reenviar el codi?',
        },
        label: {
            continueWith: 'O continuar amb',
            accountNew: 'Ja tens un compte?',
            otpConfirmationTitle1: "Acabem d'enviar-li un correu electrònic",
            otpConfirmationTitle2: 'Per a iniciar sessió, introduïsca el codi enviat a',
            otpNonVerifiedEmailNote1:
                'El seu compte encara no està verificat. Per favor, introduïsca el codi que hem enviat a',
            otpNonVerifiedEmailNote2: 'per a seguir endavant.',
            resendTitle: 'No ha rebut un correu electrònic?',
            resend: 'Reenviar',
            email: 'Correu electrònic',
            instavolt: '##COMPANY_BRAND_NAME##',
            modalMessage:
                '##EMAIL## ja ha registrat un compte amb ##COMPANY_BRAND_NAME## a través de ##FEDERATEDACCOUNT##. Desitja continuar?',
            instavoltUserAccount: "##COMPANY_BRAND_NAME## compte d'usuari",
            alreadyRegisteredWithNormalAcc:
                "##EMAIL## ja ha registrat un compte amb ##COMPANY_BRAND_NAME## a través de ##FEDERATEDACCOUNT## compte d'usuari. Desitja continuar?",
        },
        toast: {
            success: 'Saldo del compte del conductor creat amb éxit',
            alreadyExist: "L'adreça de correu electrònic que ha introduït ja existix.",
            error: "S'ha produït un error en processar la seua sol·licitud, per favor intente-ho de nou més tard",
            confirmationSuccess: 'Verificació de compte realitzat amb èxit.',
            invalidCode: 'Ui! Ha introduït un codi que no és vàlid.',
            resendSentOTP: 'Codi OTP enviat correctament.',
            resendOTPErr: "Codi OTP no s'ha enviat correctament. Per favor intente-ho de nou més tard",
            referralCodeInvalid: 'El codi de referència no és vàlid',
            resendSignupCode: "Hola, acabem d'enviar-li un altre codi, per favor, comprove el seu correu electrònic.",
            resendLimitExceeded:
                "Ha aconseguit el nombre màxim d'intents. Per favor intente-ho de nou després d'algun temps.",
        },
        errors: {
            recaptchaCode: 'Verificar codi recaptcha.',
            passwordFormat:
                'La contrasenya ha de tindre més de 10 caràcters, amb almenys una lletra majúscula, una lletra minúscula, un caràcter especial i un número.',
            securityCode: 'El codi de seguretat ha de tindre almenys 6 caràcters',
            firstName: 'El nom no és vàlid.',
            lastName: 'El cognom no és vàlid.',
            termsAndCondition: 'Ha de llegir i acceptar les condicions generals per a poder continuar',
        },
    },
    Payment: {
        Title: 'Configuració de pagaments - ##COMPANY_BRAND_NAME##: Recàrrega ràpida de vehicles elèctrics al Regne Unit',
        ChargeUnit: '(kWh)',
        description: 'Pagaments a ##COMPANY_BRAND_NAME##',
        PaymentType: {
            Title: 'Configuració de Pagament',
            SubTitle: "Per favor, seleccione l'opció de pagament",
            Message: {
                selectPaymentOption: "Per favor, seleccione l'opció de pagament",
            },
            Inputs: {
                creditAccount: {
                    label: 'Compte de crèdit',
                    label1: 'Pagament a mesura que recarrega',
                },
                paymentAccount: {
                    label: 'Compte de pagament anticipat',
                    label1: 'Pague per endavant i utilitze el crèdit del compte per a carregar',
                },
            },
            Buttons: {
                Next: {
                    text: 'Següent',
                },
            },
        },
        CreditPayment: {
            Title: 'Configuració de Pagament',
            SubTitle: 'Compte de crèdit',
            note: "Tinga en compte que en afegir una targeta de pagament es carregarà en el seu compte ##CURRENCY## que s'abonarà en el saldo del seu moneder ##COMPANY_BRAND_NAME##.",
            donateMessage: {
                first: 'Sí, dona',
                second: 'Per a arredonir el pagament a',
            },
            donationNote:
                "Donació 'Arredonir' als 50 cèntims més pròxims o 'Recarregar' amb una quantitat fixa per cada operació de pagament.",
            Inputs: {
                CardTitle: {
                    label: 'Nom del titular de la tarjeta',
                    placeHolder: 'Nom del titular de la tarjeta',
                    required: 'Es requerix titol de la targeta',
                },
                CardNumber: {
                    label: 'Número de targeta',
                    placeHolder: '1234 1234 1234 1234',
                    invalid: 'Número de targeta invàlid.',
                    required: 'Es requerix el número de la targeta',
                    maxlimit: 'El número de targeta ha de tindre com a màxim 20 dígits',
                },
                Expiry: {
                    label: 'Caducitat',
                    required: 'Es requerix el mes de caducitat',
                },
                Year: {
                    label: 'Any',
                    required: "Es requerix l'any de caducitat",
                },
                CSC: {
                    label: 'CSC',
                    label1: '(Codi de seguretat de la targeta)',
                    required: 'Es requerix el codi de seguretat de la targeta',
                    placeHolder: 'CSC',
                    maxlimit: 'Introduïsca un codi minim de 3 dígits.',
                },
                Donation: {
                    label: 'Sí, vull donar',
                },
                giftaid: {
                    label: 'Permetre la donació amb Gift Aid?',
                },
                RoundUp: {
                    label: 'Arredonir',
                },
                TopUp: {
                    label: 'Recarregar',
                },
            },
            Buttons: {
                Save: {
                    text: 'Guardar',
                },
                Back: {
                    text: 'Canviar a un compte de pagament anticipat',
                },
                editBack: {
                    text: 'Tornar',
                },
            },
            Card: {
                expire: 'Caducitat',
                add: 'Afegir targeta',
                charity: 'Donació benèfica',
                edit: 'Editar la configuració de la donació',
                editCard: 'Editar targeta',
                delete: 'Suprimir',
                replace: 'Substituir',
                balance: 'Saldo actual',
                Outbalance: 'Saldo final:',
                mmYY: 'MM/AA',
                mm: 'MM',
                yyyy: 'AAAA',
                Pay: {
                    text: 'Pagar ara',
                },
                Withdraw: {
                    text: 'Retirar fons',
                },
                toast: {
                    delete: {
                        success: 'Targeta eliminada correctament',
                        error: "S'ha produït un error en processar la seua sol·licitud. Per favor, intente-ho de nou més tard",
                    },
                    save: {
                        success: "Les dades de la targeta s'han guardat correctament",
                        error: "S'ha produït un error en processar la seua sol·licitud. Per favor, intente-ho de nou més tard",
                    },
                    paynow: {
                        success: 'Pagament efectuat correctament.',
                        error: "S'ha produït un error en processar la seua sol·licitud.  Per favor, intente-ho de nou més tard",
                    },
                    edit: {
                        success: "Les dades de la targeta s'han actualitzat correctament",
                        error: "S'ha produït un error en processar la seua sol·licitud. Per favor, intente-ho de nou més tard",
                    },
                    replace: {
                        success: "Les dades de la targeta s'han substituït correctament",
                        error: "S'ha produït un error en processar la seua sol·licitud. Per favor, intente-ho de nou més tard",
                    },
                    common: {
                        error: "S'ha produït un error en processar la seua sol·licitud. Per favor, intente-ho de nou més tard",
                    },
                    amount: 'No hi ha cap saldo pendent que cobrar.',
                    noSwitch: 'Per favor, liquide el seu saldo pendent abans de canviar a un compte de prepagament',
                },
            },
            label: {
                accountFunds: 'Fons del compte',
                rewards: 'Recompensa',
                referralBonus: 'Bonificació per recomanació',
                currentBalance: 'Saldo actual',
                seeMore: 'Veure més',
                message: 'Missatge',
                charityDonation: 'Donació benèfica',
                topupAmount: 'Sí, dona',
                note: 'Nota:',
                charity: 'caritat',
                card: 'tarjeta',
                donation: 'donació',
                giftaid: 'giftaid',
                noThanks: 'No, gràcies',
                cancel: 'Cancel·lar',
            },
            error: 'La seua transacció de pagament està per davall del valor mínim de transacció ##AMOUNT',
        },
        PrePaymentDetail: {
            Title: 'Configuració de Pagament',
            SubTitle: 'Compte de pagament anticipat',
            Inputs: {
                Amount: {
                    label: 'Import',
                    placeHolder: 'Import',
                    required: 'Es requerix un import',
                    invalid: 'El Import ha de ser major o igual a',
                    maxLimit: '',
                    number: "L'import ha de ser un número",
                },
                CardNumber: {
                    label: 'Número de targeta',
                    placeHolder: '1234 1234 1234 1234',
                    required: 'Es requerix el número de la targeta',
                    invalid: 'Número de targeta invàlid.',
                },
                Expiry: {
                    label: 'Caducitat',
                    required: 'Es requerix el mes de caducitat',
                },
                Year: {
                    label: 'Any',
                    required: "Es requerix l'any de caducitat",
                },
                CSC: {
                    label: 'CSC',
                    label1: '(Codi de seguretat de la targeta)',
                    required: 'Es requerix el codi de seguretat de la tarjeta',
                    placeHolder: 'CSC',
                    maxlimit: 'Introduïsca un codi minim de 3 dígits.',
                },
                Donation: {
                    label: 'Sí, vull donar',
                },
                RoundUp: {
                    label: 'Arredonir',
                },
                TopUp: {
                    label: 'Recarregar',
                },
            },
            Buttons: {
                Save: {
                    text: 'Afegir diners',
                },
                Back: {
                    text: 'Canviar a un compte de crèdit',
                    text1: 'Tornar',
                },
                FailBack: {
                    text: 'Canviar a un compte',
                },
            },
            PrePayemnt: {
                text: {
                    currentBalance: 'Saldo actual',
                    OutBalance: 'Saldo final',
                },
                button: {
                    add: 'Afegir diners',
                    Withdraw: 'Retirar fons',
                    withdrawAccountFunds: 'Retirar fons del compte',
                    viewYourRefundRequests: 'Veures les sol·licituds de retirada',
                },
            },
            toast: {
                canNotAddMoney:
                    'No pot afegir més fons perquè ha aconseguit el límit màxim de fons que pot tindre en el seu moneder ##COMPANY_BRAND_NAME##.',
                common: {
                    error: "S'ha produït un error en processar la seua sol·licitud. Per favor intente-ho de nou més tard",
                },
                maxLimitForPrePayment: 'Eh! només pots carregar el saldo del teu moneder fins a ##MAXAMOUNT.',
                maxLimitReached:
                    'Eh! només pots carregar el saldo del teu moneder fins a ##MAXAMOUNT. Ara ja pots afegir un màxim de ##ALLOWEDAMOUNT.',
            },
            label: {
                accountFunds: 'Fons del compte',
                rewards: 'Recompensa',
                referralBonus: 'Bonificació per recomanació',
                currentBalance: 'Saldo actual',
                seeMore: 'Veure més',
                billingAddress: 'Adreça de facturació',
                charityDonation: 'Donació benèfica',
                note: 'Nota:',
            },
            donationNote: "Donació 'Top Up' amb una quantitat fixa per a cada transacció de pagament.",
        },
        PrePaymentWithdraw: {
            Title: 'Configuració de Pagament',
            SubTitle: 'Compte de pagament anticipat',
            Inputs: {
                Amount: {
                    label: 'Import',
                    placeHolder: 'Import',
                    required: 'Es requerix un import',
                    invalid: "L'import és superior al saldo actual",
                },
                CardNumber: {
                    label: 'Número de targeta',
                    placeHolder: '1234 1234 1234 1234',
                    required: 'Es requerix el número de la targeta',
                    invalid: 'Número de targeta invàlid.',
                    maxlimit: 'El número de targeta ha de tindre com a màxim 20 dígits',
                },
                Expiry: {
                    label: 'Caducitat',
                    required: 'Es requerix el mes de caducitat',
                },
                Year: {
                    label: 'Any',
                    required: "Es requerix l'any de caducitat",
                },
                CSC: {
                    label: 'CSC',
                    label1: '(Codi de seguretat de la targeta)',
                    required: 'Es requerix el codi de seguretat de la tarjeta',
                    placeHolder: 'CSC',
                    maxlimit: 'Introduïsca un codi minim de 3 dígits.',
                },
            },
            Buttons: {
                Withdraw: {
                    text: 'Retirar fons',
                },
                Back: {
                    text: 'Canviar a un compte de crèdit',
                    text1: 'Tornar',
                },
                FailBack: {
                    text: 'Canviar a un compte',
                },
            },
            PrePayemnt: {
                text: {
                    currentBalance: 'Saldo actual',
                    OutBalance: 'Saldo final',
                },
                button: {
                    add: 'Afegir diners',
                    Withdraw: 'Retirar fons',
                },
            },
        },
        PaymentSuccess: {
            label: 'Tot un èxit!',
            Successlabel: 'Transacció correcta',
            transactionId: 'ID de Transaccions:',
            amount: 'Import',
            warningLabel: 'Avís:',
            common: {
                error: "S'ha produït un error en processar la seua sol·licitud. Per favor intente-ho de nou més tard",
            },
        },
        PaymentFail: {
            label: 'Transacció fallida',
            failureLabel: 'La seua transacció ha fallat. Per favor intente-ho de nou.',
        },
        PaymentProcess: {
            label: 'Espere mentres es processa la seua transacció',
        },
        Model: {
            header: 'Canviar a un compte',
            DeleteBoxHeader: 'Suprimir targeta',
            WithdrawBoxHeader: 'Retirar fons',
            button: {
                yes: 'Sí',
                no: 'No',
                existingCard: 'Targeta existent',
                newCard: 'Nova targeta',
            },
            messagePrePayment: 'Segur que vol vanviar a un compte de pagament anticipat?',
            messageCreditPayment: 'Segur que vol canviar a un compte de crèdit?',
            msgPrePaymentSaveCard:
                "Si canvia al compte de prepagament, les seues targetes guardades s'eliminaran. Segur que vol canviar a un compte de pagament anticipat?",
            DeleteCardMsg: 'Segur que vol eliminar les detalls de la targeta?',
            WithdrawMoneyMsg: "Seleccione l'opció de targeta per a retirar diners.",
        },
        charityModal: {
            yesDonate: 'Sí, dona',
        },
        Withdraw: {
            CanNotWithdrawMoneyWhileSessionIsActive:
                'No podeu retirar diners en aquest moment. Si us plau, espereu que finalitzi la sessió de càrrega corrent',
        },
    },
    termsAndCondition: {
        title: 'Condicions generals',
        note: 'Pàgina en construcció.',
    },
    table: {
        data: {
            noData: "No s'han trobat dades.",
        },
        pagination: {
            prev: 'Anterior',
            nrev: 'Següent',
            showing: 'Mostrar',
            pageSize: {
                ten: '10',
                fifty: '50',
                hundred: '100',
            },
        },
    },
    history: {
        headerTitle:
            'Historial de pagaments i recàrrega - ##COMPANY_BRAND_NAME##: Recàrrega ràpida de vehicles elèctrics al Regne Unit',
        title: 'Historial de pagaments i recàrrega',
        columns: {
            chargingStation: 'Estació de càrrega',
            date: 'Data',
            address: 'Adreça',
            milesAdded: 'Milles afegides',
            time: 'Temps',
            cost: 'Cost',
            energy: 'Energia',
            action: 'Acció',
            remark: 'Observacions',
            credit: 'Crèdit',
            debit: 'Dèbit',
            closing: 'Tancant',
            transaction: 'ID de Transaccions',
            credit_debit: 'Crèdit/dèbit',
        },
        form: {
            charge: 'Carregant',
            payment: 'Pagament',
            month: 'Mes',
        },
        label: {
            exportAll: 'Generar rebut',
        },
        button: {
            search: 'Buscar',
            reset: 'Restablir',
        },
        message: {
            selectRecordToDownload: 'Seleccione qualsevol registre per a generar un rebut.',
            noChargingHistoryMessage: 'Quan recarregue el seu VEU, el seu historial de recàrregues apareixerà ací',
            noPaymentHistoryMessage: 'Quan haja completat una transacció de pagament, es mostrarà ací',
        },
        receipt: {
            title: 'REBUT',
            header: {
                date: 'Data',
                billing_addresss: 'Adreça de facturació',
                note: 'Su ##COMPANY_BRAND_NAME## rebut de recàrrega EV',
                vat_number: "Número de l'IVA",
            },
            content: {
                summary: 'A continuació es mostra un resum de les seues operacions de cobrament',
                table: {
                    header: {
                        station_name: "Nom de l'estació",
                        start: 'Inici',
                        end: 'Fi',
                        usage: 'Consum',
                        unit: 'Unitat',
                        value: 'Valor',
                        transaction_id: 'ID de Transaccions',
                        vatRate: 'Taxa IVA',
                        vat: 'IVA',
                    },
                },
                total_before_vat: "Total abans de l'IVA",
                total_vat: 'Total IVA',
                vat: 'IVA a',
                total_fee: 'Taxa total',
                thanks_message: 'Gràcies per recarregar en la xarxa de recàrrega de ##COMPANY_BRAND_NAME##',
                vatReceiptNotGenerated:
                    "Encara no s'ha generat el rebut de l'IVA. Per favor, intente-ho de nou 24 hores després que la sessió de recàrrega haja acabat. Si seguix sense aconseguir-ho, pose's en contacte amb el nostre servici d'atenció al client.",
                vatReceiptNotAssociatedWithAcc:
                    "El rebut de l'IVA no s'ha associat al seu compte. Assegure's que està utilitzant les credencials correctes per a veure el rebut de l'IVA.",
            },
            footer: {
                title: "##COMPANY_BRAND_NAME## Limited és una empresa registrada a Anglaterra i Gal·les, amb número d'empresa 10484882",
                address: '6 Cedarwood, Crockford Lane, Chineham Park, Basingstoke, Hampshire, RG24 8WD',
                telephone: 'T',
                email: 'E',
                note: "Indique la data i el número d'identificació de la transacció en tota la correspondència.",
            },
        },
    },
    loyaltyPoint: {
        header: 'Recompenses - ##COMPANY_BRAND_NAME## Recàrrega ràpida de vehicles elèctrics al Regne Unit',
        title: 'Recompensa',
        note: '*Només pots canviar volts a través de la nostra app.',
        availableVolts: 'Volts disponibles:',
        noDataMessage: 'Quan haja completat una sessió de recàrrega, les seues transaccions de volts es mostraran ací',
        label: {
            chargingStation: 'Estació de recàrrega',
            date: 'Data',
            address: 'Adreça',
            credit: 'Crèdit',
            debit: 'Dèbit',
            closing: 'Tancant',
            redeemed: 'Canviat',
            charged: 'Recàrregat',
            remark: 'Observacions',
            credit_debit: 'Crèdit/dèbit',
            voltsRedeemed: 'Volts redimits',
        },
    },
    withdraw: {
        subTitle: 'Compte de pagament anticipat',
        label: {
            maxWithdrawalBalance: 'Saldo màxim de retirada',
            note: 'Nota:',
            enterAmount: 'Introduïsca un import',
            withdrawAmount: 'Import de la retirada',
            expires: 'Caducitat',
            rewardHeading: 'Per favor, Introduïsca un valor que desitja retirar del seu compte.',
            selectCardToWithdrawAmount: "Seleccione la targeta per a retirar l'import",
            remainingAmount: 'Import restant',
            accountName: 'Nom del compte',
            sortCode: "Codi d'ordenació",
            accountNumber: 'Número de compte',
            bankDetail: 'Details del bank',
            withdrawAmountNote:
                'Només podrà transferir els fons del seu compte a la targeta de pagament que es va utilitzar per a ingressar fons en el compte.',
            withdrawAmountNote1: 'Tinga en compte que la retirada es tramitarà en un termini de 14 dies.',
            withdrawAmountNote2:
                'Tinga en compte que el desistiment es tramitarà en un termini de 3 a 5 dies laborables.',
            accountNameNote:
                "Assegure's que el nom del compte bancari coincidix amb el nom del seu compte ##COMPANY_BRAND_NAME##, en cas contrari, la retirada serà rebutjada.",
        },
        placeholder: {
            amount: 'Import',
            accountName: 'Nom del compte',
            sortCode: "Codi d'ordenació",
            accountNumber: 'Número de compte',
        },
        button: {
            back: 'Tornar',
            next: 'Següent',
            raisedRequest: 'Presentar una sol·licitud de retirada',
            withdrawMoney: 'Retirar fons',
            proceed: 'Procedisca',
        },
        errors: {
            amount: {
                typeError: 'Introduïsca un import válid',
                moreThan: "L'import ha de ser major que 0",
            },
            AmountIsGreaterThanwithdrawableAmount: "L'import introduït és superior a l'import que es pot retirar",
            selectCardToWithdrawMoney: 'Seleccione qualsevol targeta per a retirar diners.',
            canNotSelectCard:
                'El valor de la retirada supera el fons màxim de retirada disponible per a la targeta de pagament seleccionada. Trie una altra targeta.',
        },
    },
    termsAndConditionListing: {
        title: 'Condicions generals - ##COMPANY_BRAND_NAME##: Recàrrega ràpida de vehicles elèctrics al Regne Unit',
        noteDetails: "*Ha d'acceptar-se obligatòriament per a poder utilitzar/registrar un compte.",
        label: {
            referralCode: 'Codi de referència (si en té un)',
            promotioncode: 'Codi de promoció (si en té un)',
            termsAndConditions: 'Condicions generals',
            welcome: 'Benvingut/Benvinguda',
            apply: 'Sol·licitar',
            note: 'Nota:',
            invalidCode: 'Ui! Codi invàlid!',
            usedCode: 'Codi ja utilitzat.',
            codeApplied: 'Codi aplicat',
            iAggreeToTermsCondition: 'Accepte',
            reviewUpdatedCondition:
                'Per favor, revise i accepte les condicions generals actualitzades que figuren a continuació.',
            name: 'Nom',
            accepted: 'Acceptat',
        },
        button: {
            next: 'Següent',
            cancel: 'Cancel·lar',
            accept: 'Acceptar',
        },
        errors: {
            acceptTermCondition: 'Per favor, accepte les Condicions Generals per a continuar',
            invalidReferralCode:
                'El codi de referència ##REFERRAL_CODE## introduït no és vàlid. Per favor, corregisca (el valor del text distingix entre majúscules i minúscules) o elimine el Codi de Referència per a continuar.',
            invalidPromotionCode:
                'El codi de promoció ##PROMO_CODE## introoduit no és válid. Per favor, corregisca (el valor del text distingix entre majúscules i minúscules) o elimine el codi promocional per a continuar.',
            referralNotAllowed: 'Els codis de referència no poden utilitzar-se per a esta promoció',
        },
        message: {
            refferalCode: {
                first: 'El codi de referència',
                second: 'introduït no és vàlid. Per favor, corregisca (el valor del text distingix entre majúscules i minúscules) o elimine el Codi de Referència per a continuar.',
            },
            promotionCode: {
                first: 'El codi de Promoció',
                second: 'que ha introduit no és válid. Per favor, corregisca (el valor del text distingix entre majúscules i minúscules) o elimine el codi promocional per a continuar.',
            },
        },
    },
    cookiePolicy: {
        text: "Este lloc web utilitza galletes per a millorar l'experiència de l'usuari. En utilitzar el nostre lloc web, reconeix haver llegit i comprés la nostra",
        action: 'Política de galletes',
        iAccept: 'Accepte',
    },
    openSource: {
        title: 'Crèdits',
    },
    refundRequest: {
        title: 'Sol·licituds de retirada - ##COMPANY_BRAND_NAME##: Recàrrega ràpida de vehicles elèctrics al Regne Unit',
        header: 'Sol·licituds de retirada',
        label: {
            name: 'Nom',
            requestedDate: 'Data sol·licitada',
            refundRequested: 'Retirada sol·licitada',
            account: 'Compte (Per a retirar crèdits)',
            remarks: 'Observacions',
            transactionID: 'Identificació de la sol·licitud',
            processing: 'Tractament',
            cancelled: 'Cancel·lat',
            declined: 'Rebutjar',
            complete: 'Completar',
            date: 'Data',
            status: 'Estat',
            cancelRequest: 'Cancel·lar la sol·licitud',
            action: 'Acció',
        },
        button: {
            search: 'Buscar',
            reset: 'Restablir',
            yes: 'Sí',
            no: 'No',
        },
        placeholder: {
            selectStatus: 'Seleccionar estat',
        },
        message: {
            confirmCancelRequest: 'Està segur de que vol cancel·lar la Sol·licitud de retirada?',
        },
        errors: {
            sortCode: {
                min: 'Per favor, introduïsca un codi minim de 6 dígits.',
                valid: "Per favor, introduïsca un codi d'ordenació vàlid.",
            },
            accountNumber: {
                min: 'Per favor, introduïsca un codi minim de 8 dígits.',
            },
        },
    },
    footer: {
        version: 'Versió',
        copyRightText: 'Tots els drets reservats',
    },
    rfidCards: {
        headerTitle: 'Targeta RFID- ##COMPANY_BRAND_NAME##: Recàrrega ràpida de vehicles elèctrics al Regne Unit',
        title: 'Targetes RFID',
        label: {
            public_card_id: 'Número(s) de targeta',
            quantity: 'Introduïsca una quantitat',
            action: 'Acció',
            delete: 'Suprimir',
            cardNumber: 'Introduïsca el número de targeta',
            rfid_card_unit_price: "El preu unitari d'una targeta RFID és de",
            rfid_card_unit_price_label: 'Per Targeta RFID',
        },
        placeholder: {
            quantity: 'Introduïsca una quantitat',
            public_card_id: 'Introduïsca el número de targeta',
        },
        noDataMessage: 'Cap targeta RFID activa en este moment',
        requestProcessDaysMessage: 'La seua sol·licitud de targeta RFID es processarà ##DAYS## dies laborables.',
        modal: {
            header: 'Sol·licitud de targeta RFID',
            body: "Introduïsca el nombre de targetes RFID necessàries. Les targetes s'enviaran a l'adreça que haja configurat en el seu compte. Quan reba les targetes, haurà d'activar-les en el seu compte.",
        },
        addCardModal: {
            header: 'Afegir targeta RFID',
        },
        button: {
            proceed: 'Procedisca',
            cancel: 'Cancel·lar',
            addNewCard: 'Afegir targeta RFID',
            requestCard: 'Sol·licitar nova targeta RFID',
            delete: 'Suprimir',
            action: 'Acció',
        },
        errors: {
            quantity: {
                min: 'El valor ha de ser superior a zero',
            },
            public_card_id: {
                valid: "L'identificador de targeta que ha introduït no ha sigut reconegut. Per favor, assegure's que no s'han inclòs espais/caràcters especials en l'identificador.",
            },
        },
        message: {
            here: 'ací.',
            deleteConfirmMessage:
                'En esborrar esta targeta, quedarà desactivada permanentment i no podrà tornar a registrar-se. Segur que vol continuar?',
        },
    },
    giftAidmessage: 'missatge de Gift Aid',
    creditAccountDonationNote:
        "En seleccionar Sí, s'aplicarà una donació de ##AMOUNT## a tots els pagaments futurs. Pot canviar la seua preferència de donació en qualsevol moment fent clic en el botó â€œEditar Configuració de Donacionsâ€",
    PrepaymentAccountDonationNote:
        "En seleccionar Sí, s'aplicarà una donació de ##AMOUNT## a esta transacció de pagament. Pot canviar la seua preferència de donació en qualsevol moment fent clic en el botó â€œEditar Configuració de Donacionsâ€",
    aboutUs: {
        listTitle: {
            openSourcelicenses: 'Llicències de codi obert',
        },
    },
    price_breakdown_details: {
        detailed_breakup: 'Desglossament detallat',
        total_cost: 'Cost total',
        conversion_rate: 'Taxa de conversió',
    },
};
