import React from 'react';
import { connect } from 'react-redux';
import { AppState } from '../store/state/app.state';
import { IAppFooterProps } from '../types/PropTypes';
import { WithTranslation, withTranslation } from 'react-i18next';

const Footer = (props: IAppFooterProps & WithTranslation) => {
    return (
        <footer className="footer">
            <p className="copyright">
                {`${props.t('footer.version')}: ${
                    props?.userData?.version_number_driver !== null ? props?.userData?.version_number_driver : ''
                } | ${props.t('footer.copyRightText')}`}
            </p>
        </footer>
    );
};
const mapStateToProps = (state: AppState) => ({
    userData: state.UserData,
});

export default connect(mapStateToProps, null)(withTranslation()(Footer));
