import { Ability } from '@casl/ability';
import { store } from '../store';
import { createContext } from 'react';
import { createContextualCan } from '@casl/react';

const storeData = store.getState();
const abilities: any = storeData.UserData ? storeData.UserData.abilities : [];

export default new Ability(abilities);

const AbilityContext: any = createContext({});
const Can = createContextualCan(AbilityContext.Consumer);

export { AbilityContext, Can };
