import BaseService from 'services/base-service';
import StandardResponse from 'models/standard-response';
import { IVatReceipt } from 'models/history';

export default class HistoryAPIService {
    getHistoryList = async (data: any): Promise<StandardResponse<any>> => {
        try {
            const res = await BaseService({
                url: `/driver/charge-payment-history/search`,
                method: 'POST',
                data,
            });
            return new StandardResponse<any>(res.status, res.data.data, res.data.message, null);
        } catch (err) {
            return Promise.reject(err);
        }
    };

    getReceiptData = async (data: any): Promise<StandardResponse<IVatReceipt>> => {
        try {
            const res = await BaseService({
                url: `/driver/charge-payment-history/export`,
                method: 'POST',
                data,
            });
            return new StandardResponse<IVatReceipt>(res.status, res.data.data, res.data.message, null);
        } catch (err) {
            return Promise.reject(err);
        }
    };

    getDownloadReceiptData = async (data: any): Promise<StandardResponse<IVatReceipt>> => {
        let apiUrl = '';
        if (data.sessionPrimaryId) {
            apiUrl = `/driver/charge-payment-history/export/${data.sessionId}/${data.sessionPrimaryId}`;
        } else {
            apiUrl = `/driver/charge-payment-history/export/${data.sessionId}`;
        }
        try {
            const res = await BaseService({
                url: apiUrl,
                method: 'get',
                data,
            });
            return new StandardResponse<IVatReceipt>(res.status, res.data.data, res.data.message, null);
        } catch (err) {
            return Promise.reject(err);
        }
    };
}
