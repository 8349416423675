import React, { Component } from 'react';
import LoginService from '../../services/login/loginApi-service';
import ConfigLoginService from '../../services/login/login-service';
import StandardResponse from '../../models/standard-response';
import { ITermsCondtionData, IReferralDetail } from '../../models/login';
import { toast } from 'react-toastify';
import { ITermsAndConditionListingProps } from '../../types/propTypes';
import { ITermsAndConditionListingState } from '../../types/stateTypes';
import { Helmet } from 'react-helmet';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Form, Button, Modal } from 'react-bootstrap';
import * as _ from 'lodash';
import { connect } from 'react-redux';
import { AppState } from '../../store/state/app.state';
import UserService from '../../services/user/user-service';
import { IUserData as IUserProfileData } from '../../models/profile';
import { ActionType } from '../../store/actions/Helpers';
import { Common } from '../../utils/constants';
import { logout, setCurrencyDetails } from '../../store/actions/app.Actions';
import images from '../../assets/images';
import { companyNameReplace } from '../../utils/index';

export class TermsAndConditionListing extends Component<
    ITermsAndConditionListingProps & WithTranslation,
    ITermsAndConditionListingState
> {
    loginService: LoginService;
    configService: ConfigLoginService;
    userService: UserService;
    intialValues = {};
    maxHeight: number = 350;
    constructor(props) {
        super(props);

        this.state = {
            TermsConditionList: [],
            promotionCode: '',
            referralCode:
                this.props.location && this.props.location.state && this.props.location.state.referralCode
                    ? this.props.location.state.referralCode
                    : '',
            isValidPromotionCode: null,
            isValidReferralCode: null,
            requiredTermsAndCondition: [],
            selectedTermsAndCondition: [],
            isReachedToBottom: false,
            isReferralDisabled: false,
            is_package_promotion: false,
            model: {
                body: null,
                header: '',
                showModel: false,
                id: null,
            },
            promotionCodeClass: '',
            referralCodeClass: '',
            referral_message: '',
            isReferralBonusOff: false,
            isUsedPromotionCode: false,
        };
        this.loginService = new LoginService();
        this.userService = new UserService();
        this.configService = new ConfigLoginService();
    }
    async componentDidMount() {
        await this.getTermsConditionListing();
        const isReferralDisabledConfig = await this.getConfigData(Common.ConfigurationKey.DISABLE_REFERRAL);
        if (isReferralDisabledConfig.length > 0) {
            let isReferralDisabled = isReferralDisabledConfig[0].value ? isReferralDisabledConfig[0].value : false;
            this.setState({ isReferralDisabled: isReferralDisabled });
        }
        const referralBonusOffConfig = await this.getConfigData(Common.ConfigurationKey.REFERRAL_BONUS);
        if (referralBonusOffConfig.length > 0) {
            let referralBonus = referralBonusOffConfig[0].value ? referralBonusOffConfig[0].value : false;
            let isReferralBonusOff: boolean = false;
            if (referralBonus.trim().toLowerCase() === 'off') {
                isReferralBonusOff = true;
                this.setState({ isReferralBonusOff: isReferralBonusOff, referralCode: '' });
            } else {
                isReferralBonusOff = false;
                this.setState({ isReferralBonusOff: isReferralBonusOff });
            }
        }
        if (this.state.referralCode !== undefined && this.state.referralCode !== '') {
            this.checkReferralCodeValid();
        }
    }

    getConfigData = async (code: string) => {
        let configData = [];
        await this.configService
            .getAPIKey({ code })
            .then(async (res) => {
                configData = res.data;
            })
            .catch((m) => {
                toast.error(m.toString());
            });
        return configData;
    };

    getTermsConditionListing = async () => {
        let requiredTermsAndConditionIds = [];
        let TermsConditionList: Array<any> = [];
        await this.loginService
            .getTermsAndConditionListing()
            .then(async (res: StandardResponse<Array<ITermsCondtionData>>) => {
                if (this.props.userData.has_saved_terms) {
                    if (_.isArray(res.Data)) {
                        TermsConditionList = res.Data.filter(
                            (x) => x.accepted === null || (x.mandatory === true && x.accepted === false),
                        );
                    }
                } else {
                    TermsConditionList = res.Data;
                }
                if (_.isArray(TermsConditionList)) {
                    const requiredTermsCondition = TermsConditionList.filter((x) => x.mandatory === true);
                    if (_.isArray(requiredTermsCondition)) {
                        requiredTermsCondition.forEach((data) => {
                            requiredTermsAndConditionIds.push(data.id);
                        });
                    }
                }

                this.setState({
                    TermsConditionList,
                    requiredTermsAndCondition: requiredTermsAndConditionIds,
                });
            })
            .catch((m) => {
                toast.error(m.toString());
            });
    };
    checkReferralCodeValid = async () => {
        if (this.state.referralCode !== '') {
            await this.loginService
                .checkReferralCode(this.state.referralCode)
                .then(async (res: StandardResponse<IReferralDetail>) => {
                    this.setState({
                        isValidReferralCode: res.Data && res.Data !== undefined,
                        referral_message: res.Data.referral_message,
                    });
                    if (
                        this.state.promotionCode !== '' &&
                        this.state.isValidReferralCode !== null &&
                        this.state.isValidReferralCode &&
                        this.state.isValidPromotionCode !== null &&
                        this.state.isValidPromotionCode &&
                        this.state.is_package_promotion !== null &&
                        this.state.is_package_promotion &&
                        this.state.isReferralDisabled !== null &&
                        this.state.isReferralDisabled
                    ) {
                        toast.error(this.props.t('termsAndConditionListing.errors.referralNotAllowed'));
                        this.setState({ ...this.state, referralCode: '', isValidReferralCode: null });
                    }
                })
                .catch((m) => {
                    if (m.response && m.response.data.message) {
                        this.setState({
                            isValidReferralCode: false,
                        });
                    } else {
                        toast.error(m.toString());
                    }
                });
        } else {
            this.setState({ isValidReferralCode: null });
        }
    };
    checkPromotionCodeValid = async () => {
        if (this.state.promotionCode !== '') {
            await this.loginService
                .checkPromotionCode(this.state.promotionCode)
                .then(async (res: StandardResponse<any>) => {
                    this.setState({
                        isValidPromotionCode: res.Data,
                        is_package_promotion: res.Data.is_package_promotion,
                    });
                    if (
                        this.state.referralCode !== '' &&
                        this.state.isValidReferralCode !== null &&
                        this.state.isValidReferralCode &&
                        this.state.isValidPromotionCode !== null &&
                        this.state.isValidPromotionCode &&
                        this.state.is_package_promotion !== null &&
                        this.state.is_package_promotion &&
                        this.state.isReferralDisabled !== null &&
                        this.state.isReferralDisabled
                    ) {
                        toast.error(this.props.t('termsAndConditionListing.errors.referralNotAllowed'));
                        this.setState({ ...this.state, referralCode: '', isValidReferralCode: null });
                    }
                })
                .catch((m) => {
                    if (m.response && m.response.data.message) {
                        if (!m.response.data.message.includes(this.state.promotionCode)) {
                            this.setState({
                                isValidPromotionCode: false,
                                referral_message: '',
                                isUsedPromotionCode: true,
                            });
                        } else {
                            this.setState({
                                isValidPromotionCode: false,
                                referral_message: '',
                                isUsedPromotionCode: false,
                            });
                        }
                        toast.error(m.response.data.message);
                    } else {
                        toast.error(m.toString());
                    }
                });
        } else {
            this.setState({ isValidPromotionCode: null, isUsedPromotionCode: false });
        }
    };
    handleSubmit = async () => {
        if (this.state.isValidReferralCode !== null && !this.state.isValidReferralCode) {
            const message = () => {
                return (
                    <>
                        <span>
                            {this.props.t('termsAndConditionListing.message.refferalCode.first')}{' '}
                            <span className="themeTextColor">{this.state.referralCode}</span>
                            {this.props.t('termsAndConditionListing.message.refferalCode.second')}
                        </span>
                    </>
                );
            };
            toast.error(message);
        } else if (this.state.isValidPromotionCode !== null && !this.state.isValidPromotionCode) {
            const message = () => {
                return (
                    <>
                        <span>
                            {this.props.t('termsAndConditionListing.message.promotionCode.first')}{' '}
                            <span className="themeTextColor">{this.state.promotionCode}</span>{' '}
                            {this.props.t('termsAndConditionListing.message.promotionCode.second')}
                        </span>
                    </>
                );
            };
            toast.error(message);
        } else {
            let acceptedAllTermsCondition = true;
            this.state.requiredTermsAndCondition.forEach((data) => {
                if (!this.state.selectedTermsAndCondition.includes(data)) {
                    acceptedAllTermsCondition = false;
                }
            });
            if (acceptedAllTermsCondition) {
                const terms_conditions = [];
                this.state.TermsConditionList.forEach((data) => {
                    terms_conditions.push({
                        id: data.id,
                        accepted: this.state.selectedTermsAndCondition.includes(data.id),
                    });
                });
                const input = { terms_conditions };
                if (this.state.isValidPromotionCode) {
                    Object.assign(input, {
                        promotion_code: _.trimEnd(this.state.promotionCode),
                    });
                }
                if (this.state.isValidReferralCode !== null && this.state.isValidReferralCode) {
                    Object.assign(input, {
                        referral_code: _.trimEnd(this.state.referralCode),
                    });
                }
                await this.loginService
                    .saveTermsAndCondition(input)
                    .then(async () => {
                        if (
                            this.state.referral_message !== '' &&
                            this.state.isValidReferralCode !== null &&
                            this.state.isValidReferralCode
                        ) {
                            toast.success(this.state.referral_message);
                        }
                        this.userService
                            .getProfile()
                            .then((userDetail: StandardResponse<IUserProfileData>) => {
                                if (
                                    userDetail &&
                                    userDetail.Data &&
                                    userDetail.Data.status &&
                                    userDetail.Data.status.code === Common.UserStatus.CANCELLED
                                ) {
                                    this.props.dispatch(logout()).then(() => {
                                        toast.error(this.props.t('login.toast.cancelled'), { autoClose: 15000 });
                                    });
                                } else {
                                    this.props.dispatch(setCurrencyDetails(userDetail.Data?.default_currency));
                                    this.props.dispatch({
                                        type: ActionType.FETCH_USER_DETAILS,
                                        payload: userDetail.Data,
                                    });
                                    if (userDetail && userDetail.Data.terms_and_condition_required) {
                                        this.props.navigate('/TermsAndCondition');
                                    } else if (userDetail && userDetail.Data.is_profile_verified === false) {
                                        this.props.navigate('/profile?redirect=true');
                                    } else if (userDetail && userDetail.Data.is_profile_verified === true) {
                                        if (
                                            !localStorage.getItem(`${Common.MARKETING_CONSENT}-${userDetail.Data.id}`)
                                        ) {
                                            this.props.navigate('/profile');
                                        } else if (userDetail && userDetail.Data.payment_type === null) {
                                            this.props.navigate('/payment');
                                        } else {
                                            this.props.navigate('/');
                                        }
                                    }
                                    if (this.state.isValidPromotionCode) {
                                        toast.info(this.props.t('login.toast.reminderMessage'), { autoClose: 15000 });
                                    }
                                }
                            })
                            .catch(() => {
                                this.props.dispatch(logout());
                                toast.error(this.props.t('login.toast.error'));
                            });
                    })
                    .catch((m) => {
                        if (m.response && m.response.message) {
                            toast.error(m.response.message);
                        } else {
                            toast.error(m.toString());
                        }
                    });
            } else {
                toast.error(this.props.t('termsAndConditionListing.errors.acceptTermCondition'));
            }
        }
    };
    handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value,
        });
    };
    acceptTermsCondition = (id: number) => {
        let selectedTermsAndCondition = this.state.selectedTermsAndCondition;
        selectedTermsAndCondition.push(id);
        this.setState({
            selectedTermsAndCondition,
            model: {
                body: '',
                header: '',
                id: null,
                showModel: false,
            },
            isReachedToBottom: false,
        });
    };
    handleModel = (body: string, header: string, showModel: boolean, id: number) => {
        this.setState(
            {
                model: {
                    body,
                    header,
                    showModel,
                    id,
                },
                isReachedToBottom: false,
            },
            () => {
                if (showModel) {
                    if (document.getElementById('terms_condition')?.scrollHeight <= this.maxHeight) {
                        this.setState({
                            isReachedToBottom: true,
                        });
                    }
                }
            },
        );
    };
    handleChaeckChange = (data: ITermsCondtionData) => {
        if (this.state.selectedTermsAndCondition.includes(data.id)) {
            var array = this.state.selectedTermsAndCondition;
            var index = array.indexOf(data.id);
            if (index !== -1) {
                array.splice(index, 1);
                this.setState({ selectedTermsAndCondition: array });
            }
        } else {
            this.handleModel(data.content, data.name, true, data.id);
        }
    };
    handleFocus = (e: React.ChangeEvent<HTMLInputElement>) => {
        const isValidPromotionCode = this.state.isValidPromotionCode ? this.state.isValidPromotionCode : null;
        const isValidReferralCode = this.state.isValidReferralCode ? this.state.isValidReferralCode : null;
        if (e.target.name === 'promotionCode') {
            this.setState({
                promotionCodeClass: 'value-exists',
                isValidPromotionCode,
            });
        } else if ((e.target.name = 'referralCode')) {
            this.setState({
                referralCodeClass: 'value-exists',
                isValidReferralCode,
            });
        }
    };
    handleBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.name === 'promotionCode' && e.target.value === '') {
            this.setState({
                promotionCodeClass: '',
            });
        } else if (e.target.name === 'referralCode' && e.target.value === '') {
            this.setState({
                referralCodeClass: '',
            });
        }
    };
    render() {
        const { t, companyName } = this.props;
        return (
            <>
                <Helmet>
                    <title>{companyNameReplace(t('termsAndConditionListing.title'))} </title>
                </Helmet>
                <div className="login-page container-fluid p-0">
                    <div className="row h-100 no-gutters">
                        <div className="col-md login-background-block">
                            <a href="#" title={this.props.t('login.label.logo')} className="instavolt-logo">
                                <img src={images.logoPNG} alt={this.props.t('login.label.logo')} />
                            </a>
                        </div>
                        <div className="col-md-auto login-section">
                            <div className="login-outer">
                                <div className="login-contain">
                                    <div className="title-outer">
                                        <h1 className="title">
                                            <img src={images.logoIconPNG} alt={t('login.label.logo')} />
                                            {this.props.userData.has_saved_terms
                                                ? t('termsAndConditionListing.label.termsAndConditions')
                                                : t('termsAndConditionListing.label.welcome')}
                                        </h1>
                                    </div>

                                    <form className="form-secondary" autoComplete="off">
                                        {!this.props.userData.has_saved_terms && (
                                            <>
                                                {this.state.isReferralBonusOff === false && (
                                                    <div className={`form-group ${this.state.referralCodeClass}`}>
                                                        <label className="float-label" htmlFor="referralCode">
                                                            {this.state.referralCode !== ''
                                                                ? ''
                                                                : t('termsAndConditionListing.label.referralCode')}
                                                        </label>
                                                        <div className="apply-text-outer success">
                                                            <input
                                                                className="form-control"
                                                                name="referralCode"
                                                                id="referralCode"
                                                                type="text"
                                                                value={this.state.referralCode}
                                                                onChange={(e) => this.handleChange(e)}
                                                                onFocus={(e) => this.handleFocus(e)}
                                                                onBlur={(e) => {
                                                                    this.handleBlur(e);
                                                                    this.checkReferralCodeValid();
                                                                }}
                                                                data-testid="referralCode"
                                                            />

                                                            {this.state.isValidReferralCode === null && (
                                                                <a
                                                                    href="#"
                                                                    className="apply-text"
                                                                    onClick={this.checkReferralCodeValid}
                                                                >
                                                                    {t('termsAndConditionListing.label.apply')}
                                                                </a>
                                                            )}
                                                            {this.state.isValidReferralCode !== null &&
                                                                !this.state.isValidReferralCode && (
                                                                    <span className="apply-text">
                                                                        {t(
                                                                            'termsAndConditionListing.label.invalidCode',
                                                                        )}
                                                                    </span>
                                                                )}
                                                            {this.state.isValidReferralCode !== null &&
                                                                this.state.isValidReferralCode && (
                                                                    <span className="applied">
                                                                        {t(
                                                                            'termsAndConditionListing.label.codeApplied',
                                                                        )}
                                                                        <img
                                                                            className="icon"
                                                                            src={images.appliedSVG}
                                                                            alt=""
                                                                        />
                                                                    </span>
                                                                )}
                                                        </div>
                                                    </div>
                                                )}
                                                <div className={`form-group ${this.state.promotionCodeClass}`}>
                                                    <label className="float-label" htmlFor="promotionCode">
                                                        {t('termsAndConditionListing.label.promotioncode')}
                                                    </label>
                                                    <div className="apply-text-outer success">
                                                        <input
                                                            className="form-control"
                                                            name="promotionCode"
                                                            id="promotionCode"
                                                            type="text"
                                                            value={this.state.promotionCode}
                                                            onChange={(e) => this.handleChange(e)}
                                                            onFocus={(e) => this.handleFocus(e)}
                                                            onBlur={(e) => {
                                                                this.handleBlur(e);
                                                                this.checkPromotionCodeValid();
                                                            }}
                                                            data-testid="promotionCode"
                                                        />

                                                        {this.state.isValidPromotionCode === null && (
                                                            <a
                                                                href="#"
                                                                className="apply-text"
                                                                onClick={this.checkPromotionCodeValid}
                                                            >
                                                                {t('termsAndConditionListing.label.apply')}
                                                            </a>
                                                        )}
                                                        {this.state.isValidPromotionCode !== null &&
                                                            !this.state.isValidPromotionCode && (
                                                                <span className="apply-text">
                                                                    {this.state.isUsedPromotionCode
                                                                        ? t('termsAndConditionListing.label.usedCode')
                                                                        : t(
                                                                              'termsAndConditionListing.label.invalidCode',
                                                                          )}
                                                                </span>
                                                            )}
                                                        {this.state.isValidPromotionCode !== null &&
                                                            this.state.isValidPromotionCode && (
                                                                <span className="applied">
                                                                    {t('termsAndConditionListing.label.codeApplied')}
                                                                    <img
                                                                        className="icon"
                                                                        src={images.appliedSVG}
                                                                        alt=""
                                                                    />
                                                                </span>
                                                            )}
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                        {this.props.userData.has_saved_terms ? (
                                            <>
                                                <p>{t('termsAndConditionListing.label.reviewUpdatedCondition')}</p>
                                            </>
                                        ) : (
                                            <>
                                                <p className="text-center">
                                                    {t('termsAndConditionListing.label.termsAndConditions')}
                                                </p>
                                            </>
                                        )}

                                        {this.state.TermsConditionList.length > 0 &&
                                            this.state.TermsConditionList.map(
                                                (data: ITermsCondtionData, index: number) => {
                                                    return (
                                                        <Form.Group key={index} className="form-group">
                                                            <div className="custom-control custom-checkbox custom-control-inline">
                                                                <input
                                                                    type="checkbox"
                                                                    className="custom-control-input"
                                                                    id={`tc${data.id}`}
                                                                    name="termsCondition"
                                                                    checked={this.state.selectedTermsAndCondition.includes(
                                                                        data.id,
                                                                    )}
                                                                    onChange={() => this.handleChaeckChange(data)}
                                                                />
                                                                <label
                                                                    className="custom-control-label pb-0"
                                                                    htmlFor={`tc${data.id}`}
                                                                >
                                                                    {t(
                                                                        'termsAndConditionListing.label.iAggreeToTermsCondition',
                                                                    )}
                                                                    <span
                                                                        onClick={() =>
                                                                            this.handleModel(
                                                                                data.content,
                                                                                data.name,
                                                                                true,
                                                                                data.id,
                                                                            )
                                                                        }
                                                                        className="term-and-condition"
                                                                    >
                                                                        {`${data.name} ${data.mandatory ? '*' : ''}`}
                                                                    </span>
                                                                </label>
                                                            </div>
                                                        </Form.Group>
                                                    );
                                                },
                                            )}
                                        <p>
                                            <span className="themeTextColor">
                                                {t('termsAndConditionListing.label.note')}
                                            </span>
                                            {t('termsAndConditionListing.noteDetails')}
                                        </p>
                                        <Form.Group className="form-group">
                                            <Button onClick={this.handleSubmit} className="btn btn-primary w-100">
                                                {t('termsAndConditionListing.button.next')}
                                            </Button>
                                        </Form.Group>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Modal show={this.state.model.showModel} onHide={() => this.handleModel('', '', null, null)}>
                    <Modal.Header>
                        <Modal.Title>{this.state.model.header}</Modal.Title>
                        <button type="button" className="close" onClick={() => this.handleModel('', '', null, null)}>
                            <span aria-hidden="true">×</span>
                            <span className="sr-only">{t('Payment.Model.button.close')}</span>
                        </button>
                    </Modal.Header>
                    <Modal.Body>
                        <div
                            id="terms_condition fixModalSize"
                            dangerouslySetInnerHTML={{
                                __html: this.state.model.body,
                            }}
                        ></div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            className="btn btn-primary"
                            onClick={() => {
                                this.acceptTermsCondition(this.state.model.id);
                            }}
                            title={t('termsAndConditionListing.button.accept')}
                        >
                            <span>{t('termsAndConditionListing.button.accept')}</span>
                        </Button>
                        <Button
                            className="btn btn-secondary"
                            onClick={() => this.handleModel('', '', false, null)}
                            title={t('termsAndConditionListing.button.cancel')}
                        >
                            <span>{t('termsAndConditionListing.button.cancel')}</span>
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}
const mapStateToProps = (state: AppState) => ({
    userData: state.UserData,
});
export default connect(mapStateToProps, null)(withTranslation()(TermsAndConditionListing));
