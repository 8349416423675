import React from 'react';
import LoginForm from './LoginForm';
import { connect } from 'react-redux';
import ForgotPasswordForm from './ForgotPasswordForm';
import SignUpForm from './SignUpForm';
import ResetPasswordForm from './ResetPasswordForm';
import SignUpOTPForm from './SignUpOTPForm';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ILoginPageProps } from '../../types/propTypes';
import { ILoginPageState } from '../../types/stateTypes';
import { Common } from '../../utils/constants';
import LoginService from '../../services/login/login-service';
import { ILoginForm, IPrivacyPolicy } from '../../models/login';
import { toast } from 'react-toastify';
import {
    changeLanguage,
    logout,
    saveUserData,
    setCurrencyDetails,
    setCurrencyFormat,
    setDateTimeFormat,
    setDefaultLanguage,
    setNumberFormat,
    setSupportedLanguages,
} from '../../store/actions/app.Actions';
import UserService from '../../services/user/user-service';
import LoginAPIService from '../../services/login/loginApi-service';
import StandardResponse from '../../models/standard-response';
import { IRegionData, IUserData as IUserProfileData } from '../../models/profile';
import SelectBox from '../../components/reactstrap-formik/SelectBox';
import { store } from '../../store';
import i18next from 'i18next';
import images from '../../assets/images';
import _ from 'lodash';
import { defaultNumberFormat } from '../../components/common/LocalNumberFormat';
import { companyNameReplace } from '../../utils';
import moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/is';

let defaultStage = Common.AuthStage.SIGNIN;

const browserLang = navigator.language && {
    name: navigator.language,
    id: 0,
    is_default: false,
    code: navigator.language,
};

let defaultSelectedRegion: IRegionData = {};
const storeState = store.getState();
let defaultSelectedLang = storeState.UserData?.language ? storeState.UserData?.language : browserLang;

export class LoginPage extends React.Component<ILoginPageProps & WithTranslation, ILoginPageState> {
    loginService: LoginService;
    loginApiService: LoginAPIService;
    userService: UserService;
    parameters = new URLSearchParams(window.location.search);
    constructor(props: ILoginPageProps & WithTranslation) {
        super(props);
        this.state = {
            user: null,
            stage:
                this?.parameters?.get('stage') && this?.parameters?.get('stage') !== 'undefined'
                    ? Number(this?.parameters?.get('stage'))
                    : defaultStage,
            email: '',
            password: '',
            emailPattern: '',
            isLoginVerified: false,
            referralCode: '',
            isDriverVerified: null,
            isSignup: false,
            language: defaultSelectedLang,
            regions: [],
            supportedLanguages: [],
            selectedRegion: defaultSelectedRegion,
            formValues: {
                email:
                    this?.parameters?.get('email') && this?.parameters?.get('email') !== 'undefined'
                        ? `${this?.parameters?.get('email')}`
                        : '',
                firstName:
                    this?.parameters?.get('firstName') && this?.parameters?.get('firstName') !== 'undefined'
                        ? `${this?.parameters?.get('firstName')}`
                        : '',
                lastName:
                    this?.parameters?.get('lastName') && this?.parameters?.get('lastName') !== 'undefined'
                        ? `${this?.parameters?.get('lastName')}`
                        : '',
            },
            apiError: false,
            privacyPolicyConfig: null,
            privacyPolicy: null,
            regionDefaultLanguage: null,
        };
        this.loginService = new LoginService();
        this.userService = new UserService();
        this.loginApiService = new LoginAPIService();
    }

    colourStyles = {
        control: (base) => ({
            ...base,
            border: 0,
            boxShadow: 'none',
        }),
        option: (styles, { isFocused }) => ({
            ...styles,
            backgroundColor: isFocused ? 'var(--themeColor)' : null,
            color: isFocused ? 'white' : '#333333',
            ':active': {
                ...styles[':active'],
                backgroundColor: 'var(--themeColor)',
            },
        }),
    };

    componentWillMount() {
        if (
            this?.parameters?.get('stage') &&
            this?.parameters?.get('stage') !== 'undefined' &&
            this?.parameters?.get('stage') !== '3'
        ) {
            this.props.navigate('/login');
        }
        const urlParams = new URLSearchParams(window.location.search);
        const myReferralCode = urlParams.get('code');
        if (this.props.isSignUp) {
            this.setState({
                stage: Common.AuthStage.SIGNUP,
            });
        }
        this.setState({
            referralCode: myReferralCode,
        });
    }

    handleChangeStage = (stage: number, isDriverVerified?: boolean) => {
        const driverVerified = isDriverVerified;
        this.setState({ stage: stage, isDriverVerified: driverVerified });
        if (stage === Common.AuthStage.SIGNIN) {
            this.props.navigate('/login');
            defaultStage = Common.AuthStage.SIGNIN;
        } else if (stage === Common.AuthStage.SIGNUP) {
            this.props.navigate('/login?stage=3');
            defaultStage = Common.AuthStage.SIGNUP;
        }
    };

    onSignupLogin = () => {
        this.setState({ isSignup: true }, () => {
            this.handleDriverLogin({ email: this.state.email, password: this.state.password });
        });
    };

    handleDriverLogin = (value: ILoginForm) => {
        const downloadVATRedirect = sessionStorage.getItem('vat_receipt_path') || '';
        return this.loginService
            .login(value)
            .then(async (res: any) => {
                this.userService
                    .getProfile()
                    .then((userDetail: StandardResponse<IUserProfileData>) => {
                        if (
                            userDetail &&
                            userDetail.Data &&
                            userDetail.Data.status &&
                            userDetail.Data.status.code === Common.UserStatus.CANCELLED
                        ) {
                            this.props.dispatch(logout()).then(() => {
                                toast.error(this.props.t('login.toast.cancelled'), { autoClose: 15000 });
                            });
                        } else {
                            this.props.dispatch(saveUserData({ ...res, ...userDetail.Data }));
                            this.props.dispatch(changeLanguage(userDetail?.Data?.language_preference));
                            this.props.dispatch(setCurrencyDetails(userDetail?.Data?.default_currency));
                            if (downloadVATRedirect && !this.state.isSignup) {
                                sessionStorage.removeItem('vat_receipt_path');
                                this.props.navigate(downloadVATRedirect);
                            } else if (userDetail && userDetail.Data.terms_and_condition_required) {
                                this.props.navigate('/TermsAndCondition', {
                                    state: { referralCode: this.state.referralCode },
                                });
                            } else if (userDetail && userDetail.Data.is_profile_verified === false) {
                                this.props.navigate('/profile?redirect=true');
                            } else if (userDetail && userDetail.Data.is_profile_verified === true) {
                                let marketingConsent = localStorage.getItem(
                                    `${Common.MARKETING_CONSENT}-${userDetail.Data.id}`,
                                );
                                if (!marketingConsent) {
                                    this.props.navigate('/profile');
                                } else if (userDetail && userDetail.Data.payment_type === null) {
                                    this.props.navigate('/payment');
                                } else {
                                    this.props.navigate('/');
                                }
                            }
                        }
                        moment.locale(store.getState().UserData?.language?.code);
                    })
                    .catch(() => {
                        this.props.dispatch(logout());
                        toast.error(this.props.t('login.toast.error'));
                    });
            })
            .catch((err) => {
                if (err && err.code === Common.Authentication.NotAuthorizedException) {
                    toast.error(this.props.t('login.toast.inValid'));
                } else if (err && err.code === Common.Authentication.UserNotConfirmedException) {
                    this.loginService
                        .resendOtp(value.email)
                        .then(() => {
                            this.setState({
                                email: value.email,
                                emailPattern: value.email,
                                password: value.password,
                                isLoginVerified: true,
                                stage: Common.AuthStage.SIGNUP_OTP,
                            });
                        })
                        .catch((er) => {
                            if (er && er.code === Common.Authentication.LimitExceededException) {
                                toast.error(this.props.t('login.toast.maximumAttempt'));
                            }
                        });
                } else {
                    toast.error(this.props.t('login.toast.error'));
                }
            })
            .finally();
    };

    handleDriverReLogin = () => {
        const { email, password } = this.state;
        this.setState({ isSignup: true }, () => {
            this.handleDriverLogin({ email, password });
        });
    };

    handleLanguageDropdown = async (values) => {
        const { privacyPolicyConfig } = this.state;
        let selectedLanguagePolicy: IPrivacyPolicy | null = null;

        if (privacyPolicyConfig) {
            // Helper to find policy by language
            const getPolicyByLanguage = (languageCode) =>
                privacyPolicyConfig.find(
                    (policy) => policy.code === languageCode && policy.title && policy.privacy_policy_url, // Ensure both title and URL exist
                );

            // Get policy by selected language or fallback to English
            let regionDefaultLanguage =
                this.state.regionDefaultLanguage && this.state.regionDefaultLanguage.code
                    ? this.state.regionDefaultLanguage.code
                    : '';

            selectedLanguagePolicy = getPolicyByLanguage(values.code) || getPolicyByLanguage(regionDefaultLanguage);
        }

        this.setState(
            {
                language: values,
                privacyPolicy: selectedLanguagePolicy,
            },
            async () => {
                // Dispatch the language change and fetch regions
                this.props.dispatch(changeLanguage(values));
                await this.getRegions(values.code);
            },
        );
    };

    componentDidMount = async () => {
        try {
            (document as any).getElementById('loading').classList.remove('spinner-disable');
            if (sessionStorage.getItem('error_message')) {
                toast.error(sessionStorage.getItem('error_message'));
                sessionStorage.removeItem('error_message');
            }
            await this.getSupportedLanguages();
            await this.getRegions();
            await this.getConfiguration();
            await this.getPrivacyPolicy();
            (document as any).getElementById('loading').classList.add('spinner-disable');
        } catch (e) {
            (document as any).getElementById('loading').classList.add('spinner-disable');
        }
    };

    getPrivacyPolicy = async () => {
        try {
            const res = await this.loginService.getAPIKey({
                code: Common.ConfigurationKey.PRIVACY_POLICY,
            });

            if (res?.data) {
                const privacyPolicyConfig = res.data.find(
                    (config) => config.reference_code === Common.ConfigurationKey.PRIVACY_POLICY,
                );

                if (privacyPolicyConfig?.value) {
                    try {
                        const policies = JSON.parse(privacyPolicyConfig.value);

                        // Helper to find policy by language
                        const getPolicyByLanguage = (languageCode) =>
                            policies.find(
                                (policy) => policy.code === languageCode && policy.title && policy.privacy_policy_url, // Ensure both title and URL exist
                            );

                        let regionDefaultLanguage =
                            this.state.regionDefaultLanguage && this.state.regionDefaultLanguage.code
                                ? this.state.regionDefaultLanguage.code
                                : '';
                        // Get policy by selected language or fallback to English
                        const selectedLanguagePolicy =
                            getPolicyByLanguage(this.state.language.code) || getPolicyByLanguage(regionDefaultLanguage);

                        // Set state if valid policy is found
                        if (selectedLanguagePolicy?.privacy_policy_url && selectedLanguagePolicy.title) {
                            this.setState({
                                privacyPolicyConfig: policies,
                                privacyPolicy: selectedLanguagePolicy,
                            });
                        } else {
                            this.setState({
                                privacyPolicyConfig: policies,
                                privacyPolicy: null,
                            });
                        }
                    } catch (error) {
                        console.error('Error parsing privacy policy JSON:', error);
                    }
                } else {
                    console.error('Privacy Policy Not Found');
                }
            }
        } catch (e) {
            console.error('Error fetching privacy policy:', e);
        }
    };

    getConfiguration = async () => {
        const loginApiService = new LoginAPIService();
        try {
            await loginApiService.getRegionConfigurations().then((res) => {
                if (res?.data) {
                    this.props.dispatch(setDateTimeFormat(res?.data?.date_time_formats));
                    this.props.dispatch(setCurrencyFormat(res?.data?.currency_formats));
                    if (_.isEmpty(res?.data?.number_formats)) {
                        this.props.dispatch(setNumberFormat(defaultNumberFormat));
                    } else {
                        this.props.dispatch(setNumberFormat(res?.data?.number_formats));
                    }
                }
            });
        } catch (e: any) {
            this.props.dispatch(setDateTimeFormat({}));
            this.props.dispatch(
                setNumberFormat({
                    GENERAL_NUMBER_FORMAT: '###.###.###,##',
                    FINANCIAL_NUMBER_FORMAT: '###.###.###,####',
                    NUMBER_DECIMAL_SEPARATOR: ',',
                }),
            );
            throw e;
        }
    };

    getRegions = async (language?: string) => {
        try {
            await this.loginApiService.getRegions(language).then((res) => {
                if (res && res.data && res.data.rows) {
                    this.setState({
                        regions: res.data.rows,
                        selectedRegion:
                            res.data.rows &&
                            res.data.rows.filter((item) => item.code === process.env.REACT_APP_PORTAL_REGION)[0],
                    });
                } else {
                    this.setState({
                        regions: [],
                        selectedRegion: null,
                    });
                }
            });
        } catch (e: any) {
            this.setState({
                regions: [],
                selectedRegion: null,
            });
        }
    };

    getSupportedLanguages = async () => {
        const loginApiService = new LoginAPIService();
        try {
            await loginApiService.getSupportedLanguages().then((res) => {
                if (res && res.data && res.data.rows) {
                    const defaultLang = res.data.rows && res.data.rows.filter((item) => item.is_default)[0];
                    const validBrowserLang =
                        res.data.rows && res.data.rows.filter((item) => browserLang?.code?.includes(item.code));
                    let language;
                    if (defaultSelectedLang === browserLang) {
                        if (storeState?.LanguageInfo?.defaultLanguage?.code !== storeState?.UserData?.language?.code) {
                            this.setState(
                                {
                                    language: storeState?.LanguageInfo?.defaultLanguage
                                        ? storeState?.LanguageInfo?.defaultLanguage
                                        : defaultLang,
                                    supportedLanguages: res.data.rows,
                                    regionDefaultLanguage: defaultLang,
                                },
                                () => {
                                    language = this.state.language;
                                },
                            );
                        } else {
                            //Default language = browser lang is in supported list otherwise  is_default lang from supportedLanguages
                            // Update Default language if user change language from login/signup
                            // Lang = user selected lang ortherwise Default language
                            this.setState(
                                {
                                    language:
                                        validBrowserLang && validBrowserLang.length > 0
                                            ? validBrowserLang[0]
                                            : defaultLang,
                                    supportedLanguages: res.data.rows,
                                    regionDefaultLanguage: defaultLang,
                                },
                                () => {
                                    this.props.dispatch(setDefaultLanguage(this.state.language));
                                    language = this.state.language;
                                },
                            );
                        }
                    } else {
                        this.setState(
                            {
                                language: defaultSelectedLang,
                                supportedLanguages: res.data.rows,
                                regionDefaultLanguage: defaultLang,
                            },
                            () => {
                                this.props.dispatch(setDefaultLanguage(this.state.language));
                                language = this.state.language;
                            },
                        );
                    }
                    this.props.dispatch(setSupportedLanguages(res.data.rows));
                    if (language && i18next?.language !== language.code) {
                        this.props.dispatch(changeLanguage(language));
                    }
                    this.props.handleLanguageAndError();
                } else {
                    this.setState({
                        supportedLanguages: [],
                        language: null,
                    });
                    this.props.handleLanguageAndError();
                }
            });
        } catch (e: any) {
            this.props.handleLanguageAndError();
            this.setState({
                supportedLanguages: [],
                language: null,
                apiError: true,
            });
        }
    };

    handleChangeFormValues = (e: any) => {
        this.setState({
            formValues: {
                ...this.state.formValues,
                [e.target.name]: e.target.value,
            },
        });
    };

    handleRegionChange = (selectedRegion: IRegionData) => {
        defaultStage = this.state.stage;
        defaultSelectedRegion = selectedRegion;
        this.setState(
            {
                selectedRegion: selectedRegion,
            },
            () => {
                const { stage, formValues } = this.state;
                if (stage === Common.AuthStage.SIGNUP) {
                    window.location.href = `${selectedRegion.driver_portal_host_url}/login?stage=3&email=${formValues?.email}&firstName=${formValues?.firstName}&lastName=${formValues?.lastName}`;
                } else if (stage === Common.AuthStage.SIGNIN) {
                    window.location.href = `${selectedRegion.driver_portal_host_url}/login?stage=0&email=${formValues?.email}`;
                }
            },
        );
    };

    render() {
        return (
            <React.Fragment>
                <div className="login-page container-fluid p-0">
                    <div className="row h-100 no-gutters">
                        <div className="col-md login-background-block">
                            <a href="#" title={this.props.t('login.label.logo')} className="instavolt-logo">
                                <img src={images.logoPNG} alt={this.props.t('login.label.logo')} />
                            </a>
                        </div>
                        <div className="col-md-auto login-section">
                            <div className="login-outer">
                                {(this.state.supportedLanguages?.length > 0 || this.state.apiError) && (
                                    <div className="login-contain">
                                        {(this.state.stage === Common.AuthStage.SIGNIN ||
                                            this.state.stage === Common.AuthStage.SIGNUP) && (
                                            <div className="language-dropdown">
                                                <SelectBox
                                                    styles={this.colourStyles}
                                                    name="language"
                                                    menuPlacement="top"
                                                    class="form-control"
                                                    value={
                                                        this?.state?.language &&
                                                        this.state.supportedLanguages.length > 0
                                                            ? this?.state?.language
                                                            : ''
                                                    }
                                                    options={this.state.supportedLanguages}
                                                    onChange={(e) => {
                                                        this.handleLanguageDropdown(e);
                                                    }}
                                                    getOptionLabel={(opt) => opt.name}
                                                    getOptionValue={(opt) => opt.id}
                                                    isClearable={false}
                                                    placeholder={this.props.t('login.placeholder.language')}
                                                />
                                            </div>
                                        )}
                                        {this.state.stage === Common.AuthStage.SIGNIN && (
                                            <LoginForm
                                                t={this.props.t}
                                                onChangeStage={this.handleChangeStage}
                                                handleSubmit={this.handleDriverLogin}
                                                handleChangeFormValues={this.handleChangeFormValues}
                                                companyName={this.props.companyName}
                                            />
                                        )}
                                        {this.state.stage === Common.AuthStage.FORGOT_PASSWORD && (
                                            <ForgotPasswordForm
                                                t={this.props.t}
                                                onChangeStage={this.handleChangeStage}
                                                setEmail={(data) => this.setState({ email: data })}
                                                handleChangeFormValues={this.handleChangeFormValues}
                                                companyName={this.props.companyName}
                                            />
                                        )}
                                        {this.state.stage ===
                                            Common.AuthStage.CHANGE_PASSWORD_WITH_VERIFICATION_CODE && (
                                            <ResetPasswordForm
                                                t={this.props.t}
                                                email={this.state.email}
                                                onChangeStage={this.handleChangeStage}
                                                companyName={this.props.companyName}
                                            />
                                        )}
                                        {this.state.stage === Common.AuthStage.SIGNUP && (
                                            <SignUpForm
                                                t={this.props.t}
                                                onChangeStage={this.handleChangeStage}
                                                setEmail={(data) => this.setState({ email: data })}
                                                setPassword={(data) => this.setState({ password: data })}
                                                setEmailPattern={(data) => this.setState({ emailPattern: data })}
                                                referaalCode={this.state.referralCode}
                                                handleChangeFormValues={this.handleChangeFormValues}
                                                companyName={this.props.companyName}
                                            />
                                        )}
                                        {this.state.stage === Common.AuthStage.SIGNUP_OTP && (
                                            <SignUpOTPForm
                                                t={this.props.t}
                                                onChangeStage={this.handleChangeStage}
                                                onDriverReLogin={this.handleDriverReLogin}
                                                email={this.state.email}
                                                emailPattern={this.state.emailPattern}
                                                isLoginVerified={this.state.isLoginVerified}
                                                onSignupLogin={this.onSignupLogin}
                                                isDriverVerified={this.state.isDriverVerified}
                                                companyName={this.props.companyName}
                                            />
                                        )}
                                        {(this.state.stage === Common.AuthStage.SIGNIN ||
                                            this.state.stage === Common.AuthStage.SIGNUP) && (
                                            <div
                                                className="form-group"
                                                style={{
                                                    width: '104%',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <img width="25px" src={images.regionSVG} />
                                                <SelectBox
                                                    styles={this.colourStyles}
                                                    name="region"
                                                    menuPlacement="top"
                                                    class="form-control"
                                                    value={
                                                        this?.state?.selectedRegion ? this?.state?.selectedRegion : ''
                                                    }
                                                    options={this.state.regions}
                                                    onChange={(e) => {
                                                        this.handleRegionChange(e);
                                                    }}
                                                    getOptionLabel={(opt) => opt.name}
                                                    getOptionValue={(opt) => opt.id}
                                                    isClearable={false}
                                                    placeholder={this.props.t('login.placeholder.region')}
                                                />
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                            {this.state.privacyPolicy ? (
                                <p className="copyright">
                                    {`${companyNameReplace(this.props.t('login.label.copyright'))} | `}
                                    <a
                                        href={this.state.privacyPolicy.privacy_policy_url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="privacy-policy-link"
                                    >
                                        {this.state.privacyPolicy.title}
                                    </a>
                                </p>
                            ) : (
                                <p className="copyright">
                                    {' '}
                                    {companyNameReplace(this.props.t('login.label.copyright'))}
                                </p>
                            )}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default connect(null, null)(withTranslation()(LoginPage));
