import BaseService from 'services/base-service';
import CryptoJS from 'crypto-js';
import { Buffer } from 'buffer';
window.Buffer = Buffer;
import { Common } from '../../src/utils/constants';

export const getReferenceId = async () => {
    let reference = new Date().getFullYear().toString();
    reference += (new Date().getMonth() + 1 < 10 ? '0' : '').toString() + (new Date().getMonth() + 1).toString();
    reference += (new Date().getDate() < 10 ? '0' : '').toString() + new Date().getDate().toString();
    reference += (new Date().getHours() < 10 ? '0' : '').toString() + new Date().getHours().toString();
    reference += (new Date().getMinutes() < 10 ? '0' : '').toString() + new Date().getMinutes().toString();
    reference += (new Date().getSeconds() < 10 ? '0' : '').toString() + new Date().getSeconds().toString();

    const res = await BaseService({
        url: `/driver/detail`,
        method: 'POST',
        data: { platform_type: 'Web' },
    });
    if (res) {
        reference += '_' + res.data.data.id.toString();
    }
    return reference.toString();
};

export const getOptoReq = async (Data) => {
    const returnURL = window.location.href;
    const MerchantStoreId = Data.store_id;
    const MerchantDepartmentId = Data.department_id;
    const MerchantSignatureKeyId = process.env.REACT_APP_SIGNATURE_KEY;
    const CountryId = process.env.REACT_APP_COUNTRYID;
    const CardCollectionId = process.env.REACT_APP_CARD_COLLECTIONID;
    const optoRAW = `Reference${await getReferenceId()}|ReturnUrl${returnURL}|MerchantStoreId${MerchantStoreId}|MerchantDepartmentId${MerchantDepartmentId}|MerchantSignatureKeyId${MerchantSignatureKeyId}|CountryId${CountryId}|CardCollectionId${CardCollectionId}`;
    const optoReq = new Buffer(optoRAW).toString('base64');
    const hmac = CryptoJS.algo.HMAC.create(CryptoJS.algo.SHA256, decryptUsingIV(Data.signature_key));
    const hmacUpdate = hmac.update(optoRAW);
    const optomac = CryptoJS.enc.Base64.stringify(hmacUpdate.finalize());
    return { optoReq: optoReq, optomac: optomac };
};

export const CheckCard = (result) => {
    switch (result) {
        case Common.PaymentCardType.VISA.toLowerCase():
            return {
                cardImage: Common.PaymentCardTypeImage.VISA,
                type: Common.PaymentCardType.VISA,
            };
        case Common.PaymentCardType.AMEX.toLowerCase():
            return {
                cardImage: Common.PaymentCardTypeImage.AMEX,
                type: Common.PaymentCardType.AMEX,
            };
        case Common.PaymentCardType.DINERS_CLUB_CARTE_BLANCHE.toLowerCase():
            return {
                cardImage: Common.PaymentCardTypeImage.DINERS_CLUB_CARTE_BLANCHE,
                type: Common.PaymentCardType.DINERS_CLUB_CARTE_BLANCHE,
            };
        case Common.PaymentCardType.DINERS_CLUB_INTERNATIONAL.toLowerCase():
            return {
                cardImage: Common.PaymentCardTypeImage.DINERS_CLUB_INTERNATIONAL,
                type: Common.PaymentCardType.DINERS_CLUB_INTERNATIONAL,
            };
        case Common.PaymentCardType.JCB.toLowerCase():
            return {
                cardImage: Common.PaymentCardTypeImage.JCB,
                type: Common.PaymentCardType.JCB,
            };
        case Common.PaymentCardType.LASER.toLowerCase():
            return {
                cardImage: Common.PaymentCardTypeImage.LASER,
                type: Common.PaymentCardType.LASER,
            };
        case Common.PaymentCardType.VISA_ELECTRON.toLowerCase():
            return {
                cardImage: Common.PaymentCardTypeImage.VISA_ELECTRON,
                type: Common.PaymentCardType.VISA_ELECTRON,
            };
        case Common.PaymentCardType.MASTERCARD.toLowerCase():
            return {
                cardImage: Common.PaymentCardTypeImage.MASTERCARD,
                type: Common.PaymentCardType.MASTERCARD,
            };
        case Common.PaymentCardType.DISCOVER.toLowerCase():
            return {
                cardImage: Common.PaymentCardTypeImage.DISCOVER,
                type: Common.PaymentCardType.DISCOVER,
            };
        case Common.PaymentCardType.DANKORT.toLowerCase():
            return {
                cardImage: Common.PaymentCardTypeImage.DANKORT,
                type: Common.PaymentCardType.DANKORT,
            };
        case Common.PaymentCardType.MAESTRO.toLowerCase():
            return {
                cardImage: Common.PaymentCardTypeImage.MAESTRO,
                type: Common.PaymentCardType.MAESTRO,
            };
        case Common.PaymentCardType.UATP.toLowerCase():
            return {
                cardImage: Common.PaymentCardTypeImage.UATP,
                type: Common.PaymentCardType.UATP,
            };
        default:
            return {
                cardImage: '',
                type: '',
            };
    }
};
export const getRoundupCharityByDonationValue = (actual_amount: number, donation_amount: number): number => {
    const fixedActualAmount = actual_amount.toFixed(2);
    const roundUpActualAmount = parseFloat(fixedActualAmount.split('.')[1]);
    const fixedDonationAmount = donation_amount.toFixed(2);
    const roundUpDonationAmount = parseFloat(fixedDonationAmount.split('.')[1]);
    if (!actual_amount) return 0;
    if (!roundUpActualAmount) return 0;
    if (!donation_amount) return 0;
    let finalRoundUpValue = '';
    if (roundUpActualAmount <= roundUpDonationAmount) {
        finalRoundUpValue = (roundUpDonationAmount - roundUpActualAmount).toString();
    } else {
        finalRoundUpValue = (100 - roundUpActualAmount).toString();
    }
    return parseFloat(`0.${finalRoundUpValue.padStart(2, '0')}`);
};
export const decryptUsingIV = (encrypted: string): string => {
    const key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_IV_ENCRYPTION_KEY);
    const iv = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_IV_INIT_VECTOR);
    const ctx = CryptoJS.enc.Hex.parse(encrypted);
    let enc = CryptoJS.lib.CipherParams.create({ ciphertext: ctx });
    let decrypted = CryptoJS.AES.decrypt(enc, key, { iv: iv });
    decrypted = CryptoJS.enc.Utf8.stringify(decrypted);
    return decrypted;
};

export const getDNAPaymentScript = (isTestMode: boolean): string => {
    return isTestMode
        ? 'https://test-pay.dnapayments.com/checkout/payment-api.js'
        : 'https://pay.dnapayments.com/checkout/payment-api.js';
};

export const getDNAPaymentScriptIntegrity = (isTestMode: boolean): string => {
    return isTestMode
        ? 'sha256-z27fIyoBGu/jlBtWNBta9k6bAfpIqeTuEVxceOXV53k='
        : 'sha256-A3y2uxmrya0WIZUTucuGnBjtT4PZ2+joczNt+ry+JOM=';
};

export const getDATATRANSPaymentScript = (isTestMode: boolean): string => {
    return isTestMode ? 'https://pay.sandbox.datatrans.com/v1/start' : 'https://pay.datatrans.com/v1/start';
};
