import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { IFAQPageProps } from '../../types/propTypes';
import { Card, Accordion, useAccordionButton } from 'react-bootstrap';
import { IFAQState } from '../../types/stateTypes';
import { store } from '../../store';
import { toast } from 'react-toastify';
import UserService from '../../services/user/user-service';

export class FleetFAQPage extends Component<IFAQPageProps & WithTranslation, IFAQState> {
    state: IFAQState;
    userService: UserService;
    constructor(props) {
        super(props);
        this.state = {
            activeKey: '0',
            faqArray: [],
        };
        this.userService = new UserService();
    }
    async componentDidMount() {
        const queryStringLang = new URLSearchParams(window.location.search);
        const languageInfo = store?.getState()?.LanguageInfo?.supportedLanguages;
        const changelanguage = languageInfo
            ? languageInfo?.filter(
                  (item) =>
                      item?.code === queryStringLang.get('langCode') || item?.code === queryStringLang.get('language'),
              )[0]
            : { code: queryStringLang.get('langCode') || queryStringLang.get('language') };
        let regionCode = process.env.REACT_APP_PORTAL_REGION?.toLocaleLowerCase();
        let languageCode;
        if (queryStringLang.get('langCode') || queryStringLang.get('language')) {
            languageCode = changelanguage?.code;
        } else {
            if (store.getState()?.UserData) {
                languageCode = store.getState()?.UserData?.language?.code;
            } else if (store.getState()?.LanguageInfo) {
                languageCode = store.getState()?.LanguageInfo?.defaultLanguage?.code;
            }
        }
        let jsonFile = require(`../../assets/fleetFaq/uk/en.json`);
        if (regionCode && languageCode) {
            jsonFile = require(`../../assets/fleetFaq/${regionCode}/${languageCode}.json`);
        }
        let brandConfig = await this.getConfigDataBrandName('COMPANY_BRAND_NAME');
        let brandname = '';
        if (brandConfig.length > 0) {
            brandname = brandConfig[0].value ? brandConfig[0].value : '';
        }
        let faqs = JSON.stringify(jsonFile?.faq);
        const changeValue = store?.getState()?.configuration?.company_name || 'Instavolt';
        faqs = faqs.split('##COMPANY_BRAND_NAME##').join(brandname || changeValue);
        const faqArray = JSON.parse(faqs);

        this.setState({
            ...this.state,
            faqArray: faqArray,
        });
    }
    setActiveKey = (key: number) => {
        const activeKey = this.state.activeKey === key.toString() ? '' : key.toString();
        this.setState({ activeKey: activeKey });
    };
    getConfigDataBrandName = async (code: string) => {
        let configData = [];
        await this.userService
            .getConfigurationDetail({ code })
            .then(async (res) => {
                configData = res.data;
            })
            .catch((m) => {
                toast.error(m.toString());
            });
        return configData;
    };

    CustomToggle = ({ children, eventKey }) => {
        const decoratedOnClick = useAccordionButton(eventKey, () => this.setActiveKey(eventKey));

        return (
            <span
                className={this.state.activeKey === eventKey.toString() ? 'faq-card-title' : 'faq-card-title collapsed'}
                onClick={decoratedOnClick}
            >
                {children}
            </span>
        );
    };

    render() {
        return (
            <>
                <div className="container-fluid" id="faq-wrapper">
                    <Accordion defaultActiveKey="0" className="faqs-outer p-3">
                        {this.state.faqArray &&
                            this.state.faqArray.map((faq, index) => {
                                const answerList = faq.answer.split('\n');
                                return (
                                    <Card key={index}>
                                        <Card.Header>
                                            <this.CustomToggle eventKey={index.toString()}>
                                                {faq.question}
                                            </this.CustomToggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey={index.toString()}>
                                            <Card.Body>
                                                {answerList.map((data, index) => {
                                                    return (
                                                        <>
                                                            {data}{' '}
                                                            {index === answerList.length - 1 ? (
                                                                <></>
                                                            ) : (
                                                                <>
                                                                    <br />
                                                                    <br />
                                                                </>
                                                            )}
                                                        </>
                                                    );
                                                })}
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                );
                            })}
                    </Accordion>
                </div>
            </>
        );
    }
}

export default withTranslation()(FleetFAQPage);
