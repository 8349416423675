import React from 'react';
import images from './assets/images';

export const ImagesVariable = () => {
    return (
        <>
            <style>
                {`
                    :root {
                        --customSelectArrowSVG: url(${images.customSelectArrowSVG});
                        --unlockSVG: url(${images.unlockSVG});
                        --lockSVG: url(${images.lockSVG});
                        --checkSVG: url(${images.checkSVG});
                        --innerPageBgPNG: url(${images.innerPageBgPNG});
                        --creditCardBgPNG: url(${images.creditCardBgPNG});
                        --currentBalanceBgPNG: url(${images.currentBalanceBgPNG});
                        --sortingSVG: url(${images.sortingSVG});
                        --srotingAscSVG: url(${images.srotingAscSVG});
                        --sortingDescSVG: url(${images.sortingDescSVG});
                        --downSVG: url(${images.downSVG});
                        --loginBackgroundJPG: url(${images.loginBackgroundJPG});
                        --mdLoginBackgroundJPG: url(${images.mdLoginBackgroundJPG});
                        --SuccessIconSVG: url(${images.SuccessIconSVG});
                        --warningIconSVG: url(${images.warningIconSVG});
                        --errorIconSVG: url(${images.errorIconSVG});
                        --infoIconSVG: url(${images.infoIconSVG});
                    }
                `}
            </style>
        </>
    );
};
