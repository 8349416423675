import React from 'react';
import { Auth } from 'aws-amplify';
import { connect } from 'react-redux';
import images from '../../assets/images';

const SocialMediaLoginForm = (props) => {
    const googleLogin = () => {
        localStorage.setItem('is_federate', 'true');
        Auth.federatedSignIn({ provider: 'Google' });
    };

    const appleLogin = () => {
        localStorage.setItem('is_federate', 'true');
        Auth.federatedSignIn({ provider: 'SignInWithApple' });
    };

    const facebookLogin = () => {
        localStorage.setItem('is_federate', 'true');
        Auth.federatedSignIn({ provider: 'Facebook' });
    };
    return (
        <div className="pt-3  pb-3">
            <p className="login-link text-center">
                ------------------------------------ {props.t('route.or')} -----------------------------------
            </p>
            <div className="social-media form-row justify-content-center">
                <div className="col-12">
                    <a
                        href="#"
                        title={props.t('login.label.google')}
                        onClick={googleLogin}
                        className="google-btn d-flex align-items-center justify-content-center mb-2"
                    >
                        <img src={images.googleLogoPNG} alt="" />
                        {props.t('login.label.google')}
                    </a>
                </div>
                <div className="col-12">
                    <a
                        href="#"
                        title={props.t('login.label.facebook')}
                        onClick={facebookLogin}
                        className="facebook-btn d-flex align-items-center justify-content-center mb-2"
                    >
                        <img src={images.facebookLogoSVG} alt="" />
                        {props.t('login.label.facebook')}
                    </a>
                </div>
                <div className="col-12">
                    <a
                        href="#"
                        title={props.t('login.label.apple')}
                        onClick={appleLogin}
                        className="ios-btn d-flex align-items-center justify-content-center mb-2"
                    >
                        <img src={images.iosSVG} alt="" />
                        {props.t('login.label.apple')}
                    </a>
                </div>
            </div>
        </div>
    );
};

export default connect(null, null)(SocialMediaLoginForm);
