import React from 'react';
import { Helmet } from 'react-helmet';
import { IForgotPasswordProps } from '../../types/propTypes';
import { IForgotPasswordState } from '../../types/stateTypes';
import LoginService from './../../services/login/login-service';
import LoginAPIService from './../../services/login/loginApi-service';
import { IForgotPasswordFormField } from '../../models/login';
import { Formik, Field } from 'formik';
import { Form, Button } from 'react-bootstrap';
import { ReactstrapInput } from '../../components/reactstrap-formik';
import { ForgotPasswordSchema } from '../../validation/login/login-validation';
import { Common } from '../../utils/constants';
import { toast } from 'react-toastify';
import StandardResponse from '../../models/standard-response';
import images from '../../assets/images';
import _ from 'lodash';
import { companyNameReplace } from '../../utils';

class ForgotPasswordForm extends React.Component<IForgotPasswordProps, IForgotPasswordState> {
    parameters = new URLSearchParams(window.location.search);
    private initialState: IForgotPasswordFormField = {
        email:
            this?.parameters?.get('email') && this?.parameters?.get('email') !== 'undefined'
                ? `${this?.parameters?.get('email')}`
                : '',
    };
    state: IForgotPasswordState;
    loginService: LoginService;
    loginAPIService: LoginAPIService;
    constructor(props: IForgotPasswordProps) {
        super(props);
        this.loginService = new LoginService();
        this.loginAPIService = new LoginAPIService();
        this.state = {
            supportContact: '',
            Email: '',
        };
    }

    async componentDidMount() {
        const contactUsConfig = await this.getConfigData(Common.ConfigurationKey.CONTACT_US_PHONE);
        if (contactUsConfig.length > 0) {
            let supportContact = contactUsConfig[0].value
                ? contactUsConfig[0].value
                : this.props.t('forgot.supportNumber');
            this.setState({ supportContact: supportContact });
        }
    }

    getConfigData = async (code: string) => {
        let configData = [];
        await this.loginService
            .getAPIKey({ code })
            .then(async (res) => {
                configData = res.data;
            })
            .catch((m) => {
                toast.error(m.string());
            });
        return configData;
    };
    checkDriverIsVerified = async (email: string) => {
        let isVerified = true;
        await this.loginAPIService
            .checkDriverIsVerified({ email })
            .then(async (res: StandardResponse<any>) => {
                isVerified = res.Data.is_verified;
            })
            .catch((e) => {
                toast.error(e.message);
            })
            .finally();
        return isVerified;
    };
    handleSubmit = async (value: IForgotPasswordFormField) => {
        const isVerified = await this.checkDriverIsVerified(value.email.toLowerCase());
        this.props.setEmail(value.email.toLowerCase());
        if (isVerified) {
            return this.loginService
                .forgotPassword(value)
                .then(async () => {
                    this.props.onChangeStage(Common.AuthStage.CHANGE_PASSWORD_WITH_VERIFICATION_CODE);
                })
                .catch(() => {
                    toast.error(this.props.t('forgot.toast.error'));
                })
                .finally();
        } else {
            this.props.onChangeStage(Common.AuthStage.SIGNUP_OTP, false);
        }
    };

    render() {
        const { t, companyName } = this.props;
        return (
            <React.Fragment>
                <Helmet>
                    <title>{companyNameReplace(t('forgot.title'))}</title>
                </Helmet>
                <button
                    type="button"
                    className="btn btn-icon btn-link pr-0 btn-back"
                    onClick={() => this.props.onChangeStage(Common.AuthStage.SIGNIN)}
                    title={t('forgot.button.back')}
                    data-testid="backButton"
                >
                    <img className="icon" src={images.backArrowRSVG} alt={t('forgot.button.back')} />
                </button>
                <div className="title-outer">
                    <h1 className="title">
                        <img src={images.logoIconPNG} alt={companyNameReplace(t('login.label.logo'))} />
                        {t('forgot.header')}
                    </h1>
                    <p>{t('forgot.subHeader')}</p>
                </div>
                <Formik
                    initialValues={this.initialState}
                    onSubmit={this.handleSubmit}
                    validationSchema={ForgotPasswordSchema(t)}
                >
                    {(props) => {
                        const { handleSubmit } = props;
                        return (
                            <form className="form-secondary" onSubmit={handleSubmit} autoComplete="off" noValidate>
                                <Field
                                    type="text"
                                    name="email"
                                    id="Email"
                                    label={t('forgot.form.email')}
                                    component={ReactstrapInput}
                                    maxLength={50}
                                    onKeyUp={(e) => {
                                        this.props.handleChangeFormValues(e);
                                    }}
                                />
                                <div className="alert-primary mb-4">
                                    <span>{companyNameReplace(t('forgot.form.message'))}</span>
                                    <span>{this.state.supportContact}</span>
                                </div>
                                <Form.Group className="form-group">
                                    <Button type="submit" className="btn btn-primary w-100">
                                        {t('forgot.button.submit')}
                                    </Button>
                                </Form.Group>
                            </form>
                        );
                    }}
                </Formik>
            </React.Fragment>
        );
    }
}
export default ForgotPasswordForm;
