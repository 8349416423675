export default {
    route: {
        dashboard: 'Deais',
        article: 'Ailt',
        stationTitle: 'Sonraí Stáisiúin : ##COMPANY_BRAND_NAME## - Táillí EV Mear RA',
        or: 'NÓ',
    },
    month: {
        jan: 'Ean',
        feb: 'Fea',
        mar: 'Mar',
        apr: 'Aib',
        may: 'Bea',
        jun: 'Mei',
        jul: 'Iúi',
        aug: 'Lún',
        sep: 'Meá',
        oct: 'Dei',
        nov: 'Sam',
        dec: 'Nol',
    },
    error: {
        accessDenied: 'Rochtain Diúltaithe',
        somethingWrong: 'Chuaigh rud eigin mícheart!',
        notFound: 'Ní bhfuarthas an leathanach',
    },
    navbar: {
        title: '##COMPANY_BRAND_NAME##',
        logOut: 'Logáil Amach',
        myProfile: 'Mo phróifíl',
        termsConditions: 'Téarmaí',
        menu: {
            dashboard: 'Deais',
            paymentConfiguration: 'Cumraíocht Íocaíochta',
            chargingPaymentHistory: 'Muirearú',
            rewards: 'luach saothair',
            rfidCards: 'Cártaí RFID',
        },
    },
    login: {
        title: 'Logáil isteach - ##COMPANY_BRAND_NAME## : Muirearú Mear EV RA',
        header: 'Fáilte go',
        form: {
            email: 'Aitheantas ríomhphoist',
            password: 'Pasfhocal',
        },
        placeholder: {
            region: 'Roghnaigh Réigiún',
            language: 'Roghnaigh Teanga',
        },
        button: {
            submit: 'Logáil isteach',
            forgotPassword: 'Dearmad ar pasfhocal?',
            signup: 'Clarú',
            signin: 'Sínigh isteach',
        },
        label: {
            logo: '##COMPANY_BRAND_NAME##',
            thirdPartyLogin: 'Nó logáil isteach le',
            rememberMe: 'Cuimhnigh orm',
            newAccount: 'Nach bhfuil cuntas agat?',
            facebook: 'Sínigh isteach le Facebook',
            google: 'Sínigh isteach le Google',
            apple: 'Sínigh isteach le Apple',
            copyright: 'Cóipcheart ##COMPANY_BRAND_NAME## Teo',
        },
        toast: {
            success: 'Tiománaí logáil isteach go rathúil.',
            inValid: 'Cuir isteach an ríomhphost agus an pasfhocal ceart.',
            error: "Tharla earráid agus d'iarratas á phróiseáil, bain triail eile as ar ball.",
            cancelled: 'Tá do chuntas á chealú faoi láthair mar sin ní bheidh tú in ann aon ghníomh(anna) a dhéanamh.',
            maximumAttempt: 'Tá an t-uaslíon iarrachtaí sroichte agat. ',
            reminderMessage:
                'Cuir cárta íocaíochta i do chuntas creidmheasa nó cuir airgead le do chuntas réamhíocaíochta chun an íocaíocht a bhaineann leis an gcód promóisin seo a ghníomhachtú.',
        },
        errors: {
            inValidEmail: 'Tá an seoladh ríomhphoist neamhbhailí.',
        },
    },
    forgot: {
        title: 'Dearmad ar pasfhocal - ##COMPANY_BRAND_NAME## : Muirearú Mear EV RA',
        resetTitle: 'Athshocraigh do Phasfhocal - ##COMPANY_BRAND_NAME## : Muirearú Mear EV RA',
        header: 'Ar rinne tú dearmad ar do Phásfhocail?',
        subHeader:
            'Cuir isteach do ríomhphost thíos agus seolfaimid teachtaireacht chugat chun do phasfhocal a athshocrú',
        resetHeader: 'Athshocraigh do phasfhocal.',
        resetSubHeader: 'Cuir isteach an cód a sheolamar chugat agus athshocraigh do phasfhocal.',
        supportNumber: 'Ár n-uimhir tacaíochta',
        button: {
            back: 'Ar ais',
            submit: 'Athshocraigh Mo Phasfhocal',
            save: 'Sábháil Pasfhocal Nua',
        },
        form: {
            email: 'Ríomhphost',
            code: 'Cód',
            password: 'Pasfhocal',
            confirmPassword: 'Athdhearbhaigh Pasfhocal',
            message:
                'Má tá cuntas bailí agat ba cheart go bhfaighfeá cód trí ríomhphost.  ##COMPANY_BRAND_NAME## cuntas le cuntas cónaidhmeach e.g. ',
        },
        toast: {
            success: 'Athraíodh do phasfhocal go rathúil.',
            error: "Tharla earráid agus d'iarratas á phróiseáil, bain triail eile as ar ball.",
            inValidEmailAddress: 'Tá an seoladh ríomhphoist neamhbhailí.',
            invalidCode: 'Úps! ',
        },
        errors: {
            passwordFormat:
                'Caithfidh pasfhocal a bheith 10 gcarachtar, le ar a laghad litir chás uachtair amháin, litir chás íochtair, carachtar speisialta agus uimhir.',
            confirmPasswordMatch: 'caithfidh an pasfhocal deimhnithe teacht le pasfhocal nua a chuir tú isteach.',
            verificationCodeFormat: 'Cuir isteach cód fíorúcháin 6 dhigit.',
        },
    },
    dashboard: {
        title: 'Deais - ##COMPANY_BRAND_NAME## : Muirearú Mear EV RA',
        header: 'Deais',
        premiumSubscription: '##COMPANY_BRAND_NAME## soláthraíonn sé seirbhís préimhe. ',
    },
    permission: {
        createArticle: 'Cuir Airteagal leis',
        editArticle: 'Cuir Airteagal in eagar',
        save: 'Sábháil',
        confirm: 'An bhfuil tú cinnte?',
        cancel: 'Cealaigh',
        update: 'Nuashonrú',
    },
    profile: {
        title: 'Próifíl - ##COMPANY_BRAND_NAME## : Muirearú Mear EV RA',
        header: 'Próifíl',
        addresses: 'Seoltaí',
        personalDetails: {
            header: 'Sonraí pearsanta',
            label: {
                firstName: 'Ainm',
                lastName: 'Sloinne',
                email: 'Aitheantas ríomhphoist',
                address: 'Seoladh',
                billingAddress: 'Seoladh billeála',
                postcode: 'Cód Poist',
                samePersonalAddress: 'Mar an gcéanna le seoladh pearsanta',
            },
            placeholder: {
                firstName: 'Ainm',
                lastName: 'Sloinne',
                email: 'Aitheantas ríomhphoist',
                address: 'Cuir isteach do sheoladh',
                billingAddress: 'Seoladh billeála',
                postcode: 'Cód Poist',
                searchAddress: 'Tosaigh ag clóscríobh do sheoladh chun cuardach a dhéanamh',
            },
            button: {
                save: 'Sábháil',
                cancel: 'Cealaigh',
                yes: 'Tá',
                no: 'Níl',
            },
            errors: {
                email: {
                    email: 'Tá an seoladh ríomhphoist neamhbhailí.',
                },
                first_name: {
                    matches: 'Tá an chéad ainm neamhbhailí.',
                },
                last_name: {
                    matches: 'Tá sloinne neamhbhailí.',
                },
                address: {
                    address: 'íosmhéid ##MIN_LENGTH## carachtar ag teastáil',
                    invalid: 'Tá an seoladh neamhbhailí',
                    minLine: "Ní shásaíonn an seoladh a d'iontráil tú na línte seolta íosta chun a bheith bailí.",
                },
                billing_Address: {
                    billing_Address: 'íosmhéid ##MIN_LENGTH## carachtar ag teastáil',
                    invalid: 'Seoladh Billeála neamhbhailí',
                    minLine: "Ní shásaíonn an seoladh a d'iontráil tú na línte seolta íosta chun a bheith bailí.",
                },
            },
            model: {
                header: 'Aimsigh Seoladh',
                address: 'Cineál Seoladh',
                suggested_address: 'Seoltaí Molta',
                no_data_found: 'Níor aimsíodh aon seoltaí',
                no_address_found: 'Níor aimsíodh aon seoladh.',
                button: {
                    ok: 'ceart go leor',
                    cancel: 'Cealaigh',
                    close: 'Dún',
                },
                toast: {
                    invalidAddress: 'Tá Cód Poist nó Seoladh neamhbhailí curtha isteach.',
                },
            },
        },
        addressDetails: {
            header: 'Seoladh',
            label: {
                address1: 'Seoladh',
                address2: 'Líne Seoladh 2',
                city: 'Cathair',
                county: 'Contae',
                paymentPostcode: 'Cód Poist',
            },
            placeholder: {
                address1: 'Seoladh',
                address2: 'Líne Seoladh 2',
                city: 'Cathair',
                county: 'Contae',
            },
        },
        billingAddressDetails: {
            header: 'Seoladh billeála',
            label: {
                billingaddress1: 'Seoladh',
                billingaddress2: 'Líne Seoladh 2',
                billingcity: 'Cathair',
                billingcounty: 'Contae',
                samePersonalAddress: 'Mar an gcéanna le seoladh pearsanta',
            },
            placeholder: {
                billingaddress1: 'Seoladh',
                billingaddress2: 'Líne Seoladh 2',
                billingcity: 'Cathair',
                billingcounty: 'Contae',
            },
        },
        marketingPreferenceDetails: {
            header: 'Roghanna Margaíochta',
            label: {
                receivePreference: 'Faigh cumarsáid margaíochta ó ##COMPANY_BRAND_NAME##',
                yes: 'Tá',
                no: 'Níl',
            },
            message: 'Ar mhaith leat cumarsáid margaíochta a fháil ó ##COMPANY_BRAND_NAME##?',
        },
        langPreference: 'Rogha Teanga',
        changePassword: {
            header: 'Pasfhocal',
            toast: {
                success: 'Athraíodh do phasfhocal go rathúil.',
                error: 'Úps! ',
                commonError: "Tharla earráid agus d'iarratas á phróiseáil, bain triail eile as ar ball.",
            },
            button: {
                update: 'Nuashonrú',
            },
            label: {
                current_password: 'Pasfhocal reatha',
                new_password: 'Focal Faire Nua',
                confirm_password: 'Athdhearbhaigh Pasfhocal',
            },
            placeholder: {
                current_password: 'Pasfhocal reatha',
                new_password: 'Focal Faire Nua',
                confirm_password: 'Athdhearbhaigh Pasfhocal',
            },
            errors: {
                passwordFormat:
                    'Caithfidh pasfhocal a bheith 10 gcarachtar, le ar a laghad litir chás uachtair amháin, litir bheag, carachtar speisialta agus uimhir amháin.',
                confirmPasswordMatch:
                    'Deimhnigh caithfidh an pasfhocal a bheith ag teacht le pasfhocal nua a chuir tú isteach.',
            },
        },
        cancelAccount: {
            header: 'Scrios Cuntas',
            note1: 'Is féidir leat do chuntas a scriosadh am ar bith. ##COMPANY_BRAND_NAME## bainfidh tú do chuid sonraí go léir ónár gcóras.',
            note2: 'Chun do chuntas a scriosadh.',
            clickHere: 'Cliceáil anseo',
            toast: {
                success: 'Tá brón orm a fheiceann tú ag dul. ',
                failed: "Tharla earráid agus d'iarratas á phróiseáil, bain triail eile as ar ball.",
            },
            model: {
                title: 'Scrios Cuntas',
                body: 'Má scriosann tú do chuntas bainfear do shonraí go léir go buan dár gcóras. ',
                button: {
                    yes: 'Tá',
                    no: 'Níl',
                },
            },
        },
        limitExceedAddressError: 'Ní féidir an seoladh a aimsiú faoi láthair',
    },
    signup: {
        header: 'Cruthaigh do chuntas le',
        title: 'Clarú - ##COMPANY_BRAND_NAME## : Muirearú Mear EV RA',
        form: {
            firstName: 'Ainm',
            lastName: 'Sloinne',
            email: 'Ríomhphost',
            password: 'Pasfhocal',
            referralCode: 'Cód atreoraithe',
            code: 'Cód',
            terms: 'Aontaím le',
            condition: 'Téarmaí',
        },
        button: {
            submit: 'Clarú',
            signin: 'Sínigh isteach',
            login: 'Logáil isteach',
            back: 'Ar ais',
            submitOtp: 'Cuir isteach',
            resend: 'Athsheol an Cód?',
        },
        label: {
            continueWith: 'Nó ar aghaidh le',
            accountNew: 'An bhfuil cuntas agat cheana féin?',
            otpConfirmationTitle1: 'Níl le déanamh againn ach ríomhphost a sheoladh chugat',
            otpConfirmationTitle2: 'Chun logáil isteach cuir isteach an cód a seoladh chuig',
            otpNonVerifiedEmailNote1: 'Níl do chuntas fíoraithe fós. ',
            otpNonVerifiedEmailNote2: 'chun dul ar aghaidh níos faide.',
            resendTitle: 'Nach bhfuair tú ríomhphost?',
            resend: 'Seol ar ais',
            email: 'Ríomhphost',
            instavolt: '##COMPANY_BRAND_NAME##',
            modalMessage: '##EMAIL## cláraithe cheana féin an ##COMPANY_BRAND_NAME## cuntas via ##FEDERATEDACCOUNT##. ',
            instavoltUserAccount: '##COMPANY_BRAND_NAME## Cuntas Úsáideora',
            alreadyRegisteredWithNormalAcc:
                '##EMAIL## cláraithe cheana féin an ##COMPANY_BRAND_NAME## cuntas via ##COMPANY_BRAND_NAME## Cuntas Úsáideora. ',
        },
        toast: {
            success: "D'éirigh leis an gcuntas tiománaithe a chruthú.",
            alreadyExist: 'Tá an seoladh ríomhphoist a chuir tú isteach ann cheana.',
            error: "Tharla earráid agus d'iarratas á phróiseáil, bain triail eile as ar ball.",
            confirmationSuccess: "D'éirigh le fíorú cuntais.",
            invalidCode: 'Úps! ',
            resendSentOTP: 'Seoladh an cód OTP go rathúil.',
            resendOTPErr: 'Níor seoladh an cód OTP go rathúil. ',
            referralCodeInvalid: 'Tá an cód atreoraithe neamhbhailí.',
            resendSignupCode:
                'Hey, Táimid díreach tar éis cód eile a sheoladh chugat, Seiceáil do r-phost le do thoil.',
            resendLimitExceeded: 'Tá uasiarracht bainte amach agat. ',
        },
        errors: {
            recaptchaCode: 'Fíoraigh cód recaptcha.',
            passwordFormat:
                'Caithfidh pasfhocal a bheith 10 gcarachtar, le ar a laghad litir chás uachtair amháin, litir bheag, carachtar speisialta agus uimhir amháin.',
            securityCode: 'Caithfidh an cód slándála a bheith 6 dhigit ar a laghad',
            firstName: 'Tá an chéad ainm neamhbhailí.',
            lastName: 'Tá sloinne neamhbhailí.',
            termsAndCondition: 'Ní mór duit na Téarmaí a léamh agus aontú leo',
        },
    },
    Payment: {
        Title: 'Cumraíocht Íocaíochta - ##COMPANY_BRAND_NAME## : Muirearú Mear EV RA',
        ChargeUnit: '(kWh)',
        description: 'Íocaíocht le ##COMPANY_BRAND_NAME##',
        PaymentType: {
            Title: 'Cumraíocht Íocaíochta',
            SubTitle: 'Roghnaigh an rogha íocaíochta',
            Message: {
                selectPaymentOption: 'Roghnaigh an rogha íocaíochta.',
            },
            Inputs: {
                creditAccount: {
                    label: 'Cuntas Creidmheasa',
                    label1: 'Íoc mar a ghearrann tú',
                },
                paymentAccount: {
                    label: 'Cuntas Réamhíocaíochta',
                    label1: 'Íoc roimh ré agus bain úsáid as creidmheas cuntais chun táille a ghearradh',
                },
            },
            Buttons: {
                Next: {
                    text: 'Ar aghaidh',
                },
            },
        },
        CreditPayment: {
            Title: 'Cumraíocht Íocaíochta',
            SubTitle: 'Cuntas Creidmheasa',
            note: 'Tabhair faoi deara le do thoil agus cárta íocaíochta á chur leis beidh do chuntas chun dochair ##CURRENCY## a chuirfear chun sochair do ##COMPANY_BRAND_NAME## cothromaíocht sparán.',
            donateMessage: {
                first: 'Sea, bhronnadh',
                second: 'Chun íocaíocht a chothromú le',
            },
            donationNote:
                "Síntiús 'Babhta Suas' go dtí an 50 pingin nó 'Breisiú' is gaire le méid seasta do gach idirbheart íocaíochta.",
            Inputs: {
                CardTitle: {
                    label: 'Ainm ar Chárta',
                    placeHolder: 'Ainm ar chárta',
                    required: 'Teastaíonn teideal an chárta',
                },
                CardNumber: {
                    label: 'Uimhir chárta',
                    placeHolder: '1234 1234 1234 1234',
                    invalid: 'Uimhir chárta neamhbhailí',
                    required: 'Tá uimhir chárta ag teastáil',
                    maxlimit: 'Caithfidh Uimhir Chárta a bheith 20 digit ar a mhéad',
                },
                Expiry: {
                    label: 'Éaga',
                    required: 'Tá gá le mí éaga',
                },
                Year: {
                    label: 'Bliain',
                    required: 'Tá gá le bliain éaga',
                },
                CSC: {
                    label: 'CSC',
                    label1: '(Cód Slándála Cárta)',
                    required: 'Tá Cód Slándála Cárta ag teastáil',
                    placeHolder: 'CSC',
                    maxlimit: 'Cuir isteach cód 3 dhigit ar a laghad.',
                },
                Donation: {
                    label: 'Sea, ba mhaith liom a bhronnadh!',
                },
                giftaid: {
                    label: 'Cumasaigh Bronntanas Cúnaimh?',
                },
                RoundUp: {
                    label: 'Bhabhta suas',
                },
                TopUp: {
                    label: 'Barr Suas',
                },
            },
            Buttons: {
                Save: {
                    text: 'Sábháil',
                },
                Back: {
                    text: 'Téigh go Cuntas Réamhíocaíochta',
                },
                editBack: {
                    text: 'Ar ais',
                },
            },
            Card: {
                expire: 'In éag',
                add: 'Cuir Cárta leis',
                charity: 'Síntiús Carthanachta',
                edit: 'Cuir Socruithe Tabhartais in Eagar',
                editCard: 'Cuir Cárta in Eagar',
                delete: 'Scrios',
                replace: 'Ionadaigh',
                balance: 'Iarmheid reatha:',
                Outbalance: 'Iarmhéid gan íoc:',
                mmYY: 'MM/BB',
                mm: 'MM',
                yyyy: 'BBBB',
                Pay: {
                    text: 'Íoc anois',
                },
                Withdraw: {
                    text: 'Cistí a Tharraingt siar',
                },
                toast: {
                    delete: {
                        success: "D'éirigh le scriosadh an chárta.",
                        error: "Tharla earráid agus d'iarratas á phróiseáil. ",
                    },
                    save: {
                        success: "D'éirigh le sonraí an chárta a shábháil.",
                        error: "Tharla earráid agus d'iarratas á phróiseáil. ",
                    },
                    paynow: {
                        success: "D'éirigh leis an íocaíocht.",
                        error: "Tharla earráid agus d'iarratas á phróiseáil. ",
                    },
                    edit: {
                        success: "D'éirigh le sonraí an chárta a nuashonrú.",
                        error: "Tharla earráid agus d'iarratas á phróiseáil. ",
                    },
                    replace: {
                        success: "D'éirigh le sonraí an chárta a athsholáthar.",
                        error: "Tharla earráid agus d'iarratas á phróiseáil. ",
                    },
                    common: {
                        error: "Tharla earráid agus d'iarratas á phróiseáil. ",
                    },
                    amount: 'Níl aon iarmhéid gan íoc le muirearú.',
                    noSwitch: "Glan d'iarmhéid gan íoc sula n-aistrigh go cuntas réamhíocaíochta",
                },
            },
            label: {
                accountFunds: 'Cistí Cuntas',
                rewards: 'luach saothair',
                referralBonus: 'Bónas Atreoraithe',
                currentBalance: 'Iarmheid reatha',
                seeMore: 'Féach níos mó',
                message: 'Teachtaireacht',
                charityDonation: 'Síntiús Carthanachta',
                topupAmount: 'Sea, bhronnadh',
                note: 'Nóta:',
                charity: 'carthanacht',
                card: 'cárta',
                donation: 'síntiús',
                giftaid: 'bronntanasaid',
                noThanks: 'Níl, go raibh maith agat',
                cancel: 'Cealaigh',
            },
            error: "Tá d'idirbheart íocaíochta faoi bhun an luach íosta idirbhirt ##AMOUNT",
        },
        PrePaymentDetail: {
            Title: 'Cumraíocht Íocaíochta',
            SubTitle: 'Cuntas Réamhíocaíochta',
            Inputs: {
                Amount: {
                    label: 'Méid',
                    placeHolder: 'Méid',
                    required: 'Tá méid ag teastáil',
                    invalid: 'Caithfidh an méid a bheith níos mó ná nó cothrom le',
                    maxLimit: '',
                    number: 'Ní mór uimhir a bheith i méid',
                },
                CardNumber: {
                    label: 'Uimhir chárta',
                    placeHolder: '1234 1234 1234 1234',
                    required: 'Tá uimhir chárta ag teastáil',
                    invalid: 'Uimhir chárta neamhbhailí',
                },
                Expiry: {
                    label: 'Éaga',
                    required: 'Tá gá le mí éaga',
                },
                Year: {
                    label: 'Bliain',
                    required: 'Tá gá le bliain éaga',
                },
                CSC: {
                    label: 'CSC',
                    label1: '(Cód Slándála Cárta)',
                    required: 'Tá Cód Slándála Cárta ag teastáil',
                    placeHolder: 'CSC',
                    maxlimit: 'Cuir isteach cód 3 dhigit ar a laghad.',
                },
                Donation: {
                    label: 'Sea, ba mhaith liom a bhronnadh!',
                },
                RoundUp: {
                    label: 'Bhabhta suas',
                },
                TopUp: {
                    label: 'Barr Suas',
                },
            },
            Buttons: {
                Save: {
                    text: 'Cuir Airgead leis',
                },
                Back: {
                    text: 'Athraigh go Cuntas Creidmheasa',
                    text1: 'Ar ais',
                },
                FailBack: {
                    text: 'Ar Ais go Cuntas',
                },
            },
            PrePayemnt: {
                text: {
                    currentBalance: 'Iarmheid reatha',
                    OutBalance: 'Iarmhéid gan íoc',
                },
                button: {
                    add: 'Cuir Airgead leis',
                    Withdraw: 'Cistí a Tharraingt siar',
                    withdrawAccountFunds: 'Cistí Cuntas a Tharraingt siar',
                    viewYourRefundRequests: 'Féach ar Iarratais Aistarraingthe',
                },
            },
            toast: {
                canNotAddMoney:
                    'Ní féidir leat níos mó cistí a chur leis toisc go bhfuil uasteorainn bainte amach agat maidir leis na cistí is féidir le duine a choinneáil ag a gcuid ##COMPANY_BRAND_NAME## sparán.',
                common: {
                    error: "Tharla earráid agus d'iarratas á phróiseáil. ",
                },
                maxLimitForPrePayment: 'Hey! ní féidir leat iarmhéid do sparán a luchtú ach go dtí ##MAXAMOUNT.',
                maxLimitReached:
                    'Hey! ní féidir leat iarmhéid do sparán a luchtú ach go dtí ##MAXAMOUNT. Is féidir leat uasmhéid ##ALLOWEDAMOUNT a chur leis anois.',
            },
            label: {
                accountFunds: 'Cistí Cuntas',
                rewards: 'luach saothair',
                referralBonus: 'Bónas Atreoraithe',
                currentBalance: 'Iarmheid reatha',
                seeMore: 'Féach níos mó',
                billingAddress: 'Seoladh billeála',
                charityDonation: 'Síntiús Carthanachta',
                note: 'Nóta:',
            },
            donationNote: "Síntiús 'Breisiú' le méid seasta do gach idirbheart íocaíochta.",
        },
        PrePaymentWithdraw: {
            Title: 'Cumraíocht Íocaíochta',
            SubTitle: 'Cuntas Réamhíocaíochta',
            Inputs: {
                Amount: {
                    label: 'Méid',
                    placeHolder: 'Méid',
                    required: 'Tá méid ag teastáil',
                    invalid: 'Tá an méid níos mó ná an t-iarmhéid reatha',
                },
                CardNumber: {
                    label: 'Uimhir chárta',
                    placeHolder: '1234 1234 1234 1234',
                    required: 'Tá uimhir chárta ag teastáil',
                    invalid: 'Uimhir chárta neamhbhailí',
                    maxlimit: 'Caithfidh Uimhir Chárta a bheith 20 digit ar a mhéad',
                },
                Expiry: {
                    label: 'Éaga',
                    required: 'Tá gá le mí éaga',
                },
                Year: {
                    label: 'Bliain',
                    required: 'Tá gá le bliain éaga',
                },
                CSC: {
                    label: 'CSC',
                    label1: '(Cód Slándála Cárta)',
                    required: 'Tá Cód Slándála Cárta ag teastáil',
                    placeHolder: 'CSC',
                    maxlimit: 'Cuir isteach cód 3 dhigit íosta le do thoil.',
                },
            },
            Buttons: {
                Withdraw: {
                    text: 'Cistí a Tharraingt siar',
                },
                Back: {
                    text: 'Athraigh go Cuntas Creidmheasa',
                    text1: 'Ar ais',
                },
                FailBack: {
                    text: 'Ar Ais go Cuntas',
                },
            },
            PrePayemnt: {
                text: {
                    currentBalance: 'Iarmheid reatha',
                    OutBalance: 'Iarmhéid gan íoc',
                },
                button: {
                    add: 'Cuir Airgead leis',
                    Withdraw: 'Cistí a Tharraingt siar',
                },
            },
        },
        PaymentSuccess: {
            label: 'Rath!',
            Successlabel: 'Idirbheart rathúil',
            transactionId: 'Aitheantas Idirbhirt:',
            amount: 'Méid:',
            warningLabel: 'Rabhadh',
            common: {
                error: "Tharla earráid agus d'iarratas á phróiseáil. ",
            },
        },
        PaymentFail: {
            label: 'Theip ar Idirbheart',
            failureLabel: "Theip ar d'idirbheart. ",
        },
        PaymentProcess: {
            label: "Fan le do thoil agus d'idirbheart á phróiseáil",
        },
        Model: {
            header: 'Athraigh Cuntas',
            DeleteBoxHeader: 'Scrios Cárta',
            WithdrawBoxHeader: 'Cistí a Tharraingt siar',
            button: {
                yes: 'Tá',
                no: 'Níl',
                existingCard: 'Cárta Reatha',
                newCard: 'Cárta Nua',
            },
            messagePrePayment: 'An bhfuil tú cinnte gur mhaith leat aistriú go cuntas réamhíocaíochta?',
            messageCreditPayment: 'An bhfuil tú cinnte gur mhaith leat aistriú go cuntas creidmheasa?',
            msgPrePaymentSaveCard: 'Trí aistriú go cuntas réamhíocaíochta, scriosfar sonraí do chárta. ',
            DeleteCardMsg: 'An bhfuil tú cinnte gur mhaith leat sonraí an chárta a scriosadh?',
            WithdrawMoneyMsg: 'Roghnaigh rogha cárta chun airgead a aistarraingt.',
        },
        charityModal: {
            yesDonate: 'Sea, bhronnadh',
        },
        Withdraw: {
            CanNotWithdrawMoneyWhileSessionIsActive:
                'Ní féidir leat airgead a aistarraingt faoi láthair. Fan go mbeidh do sheisiún luchtaithe reatha críochnaithe',
        },
    },
    termsAndCondition: {
        title: 'Téarmaí',
        note: 'Leathanach Á Thógáil.',
    },
    table: {
        data: {
            noData: 'Níor aimsíodh aon sonraí.',
        },
        pagination: {
            prev: 'Roimhe seo',
            nrev: 'Ar aghaidh',
            showing: 'Ag taispeáint',
            pageSize: {
                ten: '10',
                fifty: '50',
                hundred: '100',
            },
        },
    },
    history: {
        headerTitle: 'Muirearú ##COMPANY_BRAND_NAME## : Muirearú Mear EV RA',
        title: 'Muirearú',
        columns: {
            chargingStation: 'Port luchtaithe',
            date: 'Dáta',
            address: 'Seoladh',
            milesAdded: 'Mílte Curtha leis',
            time: 'Am',
            cost: 'Costas',
            energy: 'Fuinneamh',
            action: 'Gníomh',
            remark: 'Nótaí',
            credit: 'Creidmheas',
            debit: 'Dochar',
            closing: 'Deiridh',
            transaction: 'ID Idirbhirt',
            credit_debit: 'Creidmheas/Dochar',
        },
        form: {
            charge: 'Muirearú',
            payment: 'Íocaíocht',
            month: 'Mí',
        },
        label: {
            exportAll: 'Gin Admháil',
        },
        button: {
            search: 'Cuardach',
            reset: 'Athshocraigh',
        },
        message: {
            selectRecordToDownload: 'Roghnaigh aon taifead chun admháil a ghiniúint.',
            noChargingHistoryMessage: 'Nuair a ghearrann tú do EV beidh do stair luchtaithe le feiceáil anseo.',
            noPaymentHistoryMessage: 'Nuair a bheidh idirbheart íocaíochta curtha i gcrích agat taispeánfar anseo é.',
        },
        receipt: {
            title: 'FÁILTE',
            header: {
                date: 'Dáta',
                billing_addresss: 'Seoladh billeála',
                note: 'Do ##COMPANY_BRAND_NAME## Admháil Muirir EV',
                vat_number: 'uimhir CBL',
            },
            content: {
                summary: 'Tá achoimre ar d’idirbhearta muirir léirithe thíos',
                table: {
                    header: {
                        station_name: 'Ainm an Stáisiúin',
                        start: 'Tosaigh',
                        end: 'Deireadh',
                        usage: 'Úsáid',
                        unit: 'Aonad',
                        value: 'Luach',
                        transaction_id: 'ID Idirbhirt',
                        vatRate: 'Ráta CBL',
                        vat: 'CBL',
                    },
                },
                total_before_vat: 'Iomlán roimh CBL',
                total_vat: 'CBL iomlán',
                vat: 'CBL ag',
                total_fee: 'Táille Iomlán',
                thanks_message: 'Go raibh maith agat as muirearú ar an ##COMPANY_BRAND_NAME## Líonra Muirir EV',
                vatReceiptNotGenerated: 'Níl admháil CBL ginte fós. ',
                vatReceiptNotAssociatedWithAcc: 'Níl admháil CBL ceangailte le do chuntas. ',
            },
            footer: {
                title: '##COMPANY_BRAND_NAME## Is cuideachta é Limited atá cláraithe i Sasana agus sa Bhreatain Bheag, uimhir chuideachta 10484882',
                address: '6 Cedarwood, Lána Crockford, Páirc Chineham, Basingstoke, Hampshire, RG24 8WD',
                telephone: 'T',
                email: 'E',
                note: 'Luaigh dáta an idirbhirt agus ID an idirbhirt i ngach comhfhreagras.',
            },
        },
    },
    loyaltyPoint: {
        header: 'luach saothair - ##COMPANY_BRAND_NAME## : Muirearú Mear EV RA',
        title: 'luach saothair',
        note: '*Ní féidir leat ach volta a fhuascailt tríd ár n-aip.',
        availableVolts: 'Volta ar fáil:',
        noDataMessage: "Nuair a bheidh seisiún muirir críochnaithe agat, taispeánfar d'idirbhearta volta anseo.",
        label: {
            chargingStation: 'Port luchtaithe',
            date: 'Dáta',
            address: 'Seoladh',
            credit: 'Creidmheas',
            debit: 'Dochar',
            closing: 'Deiridh',
            redeemed: 'Fuascailte',
            charged: 'Gearrtar',
            remark: 'Nótaí',
            credit_debit: 'Creidmheas/Dochar',
            voltsRedeemed: 'Volta Fuascailte',
        },
    },
    withdraw: {
        subTitle: 'Cuntas Réamhíocaíochta',
        label: {
            maxWithdrawalBalance: 'Iarmhéid Aistarraingthe Uasta',
            note: 'Nóta:',
            enterAmount: 'Cuir isteach Méid',
            withdrawAmount: 'Méid a Tharraingt siar',
            expires: 'In éag',
            rewardHeading: 'Cuir isteach luach is mian leat a tharraingt siar ó do chuntas',
            selectCardToWithdrawAmount: 'Roghnaigh cárta chun an méid a tharraingt siar',
            remainingAmount: 'An méid atá fágtha',
            accountName: 'Ainm chuntais',
            sortCode: 'Cód Sórtáil',
            accountNumber: 'Uimhir chuntais',
            bankDetail: 'Sonraí Bainc',
            withdrawAmountNote:
                'Ní bheidh tú in ann ach na cistí a aistriú ó do chuntas isteach sa chárta íocaíochta a úsáideadh chun an cuntas a mhaoiniú.',
            withdrawAmountNote1:
                'Tabhair faoi deara le do thoil go ndéanfar an tarraingt siar a phróiseáil laistigh de 14 lá.',
            withdrawAmountNote2:
                'Tabhair faoi deara le do thoil go ndéanfar an tarraingt siar a phróiseáil laistigh de 3 go 5 lá oibre.',
            accountNameNote:
                ' Cinntigh le do thoil go dtagann ainm an chuntais bhainc le do chuid ##COMPANY_BRAND_NAME## ainm an chuntais, Seachas sin, diúltófar don tarraingt siar.',
        },
        placeholder: {
            amount: 'Méid',
            accountName: 'Ainm chuntais',
            sortCode: 'Cód Sórtáil',
            accountNumber: 'Uimhir chuntais',
        },
        button: {
            back: 'Ar ais',
            next: 'Ar aghaidh',
            raisedRequest: 'Íosluchtaigh iarratas ar tharraingt siar',
            withdrawMoney: 'Cistí a Tharraingt siar',
            proceed: 'Lean ar aghaidh',
        },
        errors: {
            amount: {
                typeError: 'Cuir isteach méid bailí',
                moreThan: 'Caithfidh an méid a bheith níos mó ná 0',
            },
            AmountIsGreaterThanwithdrawableAmount: "Is mó an méid a d'iontráil tú ná an méid is féidir a aistarraingt",
            selectCardToWithdrawMoney: 'Roghnaigh aon chárta chun airgead a aistarraingt.',
            canNotSelectCard:
                'Sáraíonn an luach aistarraingthe an ciste aistarraingthe uasta atá ar fáil do chárta íocaíochta roghnaithe. ',
        },
    },
    termsAndConditionListing: {
        title: 'Téarmaí ##COMPANY_BRAND_NAME## : Muirearú Mear EV RA',
        noteDetails: '*Ní mór glacadh leis go bhfuil sé éigeantach chun cuntas a úsáid/a shíniú.',
        label: {
            referralCode: 'Cód Atreoraithe (má tá ceann agat)',
            promotioncode: 'Cód Cur Chun Cinn (má tá ceann agat)',
            termsAndConditions: 'Téarmaí',
            welcome: 'Fáilte',
            apply: 'Cuir iarratas isteach',
            note: 'Nóta:',
            invalidCode: 'Úps! ',
            usedCode: 'Cód Úsáidte Cheana',
            codeApplied: 'Cód i bhfeidhm',
            iAggreeToTermsCondition: 'Aontaím le',
            reviewUpdatedCondition: 'Athbhreithnigh agus glac leis na téarmaí nuashonraithe',
            name: 'Ainm',
            accepted: 'Glactar leis',
        },
        button: {
            next: 'Ar aghaidh',
            cancel: 'Cealaigh',
            accept: 'Glac',
        },
        errors: {
            acceptTermCondition: 'Glac le * Téarmaí éigeantacha',
            invalidReferralCode: 'An Cód Atreoraithe ##REFERRAL_CODE## níl an t-iontráil bailí. ',
            invalidPromotionCode:
                'An Cód Cur Chun Cinn ##PROMO_CODE## Níl an t-iontráil bailí. Cuir ceart (tá luach an téacs cásíogair ) nó bain an Cód Cur Chun Cinn chun leanúint ar aghaidh.',
            referralNotAllowed: 'Ní féidir cóid atreoraithe a úsáid leis an ardú céime seo',
        },
        message: {
            refferalCode: {
                first: 'An Cód Atreoraithe',
                second: 'níl an t-iontráil bailí. ',
            },
            promotionCode: {
                first: 'An Cód Cur Chun Cinn',
                second: 'Níl an t-iontráil bailí. Cuir ceart (tá luach an téacs cásíogair ) nó bain an Cód Cur Chun Cinn chun leanúint ar aghaidh.',
            },
        },
    },
    cookiePolicy: {
        text: 'Úsáideann an suíomh Gréasáin seo fianáin chun eispéireas an úsáideora a fheabhsú. ',
        action: 'Polasaí Fianán',
        iAccept: 'Glacaim',
    },
    openSource: {
        title: 'Creidmheasanna',
    },
    refundRequest: {
        title: 'Iarratais Aistarraingthe - ##COMPANY_BRAND_NAME## : Muirearú Mear EV RA',
        header: 'Iarratais Aistarraingthe',
        label: {
            name: 'Ainm',
            requestedDate: 'Dáta Iarrtha',
            refundRequested: 'Aistarraingt Iarrtha',
            account: 'Cuntas (Le hAistarraingt Creidmheasa)',
            remarks: 'Nótaí',
            transactionID: 'Iarratas aitheantais',
            processing: 'Próiseáil',
            cancelled: 'Ar ceal',
            declined: 'Diúltaithe',
            complete: 'Críochnaigh',
            date: 'Dáta',
            status: 'Stádas',
            cancelRequest: 'Cealaigh Iarratas',
            action: 'Gníomh',
        },
        button: {
            search: 'Cuardach',
            reset: 'Athshocraigh',
            yes: 'Tá',
            no: 'Níl',
        },
        placeholder: {
            selectStatus: 'Roghnaigh Stádas',
        },
        message: {
            confirmCancelRequest: "An bhfuil tú cinnte gur mhaith leat d'iarratas aistarraingthe a chealú?",
        },
        errors: {
            sortCode: {
                min: 'Cuir isteach cód sórtála 6 dhigit ar a laghad.',
                valid: 'Cuir isteach cód sórtála bailí.',
            },
            accountNumber: {
                min: 'Cuir isteach uimhir chuntais íosta 8 ndigit.',
            },
        },
    },
    footer: {
        version: 'Leagan',
        copyRightText: 'Gach ceart ar cosaint',
    },
    rfidCards: {
        headerTitle: 'Cártaí RFID - ##COMPANY_BRAND_NAME## : Muirearú Mear EV RA',
        title: 'Cártaí RFID',
        label: {
            public_card_id: 'Uimhir(eanna) Cárta',
            quantity: 'Cuir isteach cainníocht',
            action: 'Gníomh',
            delete: 'Scrios',
            cardNumber: 'Cuir isteach Uimhir Chárta',
            rfid_card_unit_price: 'Is é praghas aonaid cárta RFID amháin',
            rfid_card_unit_price_label: 'Le cárta RFID',
        },
        placeholder: {
            quantity: 'Cuir isteach cainníocht',
            public_card_id: 'Cuir isteach Uimhir Chárta',
        },
        noDataMessage: 'Níl aon chárta gníomhach RFID ag an nóiméad seo',
        requestProcessDaysMessage:
            "Déanfar d'iarratas ar chárta RFID a phróiseáil laistigh de ##DAYS## Laethanta oibre.",
        modal: {
            header: 'Iarratas Cárta RFID',
            body: 'Cuir isteach Líon na gCártaí RFID atá ag Teastáil. ',
        },
        addCardModal: {
            header: 'Cuir Cárta RFID leis',
        },
        button: {
            proceed: 'Lean ar aghaidh',
            cancel: 'Cealaigh',
            addNewCard: 'Cuir Cárta RFID leis',
            requestCard: 'Iarr Cárta Nua RFID',
            delete: 'Scrios',
            action: 'Gníomh',
        },
        errors: {
            quantity: {
                min: 'Caithfidh an chainníocht a bheith níos mó ná nialas',
            },
            public_card_id: {
                valid: 'Níor aithníodh aitheantas an chárta a cuireadh isteach. ',
            },
        },
        message: {
            here: 'anseo',
            deleteConfirmMessage:
                'Má scriostar an cárta seo, beidh sé díchumasaithe go buan agus ní féidir é a athchlárú. ',
        },
    },
    giftAidmessage: 'teachtaireacht cúnamh bronntanais',
    creditAccountDonationNote: 'Trí Sea, síntiús de ##AMOUNT## cuirfear i bhfeidhm é ar gach íocaíocht amach anseo. ',
    PrepaymentAccountDonationNote: 'Trí Sea, síntiús de ##AMOUNT## a chur i bhfeidhm ar an idirbheart íocaíochta seo. ',
    aboutUs: {
        listTitle: {
            openSourcelicenses: 'Ceadúnais Foinse Oscailte',
        },
    },
    price_breakdown_details: {
        detailed_breakup: 'Miondealú Mionsonraithe',
        total_cost: 'Costas Iomlán',
        conversion_rate: 'Ráta Tiontaithe',
    },
};
