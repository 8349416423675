import React from 'react';
import { Helmet } from 'react-helmet';
import { IResetPasswordProps } from '../../types/propTypes';
import { IResetPasswordState } from '../../types/stateTypes';
import LoginService from '../../services/login/login-service';
import { IForgotPasswordChangeField } from '../../models/login';
import { Formik, Field } from 'formik';
import { Form, Button } from 'react-bootstrap';
import { ReactstrapInput, ReactstrapFormikPasswordInput } from '../../components/reactstrap-formik';
import { ResetPassworValidationSchema } from '../../validation/login/login-validation';
import { Common } from '../../utils/constants';
import { toast } from 'react-toastify';
import LoginAPIService from '../../services/login/loginApi-service';
import images from '../../assets/images';
import { companyNameReplace } from '../../utils';

class ResetPasswordForm extends React.Component<IResetPasswordProps, IResetPasswordState> {
    private initialState: IForgotPasswordChangeField = {
        code: '',
        password: '',
        email: '',
        confirmPassword: '',
    };
    state: IResetPasswordState;
    loginService: LoginService;
    loginAPIService: LoginAPIService;
    constructor(props: IResetPasswordProps) {
        super(props);
        this.loginService = new LoginService();
        this.loginAPIService = new LoginAPIService();
    }

    handleSubmit = (value: IForgotPasswordChangeField) => {
        value.email = this.props.email;
        return this.loginService
            .forgotPasswordChange(value)
            .then(() => {
                toast.success(this.props.t('forgot.toast.success'));
                this.loginAPIService.emailNotification(this.props.email);
                this.props.onChangeStage(Common.AuthStage.SIGNIN);
            })
            .catch((err) => {
                if (err && err.code === Common.Authentication.CodeMismatchException) {
                    toast.error(this.props.t('forgot.toast.invalidCode'));
                } else {
                    toast.error(this.props.t('forgot.toast.error'));
                }
            })
            .finally();
    };

    render() {
        const { t, companyName } = this.props;
        return (
            <React.Fragment>
                <Helmet>
                    <title>{companyNameReplace(t('forgot.resetTitle'))}</title>
                </Helmet>
                <button
                    type="button"
                    className="btn btn-icon btn-link pr-0 btn-back"
                    onClick={() => this.props.onChangeStage(Common.AuthStage.FORGOT_PASSWORD)}
                    title={t('forgot.button.back')}
                >
                    <img className="icon" src={images.backArrowRSVG} alt={t('forgot.button.back')} />
                </button>
                <div className="title-outer">
                    <h1 className="title">
                        <img src={images.logoIconPNG} alt={t('login.label.logo')} />
                        {t('forgot.resetHeader')}
                    </h1>
                    <p>{t('forgot.resetSubHeader')}</p>
                </div>
                <Formik
                    initialValues={this.initialState}
                    onSubmit={this.handleSubmit}
                    validationSchema={ResetPassworValidationSchema(t)}
                >
                    {(props) => {
                        const { handleSubmit } = props;
                        return (
                            <form className="form-secondary" onSubmit={handleSubmit} autoComplete="off" noValidate>
                                <Field
                                    name="code"
                                    id="Code"
                                    label={t('forgot.form.code')}
                                    component={ReactstrapInput}
                                />
                                <Field
                                    type="password"
                                    name="password"
                                    id="Password"
                                    label={t('forgot.form.password')}
                                    component={ReactstrapFormikPasswordInput}
                                />
                                <Field
                                    type="password"
                                    name="confirmPassword"
                                    id="ConfirmPassword"
                                    label={t('forgot.form.confirmPassword')}
                                    autoComplete="new-password"
                                    component={ReactstrapFormikPasswordInput}
                                />
                                <Form.Group className="form-group">
                                    <Button type="submit" className="btn btn-primary w-100">
                                        {t('forgot.button.save')}
                                    </Button>
                                </Form.Group>
                            </form>
                        );
                    }}
                </Formik>
            </React.Fragment>
        );
    }
}
export default ResetPasswordForm;
