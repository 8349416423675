import { Action } from 'redux';

export enum ActionType {
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT_REQUEST,
    LOGOUT_SUCCESS,
    LOGOUT_FAIL,
    ERROR_LOG,
    CLEAR_ERROR_LOG,
    TOGGLE_SIDEBAR,
    CHANGE_LANGUAGE,
    FETCH_USER_DETAILS,
    USER_DATA,
    SUPPORTED_LANGUAGES,
    DEFAULT_LANGUAGE,
    DATE_TIME_FORMAT,
    NUMBER_FORMAT,
    CURRENCY_DETAILS,
    CURRENCY_FORMAT,
    COMPANY_NAME,
}

export interface IAppAction extends Action<ActionType> {
    payload?: any;
}
