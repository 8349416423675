import React from 'react';
import { Spinner } from 'reactstrap';

const PageSpinner = ({ color = 'primary' }) => {
    return (
        <div className="cr-page-spinner">
            <Spinner color={color}></Spinner>
        </div>
    );
};

export default PageSpinner;
