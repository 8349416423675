import React, { Component, FC } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { IPremiumSubscriptionPageProps } from '../../types/PropTypes';
import { IPremiumSubscriptionPageState } from '../../types/stateTypes';
import { useSearchParams } from 'react-router-dom';
import UserService from '../../services/user/user-service';
import StandardResponse from '../../models/standard-response';
import { toast } from 'react-toastify';
import { companyNameReplace } from '../../utils';

const PremiumSubscriptionPageHOC: FC<any> = (props) => {
    const [searchParams] = useSearchParams();
    let subscriptionID = searchParams?.get('premium_subscription_id') || '';
    let languageID = searchParams?.get('language_id') || '';

    return <PremiumSubscriptionPage {...props} subscriptionID={subscriptionID} languageID={languageID} />;
};

class PremiumSubscriptionPage extends Component<
    IPremiumSubscriptionPageProps & WithTranslation,
    IPremiumSubscriptionPageState
> {
    userService: UserService;
    constructor(props: IPremiumSubscriptionPageProps & WithTranslation) {
        super(props);
        this.userService = new UserService();
        this.state = {
            image: '',
            loading: true,
        };
    }

    async componentDidMount() {
        await this.getPremiumSubscriptionImage();
    }

    getPremiumSubscriptionImage = async () => {
        const { subscriptionID, languageID } = this.props;
        if (subscriptionID) {
            await this.userService
                .getSubscriptionImage({
                    premium_subscription_id: subscriptionID,
                    language_id: languageID ? languageID : undefined,
                })
                .then((res: StandardResponse<any>) => {
                    let image = res.Data.image_path;
                    if (image) {
                        this.setState({ image: `${process.env.REACT_APP_DRIVER_PORTAL_URL}/${image}`, loading: false });
                    }
                })
                .catch(() => {
                    toast.error(this.props.t('Payment.PaymentSuccess.common.error'));
                });
        } else {
            this.setState({ loading: false });
        }
    };

    render() {
        return (
            <>
                {this.state.loading ? (
                    <></>
                ) : this.state.image ? (
                    <img src={this.state.image} style={{ width: '100vw', height: '100vh', display: 'block' }}></img>
                ) : (
                    <div className="container-fluid subscription-wrapper" id="subscription-wrapper">
                        <div className="subscription-header-div">
                            <div>
                                <h1 className="subscription-header">
                                    {companyNameReplace(this.props.t('dashboard.premiumSubscription'))}
                                </h1>
                            </div>
                        </div>
                    </div>
                )}
            </>
        );
    }
}
export default withTranslation()(PremiumSubscriptionPageHOC);
