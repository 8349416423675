import React from 'react';
import { ILoginProps } from '../../types/propTypes';
import { ILoginState } from '../../types/stateTypes';
import LoginService from './../../services/login/login-service';
import { Helmet } from 'react-helmet';
import { ILoginForm } from '../../models/login';
import { Formik, Field } from 'formik';
import { Form, Button } from 'react-bootstrap';
import { ReactstrapInput, ReactstrapFormikPasswordInput } from '../../components/reactstrap-formik';
import { LoginValidationSchema } from '../../validation/login/login-validation';
import { Common } from '../../utils/constants';
import SocialMediaLoginForm from './SocialMediaLoginForm';
import { withCookies, Cookies } from 'react-cookie';
import SelectBox from '../../components/reactstrap-formik/SelectBox';
import images from '../../assets/images';
import _ from 'lodash';
import { companyNameReplace } from '../../utils';

export class LoginForm extends React.Component<ILoginProps & Cookies, ILoginState> {
    rememberValue = this.props.cookies.get('driver_remember') === 'true';
    parameters = new URLSearchParams(window.location.search);
    private initialState: ILoginForm = {
        email: this.rememberValue
            ? this.props.cookies.get('driver_email')
            : this.parameters?.get('email') && this?.parameters?.get('email') !== 'undefined'
            ? `${this.parameters.get('email')}`
            : '',
        password: this.rememberValue ? this.props.cookies.get('driver_password') : '',
        rememberMe: this.rememberValue || false,
    };
    state: ILoginState;
    loginService: LoginService;
    constructor(props: ILoginProps & Cookies) {
        super(props);
        this.loginService = new LoginService();
    }

    handleSubmit = (value: ILoginForm) => {
        const date = new Date();
        date.setDate(date.getDate() + 30);
        this.props.cookies.set('driver_remember', value.rememberMe, { path: '/', expires: date });
        if (value.rememberMe) {
            this.props.cookies.set('driver_email', value.email.toLowerCase(), { path: '/', expires: date });
            this.props.cookies.set('driver_password', value.password, { path: '/', expires: date });
        }
        this.props.handleSubmit(value);
    };

    render() {
        const { t, companyName } = this.props;
        return (
            <React.Fragment>
                <Helmet>
                    <title>{companyNameReplace(t('login.title'))}</title>
                </Helmet>
                <h1 className="title">
                    <img src={images.logoIconPNG} alt={t('login.label.logo')} />
                    {t('login.header')}
                </h1>
                <Formik
                    initialValues={this.initialState}
                    onSubmit={this.handleSubmit}
                    validationSchema={LoginValidationSchema(t)}
                >
                    {(props) => {
                        const { handleSubmit, values, setFieldValue } = props;
                        return (
                            <form className="form-secondary" onSubmit={handleSubmit} autoComplete="off" noValidate>
                                <Field
                                    type="text"
                                    name="email"
                                    id="Email"
                                    label={t('login.form.email')}
                                    component={ReactstrapInput}
                                    maxLength={50}
                                    onKeyUp={(e) => {
                                        this.props.handleChangeFormValues(e);
                                    }}
                                />
                                <Field
                                    type="password"
                                    name="password"
                                    id="Password"
                                    label={t('login.form.password')}
                                    autoComplete="new-password"
                                    component={ReactstrapFormikPasswordInput}
                                />
                                <Form.Group className="form-group">
                                    <Button type="submit" className="btn btn-primary w-100">
                                        {t('login.button.signin')}
                                    </Button>
                                </Form.Group>
                                <div className="form-group pb-2">
                                    <div className="custom-control custom-checkbox custom-control-inline">
                                        <input
                                            type="checkbox"
                                            className="custom-control-input"
                                            id="remember-me"
                                            name="rememberMe"
                                            checked={values.rememberMe}
                                            onChange={() => setFieldValue('rememberMe', !values.rememberMe)}
                                        />
                                        <label className="custom-control-label pb-0" htmlFor="remember-me">
                                            {t('login.label.rememberMe')}
                                        </label>
                                    </div>
                                    <a
                                        href="#"
                                        className="forgot-password float-right"
                                        title={t('login.button.forgotPassword')}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            this.props.onChangeStage(Common.AuthStage.FORGOT_PASSWORD);
                                        }}
                                    >
                                        {t('login.button.forgotPassword')}
                                    </a>
                                </div>
                                <SocialMediaLoginForm t={t} title={t('login.label.thirdPartyLogin')} />
                                <div className="clearfix border-top-1 pt-4">
                                    <p className="sign-up">
                                        {t('login.label.newAccount')}{' '}
                                        <a
                                            href="#"
                                            title={t('login.button.signup')}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                this.props.onChangeStage(Common.AuthStage.SIGNUP);
                                            }}
                                            className="signup-btn"
                                        >
                                            {t('login.button.signup')}
                                        </a>
                                    </p>
                                </div>
                            </form>
                        );
                    }}
                </Formik>
            </React.Fragment>
        );
    }
}
export default withCookies(LoginForm);
