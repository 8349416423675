import { Footer } from '../layout';
import React, { Suspense } from 'react';
import Header from './header';
import { IAppAction } from '../store/actions/Helpers';
import { Outlet } from 'react-router';
import PageSpinner from '../components/shared/PageSpinner';

interface IMainLayoutProps {
    logout: () => IAppAction;
    changeLanguage: (language: any) => IAppAction;
}

class MainLayout extends React.Component<IMainLayoutProps> {
    componentDidUpdate() {
        if (document.getElementById(window.location.pathname.trim())) {
            const oldActiveElements = document.querySelectorAll('.nav-item.active');
            if (oldActiveElements.length) {
                oldActiveElements.forEach((element) => element.classList.remove('active'));
            }

            document.getElementById(window.location.pathname.trim()).classList.add('active');
        } else {
            const oldActiveElement = document.querySelector('.active');
            if (oldActiveElement) {
                oldActiveElement.classList.remove('active');
            }
        }
    }
    render() {
        return (
            <div className="wrapper">
                <Header logout={this.props.logout} changeLanguage={this.props.changeLanguage} />
                <main className="main-content">
                    <div className="container-xl">
                        <div className="card mb-4">
                            <Suspense fallback={<PageSpinner />}>
                                <Outlet />
                            </Suspense>
                        </div>
                    </div>
                </main>
                <Footer />
            </div>
        );
    }
}

export default MainLayout;
