import { combineReducers } from 'redux';
import { AuthenticationReducer } from './authentication.reducer';
import { ConfigurationsReducer } from './configuration.reducers';
import { ErrorLogReducer } from './errorLog.Reducer';
import { LanguageReducer } from './languageReducer.reducer';

export const reducers = combineReducers({
    UserData: AuthenticationReducer,
    LanguageInfo: LanguageReducer,
    errorLog: ErrorLogReducer,
    configuration: ConfigurationsReducer,
});
