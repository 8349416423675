import React from 'react';
import Select from 'react-select';

const SelectBox = (props) => {
    return (
        <React.Fragment>
            {props.label && <label>{props.label}</label>}
            <Select
                {...props}
                name={props.name}
                class="form-control"
                value={props.value}
                options={props.options}
                isMulti={props.isMulti}
                isInvalid={props.isInvalid}
                onChange={(value) => props.onChange(value)}
                placeholder={props.placeholder}
            />
            {props.touched && props.errors && (
                <div className="pb-0 invalid-feedback d-block invalid-feedback">{props.touched && props.errors} </div>
            )}
        </React.Fragment>
    );
};

export default SelectBox;
