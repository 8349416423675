import BaseService from '../base-service';
import StandardResponse from '../../models/standard-response';
import { IUserData, IUserProfileForm, IPartnerPromotion } from 'models/profile';
import Axios from 'axios';

export default class UserService {
    getProfile = async (): Promise<StandardResponse<IUserData>> => {
        try {
            const data = {
                platform_type: 'Web',
            };
            const res = await BaseService({
                url: `/driver/detail`,
                method: 'POST',
                data,
            });
            return new StandardResponse<IUserData>(res.status, res.data.data, res.data.message, null);
        } catch (e) {
            return Promise.reject(e);
        }
    };

    updateProfile = async (data: IUserProfileForm): Promise<StandardResponse<Array<number>>> => {
        try {
            const res = await BaseService({
                url: `/driver`,
                method: 'PUT',
                data,
            });
            return new StandardResponse<Array<number>>(res.status, res.data.data, res.data.message, null);
        } catch (e) {
            return Promise.reject(e);
        }
    };

    deleteAccount = async (): Promise<StandardResponse<null>> => {
        try {
            const res = await BaseService({
                url: `/driver/cancel`,
                method: 'DELETE',
            });
            return new StandardResponse<null>(res.status, res.data.data, res.data.message, null);
        } catch (e) {
            return Promise.reject(e);
        }
    };

    getParentPromotionById = async (
        id: number,
        driver_id: number,
        session_id: string,
    ): Promise<StandardResponse<IPartnerPromotion>> => {
        try {
            const res = await BaseService({
                url: session_id
                    ? `/driver/partner-promotion/${id}/${driver_id}/${session_id}`
                    : `/driver/partner-promotion/${id}/${driver_id}`,
                method: 'get',
                data: { byPassAuth: true },
            });
            return new StandardResponse<IPartnerPromotion>(res.status, res.data.data, res.data.message, null);
        } catch (e) {
            return Promise.reject(e);
        }
    };

    getConfigurationDetail = async (data: any) => {
        try {
            const res = await Axios.post(
                `${process.env.REACT_APP_API_SERVER}/${process.env.REACT_APP_API_VERSION}/driver/public/configuration/search`,
                data,
            );
            return Promise.resolve(res.data);
        } catch (e) {
            return Promise.reject(e);
        }
    };

    getSubscriptionImage = async (data: any): Promise<StandardResponse<Array<number>>> => {
        try {
            const res = await BaseService({
                url: `driver/premium-subscriptions/image`,
                method: 'POST',
                data: { byPassAuth: true, ...data },
            });

            return new StandardResponse<Array<number>>(res.status, res.data.data, res.data.message, null);
        } catch (e) {
            return Promise.reject(e);
        }
    };
}
