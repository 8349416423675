import { Auth } from 'aws-amplify';
import BaseService from 'services/base-service';
import { trackPromise } from 'react-promise-tracker';
import {
    ISignUpFormField,
    ISignUpResult,
    ITermsCondtionData,
    saveTermsConditionRequestData,
    IReferralDetail,
    IRegionConfigurations,
} from 'models/login';
import StandardResponse from 'models/standard-response';
import { IRegionData } from 'models/profile';
import { ISupportedLanguage } from 'store/state/language.state';
import axios from 'axios';
import { store } from 'store';

export default class LoginAPIService {
    cognitoSignup = async (data: ISignUpFormField): Promise<ISignUpResult> => {
        try {
            const res = await trackPromise(
                Auth.signUp({
                    username: data.email.toLowerCase(),
                    password: data.password,
                    attributes: {
                        'custom:language': data?.language_preference_name?.toLowerCase(),
                    },
                }),
            );

            const objRequest: any = {
                first_name: data.firstName,
                last_name: data.lastName,
                email: data.email.toLowerCase(),
                provider_key: res.userSub,
                captcha_code: data.recaptchaCode,
                platform_type: 'WEB',
                language_preference_id: data.language_preference_id,
            };
            this.signup(objRequest);
            return Promise.resolve(res);
        } catch (err) {
            return Promise.reject(err);
        }
    };

    signup = async (data: any): Promise<any> => {
        try {
            const res = await BaseService({
                url: `/driver/signup`,
                method: 'POST',
                data: { ...data, byPassAuth: true },
            });
            return Promise.resolve(res);
        } catch (err) {
            return Promise.reject(err);
        }
    };

    emailNotification = async (email: string): Promise<boolean> => {
        const data = { email: email.toLowerCase(), byPassAuth: true };
        try {
            await BaseService({
                url: `/driver/notify-password-change`,
                method: 'PUT',
                data: { ...data, byPassAuth: true },
            });
            return Promise.resolve(true);
        } catch (e) {
            return Promise.resolve(false);
        }
    };

    verifyReferaalCode = async (code: string): Promise<boolean> => {
        try {
            await BaseService({
                url: `/driver/check-referral-code/${code}`,
                method: 'GET',
            });
            return true;
        } catch (e) {
            return false;
        }
    };
    checkReferralCode = async (referral_code: string): Promise<StandardResponse<IReferralDetail>> => {
        try {
            const res = await BaseService({
                url: `/driver/check-referral-code/${referral_code}`,
                method: 'GET',
            });
            return new StandardResponse<IReferralDetail>(res.status, res.data.data, res.data.message, null);
        } catch (e) {
            return Promise.reject(e);
        }
    };
    checkPromotionCode = async (promotion_code: string): Promise<StandardResponse<boolean>> => {
        try {
            const res = await BaseService({
                url: `/driver/check-promotion-code/${promotion_code}`,
                method: 'GET',
                data: { byPassAuth: true },
            });

            return new StandardResponse<boolean>(res.status, res.data.data, res.data.message, null);
        } catch (e) {
            return Promise.reject(e);
        }
    };
    saveTermsAndCondition = async (data: saveTermsConditionRequestData): Promise<StandardResponse<number>> => {
        try {
            const res = await BaseService({
                url: `/driver/referral-promotion-terms`,
                method: 'POST',
                data,
            });
            return new StandardResponse<number>(res.status, res.data.data, res.data.message, null);
        } catch (e) {
            return Promise.reject(e);
        }
    };
    getTermsAndConditionListing = async (): Promise<StandardResponse<Array<ITermsCondtionData>>> => {
        try {
            const res = await BaseService({
                url: `/driver/settings/terms-and-conditions`,
                method: 'get',
            });
            return new StandardResponse<Array<ITermsCondtionData>>(res.status, res.data.data, res.data.message, null);
        } catch (e) {
            return Promise.reject(e);
        }
    };
    checkDriverIsVerified = async (data: any): Promise<StandardResponse<any>> => {
        try {
            const res = await BaseService({
                url: `/driver/verify`,
                method: 'PUT',
                data: { ...data, byPassAuth: true },
            });
            return new StandardResponse<any>(res.status, res.data.data, res.data.message, null);
        } catch (e) {
            return Promise.reject(e);
        }
    };
    checkEmailAlreadyExists = async (data: any): Promise<StandardResponse<any>> => {
        try {
            const res = await BaseService({
                url: `/driver/check-email-exists-with-without-federated-account`,
                method: 'POST',
                data: { ...data, byPassAuth: true },
            });
            return new StandardResponse<number>(res.status, res.data.data, res.data.message, null);
        } catch (e) {
            return Promise.reject(e);
        }
    };

    getSupportedLanguages = async (): Promise<ISupportedLanguage> => {
        try {
            const res = await axios.post(
                `${process.env.REACT_APP_API_SERVER}/${process.env.REACT_APP_API_VERSION}/driver/languages/search`,
                {},
            );
            return Promise.resolve(res.data);
        } catch (e) {
            return Promise.reject(e);
        }
    };

    getRegions = async (language?: string): Promise<IRegionData> => {
        let languageCode = store?.getState()?.LanguageInfo?.defaultLanguage?.code
            ? store?.getState()?.LanguageInfo?.defaultLanguage?.code
            : 'en';
        const headers = {
            language: language || languageCode,
            Authorization: process.env.REACT_APP_GLOBAL_SERVICE_API_TOKEN,
        };
        try {
            const res = await axios.post(
                `${process.env.REACT_APP_GLOBAL_API_SERVER}/v1.0/global-services/regions/search`,
                {},
                {
                    headers: headers,
                },
            );
            return Promise.resolve(res.data);
        } catch (e) {
            return Promise.reject(e);
        }
    };

    getRegionConfigurations = async (): Promise<IRegionConfigurations> => {
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_API_SERVER}/${process.env.REACT_APP_API_VERSION}/driver/get-region-configs`,
            );
            return Promise.resolve(res.data);
        } catch (e) {
            return Promise.reject(e);
        }
    };
}
