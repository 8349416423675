import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { IFAQPageProps } from '../../types/propTypes';
import { Card, Accordion, useAccordionButton } from 'react-bootstrap';
import { IFAQState } from '../../types/stateTypes';
import _ from 'lodash';
import UserService from '../../services/user/user-service';
import { toast } from 'react-toastify';
import { Common } from '../../utils/constants';
import { IConfiguration } from '../../models/profile';
import { store } from '../../store';
import { getFormattedCurrencyWithSymbol } from '../../components/common/LocalNumberFormat';
import moment from 'moment';
import LoginAPIService from '../../services/login/loginApi-service';

export class FAQPage extends Component<IFAQPageProps & WithTranslation, IFAQState> {
    state: IFAQState;
    userService: UserService;
    loginApiService: LoginAPIService;
    defaultCurrency?: string;
    constructor(props) {
        super(props);
        this.state = {
            activeKey: '0',
            faqArray: [],
        };
        this.userService = new UserService();
        this.defaultCurrency = '';
    }

    async componentDidMount() {
        await this.getConfiguration();
        const queryStringLang = new URLSearchParams(window.location.search);
        const languageInfo = store?.getState()?.LanguageInfo?.supportedLanguages;
        const changelanguage = languageInfo
            ? languageInfo?.filter(
                  (item) =>
                      item?.code === queryStringLang.get('langCode') || item?.code === queryStringLang.get('language'),
              )[0]
            : { code: queryStringLang.get('langCode') || queryStringLang.get('language') };
        let regionCode = process.env.REACT_APP_PORTAL_REGION?.toLocaleLowerCase();
        let languageCode;
        if (queryStringLang.get('langCode') || queryStringLang.get('language')) {
            languageCode = changelanguage?.code;
        } else {
            if (store.getState()?.UserData) {
                languageCode = store.getState()?.UserData?.language?.code;
            } else if (store.getState()?.LanguageInfo) {
                languageCode = store.getState()?.LanguageInfo?.defaultLanguage?.code;
            }
        }
        let jsonFile = require(`../../assets/faq/uk/en.json`);
        if (regionCode && languageCode) {
            jsonFile = require(`../../assets/faq/${regionCode}/${languageCode}.json`);
        }
        let config = await this.getConfigData(Common.ConfigurationKey.FAQ_PRICE_PER_KWH);
        let brandConfig = await this.getConfigDataBrandName('COMPANY_BRAND_NAME');
        let pricePerKWHValue = '';
        if (config.length > 0) {
            const pricePerKWhConfig: IConfiguration = config.find(
                (x) => x.reference_code === Common.ConfigurationKey.FAQ_PRICE_PER_KWH,
            );
            pricePerKWHValue = pricePerKWhConfig.value ? pricePerKWhConfig.value : '';
        }
        let brandname = '';
        if (brandConfig.length > 0) {
            brandname = brandConfig[0].value ? brandConfig[0].value : '';
        }
        let faqs = JSON.stringify(jsonFile?.faq);
        let currencySymbol = this.defaultCurrency ? this.defaultCurrency : '';
        const changeValue = store?.getState()?.configuration?.company_name || 'Instavolt';
        faqs = faqs.split('##COMPANY_BRAND_NAME##').join(brandname || changeValue);
        faqs = _.replace(
            faqs,
            '##PRICE_PER_KWH##',
            getFormattedCurrencyWithSymbol(pricePerKWHValue.toString(), 'string', undefined, currencySymbol) as string,
        );
        //it only replace once to second is added due to second value replace
        faqs = _.replace(
            faqs,
            '##PRICE_PER_KWH##',
            getFormattedCurrencyWithSymbol(pricePerKWHValue.toString(), 'string', undefined, currencySymbol) as string,
        );
        faqs = _.replace(
            faqs,
            '##START_TIME##',
            `${moment('8:30', 'HH:mm').format(
                store?.getState()?.configuration?.date_time_format?.APP_GENERAL_TIME_FORMAT
                    ? store?.getState()?.configuration?.date_time_format?.APP_GENERAL_TIME_FORMAT
                    : 'HH:mm',
            )}`,
        );
        faqs = _.replace(
            faqs,
            '##END_TIME##',
            `${moment(languageCode === 'is' ? '17:00' : '5:00', 'HH:mm').format(
                store?.getState()?.configuration?.date_time_format?.APP_GENERAL_TIME_FORMAT
                    ? store?.getState()?.configuration?.date_time_format?.APP_GENERAL_TIME_FORMAT
                    : 'HH:mm',
            )}`,
        );
        faqs = _.replace(faqs, '##DRIVER_PORTAL_URL##', `${process.env.REACT_APP_DRIVER_PORTAL_URL}`);
        faqs = _.replace(faqs, '##DRIVER_PORTAL_LOGIN_URL##', `${process.env.REACT_APP_DRIVER_PORTAL_URL}/login`);
        const faqArray = JSON.parse(faqs);
        this.setState({
            ...this.state,
            faqArray: faqArray,
        });
    }

    getConfiguration = async () => {
        const loginApiService = new LoginAPIService();
        try {
            await loginApiService.getRegionConfigurations().then((res) => {
                if (res?.data) {
                    this.props.setCurrencyFormat(res?.data?.currency_formats);
                    this.defaultCurrency = res?.data?.currency_formats?.DEFAULT_CURRENCY;
                    if (_.isEmpty(res?.data?.number_formats)) {
                        this.props.setNumberFormat({
                            GENERAL_NUMBER_FORMAT: '###.###.###,###',
                            FINANCIAL_NUMBER_FORMAT: '###.###.###,####',
                            NUMBER_DECIMAL_SEPARATOR: ',',
                        });
                    } else {
                        this.props.setNumberFormat(res?.data?.number_formats);
                    }
                }
            });
        } catch (e: any) {
            this.props.setNumberFormat({
                GENERAL_NUMBER_FORMAT: '###.###.###,##',
                FINANCIAL_NUMBER_FORMAT: '###.###.###,####',
                NUMBER_DECIMAL_SEPARATOR: ',',
            });
            throw e;
        }
    };

    getConfigData = async (code: string) => {
        let configData = [];
        await this.userService
            .getConfigurationDetail({ code })
            .then(async (res) => {
                configData = res.data;
            })
            .catch((m) => {
                toast.error(m.toString());
            });
        return configData;
    };
    getConfigDataBrandName = async (code: string) => {
        let configData = [];
        await this.userService
            .getConfigurationDetail({ code })
            .then(async (res) => {
                configData = res.data;
            })
            .catch((m) => {
                toast.error(m.toString());
            });
        return configData;
    };
    setActiveKey = (key: number) => {
        const activeKey = this.state.activeKey === key.toString() ? '' : key.toString();
        this.setState({ activeKey: activeKey });
    };

    CustomToggle = ({ children, eventKey }) => {
        const decoratedOnClick = useAccordionButton(eventKey, () => this.setActiveKey(eventKey));

        return (
            <span
                className={this.state.activeKey === eventKey.toString() ? 'faq-card-title' : 'faq-card-title collapsed'}
                onClick={decoratedOnClick}
            >
                {children}
            </span>
        );
    };

    render() {
        return (
            <>
                <div className="container-fluid" id="faq-wrapper">
                    <Accordion defaultActiveKey="0" className="faqs-outer p-3">
                        {this.state.faqArray &&
                            this.state.faqArray.map((faq, index) => {
                                const answerList = faq.answer.split('\n');
                                return (
                                    <Card key={index}>
                                        <Card.Header>
                                            <this.CustomToggle eventKey={index.toString()}>
                                                {faq.question}
                                            </this.CustomToggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey={index.toString()}>
                                            <Card.Body>
                                                {answerList.map((data, index) => {
                                                    return (
                                                        <>
                                                            {data}{' '}
                                                            {index === answerList.length - 1 ? (
                                                                <></>
                                                            ) : (
                                                                <>
                                                                    <br />
                                                                    <br />
                                                                </>
                                                            )}
                                                        </>
                                                    );
                                                })}
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                );
                            })}
                    </Accordion>
                </div>
            </>
        );
    }
}

export default withTranslation()(FAQPage);
